import { IEntityModel } from './base'
import { IBatch } from './batch'
import { IMethod } from './method'
import { ITag } from './tag'
import { ITask } from './task'
import { IWorkspace } from './workspace'
import {
  AnalysisDataType,
  DexcomDeviceDataType,
  GarminDeviceLogDataType,
} from '../mongo'
import { GarminConnectHookType } from '../api'
import { IProjectScheduleConfig } from './project_schedule_config'

export enum ProjectType {
  Sample = 'sample',
  Template = 'template',
  User = 'user',
}

export enum ProjectStatus {
  Draft = 'draft',
  Completed = 'completed',
  Live = 'live',
  Deleted = 'deleted',
}

export type GraphDataType =
  | GarminDeviceLogDataType
  | DexcomDeviceDataType
  | GarminConnectHookType
  | AnalysisDataType

//might move to separated table if the setting for graph grow and get complicated
export interface IGraphDataInfo {
  type: GraphDataType
  index: number
}

export interface IProject extends IEntityModel {
  completedAt?: Date
  workspaceId: string
  workspace?: IWorkspace
  name: string
  type: ProjectType
  description: string
  investigator: string
  organization: string
  contactDescription: string
  participantInstructions: string
  status: ProjectStatus
  methodList?: IMethod[]
  batchList?: IBatch[]
  taskList?: ITask[]
  tagList?: ITag[]
  visibleGraphDataInfoList?: IGraphDataInfo[]
  backgroundSyncIntervalSeconds?: number | null
  activatedParticipantCount?: number
  scheduleConfig?: IProjectScheduleConfig
  lastActiveDate: Date | string
}
