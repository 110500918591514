import {useRef, useState} from 'react'
import {selectTheme} from '../../store'
import {SettingIcon} from '../../asset/image'
import {RIF, useClickOutside} from '../../engine'

export const QuestionnaireDropdown = (props: {
  index: number
  deleteItem: (i: number) => void
  duplicateItem: (i: number) => void
}) => {
  const dropdownRef = useRef(null)
  const {color, fontSize} = selectTheme()
  const {index, deleteItem, duplicateItem} = props
  const [renderDropdown, setRenderDropdown] = useState(false)
  const handleClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    deleteItem(index)
  }
  const handleClickDuplicate = (e: React.MouseEvent) => {
    e.stopPropagation()
    duplicateItem(index)
    setRenderDropdown(false)
  }
  useClickOutside(dropdownRef, () => setRenderDropdown(false))

  return (
    <div css={{position: 'relative'}}>
      <button
        onMouseUp={(e) => {
          e.stopPropagation()
          setRenderDropdown(!renderDropdown)
        }}
        css={{
          width: '32px',
          height: '32px',
          minWidth: '32px',
          backgroundImage: `url(${SettingIcon})`,
          backgroundSize: '24px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
        }}
      />
      {RIF(
        renderDropdown,
        <div
          ref={dropdownRef}
          css={{
            zIndex: 103,
            display: 'flex',
            flexDirection: 'column',
            gap: '1px',
            position: 'absolute',
            top: '32px',
            left: '20px',
            borderRadius: '5px',
            backgroundColor: color.surface.white,
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          <button
            onClick={handleClickDuplicate}
            css={{
              padding: '8px 16px',
              fontSize: fontSize.h7,
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: color.surface.default,
              },
            }}
          >
            Duplicate
          </button>
          <button
            onClick={handleClickDelete}
            css={{
              width: '100%',
              padding: '8px 16px',
              fontSize: fontSize.h7,
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: color.surface.default,
              },
            }}
          >
            Delete
          </button>
        </div>,
      )}
    </div>
  )
}
