import {Dispatch, SetStateAction, useState} from 'react'
import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import {ButtonReverse, AddReminderPop, ReminderTag} from '..'
import {ReminderItem} from '../../shared/db'
import {BellIcon, PlusBlueIcon} from '../../asset/image'
import ReminderImg from '../../asset/image/reminder.png'

export interface AddTaskReminderSectionPropsT {
  reminderList: ReminderItem[]
  setReminderList: Dispatch<SetStateAction<ReminderItem[]>>
}

export const AddTaskReminderSection = (props: AddTaskReminderSectionPropsT) => {
  const {color, fontWeight} = selectTheme()
  const {reminderList, setReminderList} = props
  const [displayReminderPop, setDisplayReminderPop] = useState(false)
  const [editingReminder, setEditingReminder] = useState<ReminderItem | undefined>(undefined)

  return (
    <div
      data-testid='add_task_reminder_section'
      css={{marginBottom: '80px', paddingTop: '40px', borderTop: `1px solid ${color.grey_100}`}}
    >
      <div css={{display: 'flex', justifyContent: 'space-between', marginBottom: '32px'}}>
        <div
          css={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '144px',
          }}
        >
          <div>
            <div css={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
              <img src={BellIcon} width={20} height={20} />
              <p css={{fontWeight: '500', marginLeft: '8px'}}>Auto-Reminder</p>
            </div>
            <p css={{color: color.grey_600}}>
              Schedule a task reminder for participants to receive at set times, even without internet connection.
            </p>
          </div>
          <ButtonReverse
            onClick={() => {
              setDisplayReminderPop(true)
              setEditingReminder(undefined)
            }}
            css={{marginTop: '24px'}}
            {...{
              btnPadding: 'medium',
              children: (
                <div css={{display: 'flex', alignItems: 'center', color: color.primary, fontWeight: fontWeight.bold}}>
                  <img src={PlusBlueIcon} width='18' css={{marginRight: '3px'}} />
                  Add an Auto-Reminder
                </div>
              ),
            }}
          />
        </div>
        <img src={ReminderImg} height={144} width={178} />
      </div>

      {/* reminder tag */}
      {reminderList.map((reminder, index) => (
        <ReminderTag
          {...{
            key: reminder.value,
            setDisplayReminderPop,
            setEditingReminder,
            reminder,
            index,
            reminderList,
            setReminderList,
          }}
        />
      ))}

      {RIF(
        displayReminderPop,
        <AddReminderPop
          {...{
            editingReminder,
            reminderList,
            setReminderList,
            setDisplayReminderPop,
          }}
        />,
      )}
    </div>
  )
}
