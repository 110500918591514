import {RIF, _} from '../../lib'
import {selectTheme} from '../../store'
import {QuestionnaireInput, ButtonText, QuestionnaireInputSize, ButtonCancel, DragHandle} from '..'
import {CancelBlackIcon, DraggingIcon, DragIcon} from '../../asset/image'
import {QuestionnaireTempQuestion} from '../../model'
import {QuestionnaireQuestionType} from '../../shared/db'
import {Draggable} from 'react-beautiful-dnd'

export const QuestionnaireOption = (props: {
  id: string
  isEditing: boolean
  index: number
  question: QuestionnaireTempQuestion
  option?: {
    name: string
  }
  // editingQuestion: QuestionnaireTempQuestion
  // setEditingQuestion: (arg: QuestionnaireTempQuestion) => void
  editOptionName: (optionIndex: number, name: string) => void
  deleteOption: (optionIndex: number) => void
  showDeleteButton: boolean
  isOtherOption: boolean
  viewOnly: boolean
}) => {
  const {pad, color, fontWeight} = selectTheme()
  const {
    id,
    isEditing,
    index,
    question,
    option,
    editOptionName,
    deleteOption,
    showDeleteButton,
    isOtherOption,
    viewOnly,
  } = props

  const handleChangeOptionName = (value: string) => {
    editOptionName(index, value)
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              height: isEditing ? '40px' : 'auto',
              boxSizing: 'border-box',
              position: 'relative',
            }}
          >
            {RIF(
              !viewOnly && isEditing && !isOtherOption,
              <div
                {...provided.dragHandleProps}
                css={{
                  width: '8px',
                  height: '100%',
                  marginRight: '8px',
                  backgroundImage: `url(${snapshot.isDragging ? DraggingIcon : DragIcon})`,
                  backgroundSize: '100% auto',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  cursor: 'grab',
                  ':hover': {
                    backgroundImage: `url(${DraggingIcon})`,
                  },
                }}
              />,
            )}
            <div
              css={{
                width: '16px',
                height: '16px',
                borderRadius: question?.type === QuestionnaireQuestionType.Radio ? '50%' : '2px',
                border: `1px solid ${color.grey_400}`,
                marginLeft: isOtherOption && isEditing ? '24px' : '0',
              }}
            />
            {RIF(
              isEditing,
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  flex: 1,
                }}
              >
                {RIF(
                  !isOtherOption,
                  <QuestionnaireInput
                    // size={QuestionnaireInputSize.small}
                    defaultValue={option?.name}
                    onChangeAction={handleChangeOptionName}
                    placeholder={'New Option'}
                    initialValue={'New Option'}
                    maxLength={250}
                  />,
                )}
                {RIF(
                  isOtherOption,
                  <p
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '40px',
                      flex: 1,
                      paddingLeft: '16px',
                      color: color.textIcon.secondary,
                      fontStyle: 'italic',
                      borderBottom: `1px dashed ${color.border.default}`,
                    }}
                  >
                    Other
                  </p>,
                )}
                {RIF(showDeleteButton, <ButtonCancel onClick={() => deleteOption(index)} />)}
              </div>,
            )}
            {RIF(!isEditing, <div>{isOtherOption ? 'Other' : option?.name}</div>)}
          </div>
        </div>
      )}
    </Draggable>
  )
}
