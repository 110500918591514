import {useState, useEffect} from 'react'
import {v, debug, useForm} from '../../lib'
import {PopupSimple, Input, LabelBox} from '..'
import {IProject} from '../../shared/db'

const schema = v.object({
  projectName: v.string().max(50).required(),
})

interface Props {
  project: IProject
  onClickDuplicate: (project: IProject, data: Record<string, string>) => void
  onClickClose: () => void
}

export const ProjectDuplicatePop = (props: Props) => {
  const {project, onClickDuplicate, onClickClose} = props

  const [newProjectName, setNewProjectName] = useState(`${project.name} Copy`)
  const {register, handleSubmit, watch} = useForm({schema})

  useEffect(() => {
    setNewProjectName(watch('projectName'))
  }, [watch('projectName')])

  const onSubmit = (data: Record<string, string>) => {
    debug('ProjectDuplicateForm.onSubmit', {data})
    onClickDuplicate(project, data)
  }

  const onError = (error: unknown) => {
    debug('ProjectDuplicateForm.onError', {error})
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      data-testid='duplicate_project_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '102',
      }}
    >
      <PopupSimple
        method='Name'
        name='Your Duplicated Project'
        description='Duplicated projects only duplicate the project specs, and not the participants or data.'
        buttonColor='primary'
        buttonText='Duplicate Project'
        btnDisableAction={!newProjectName}
        closeAction={() => onClickClose()}
        html={
          <LabelBox
            {...{
              label: 'Project Name',
              leftCharacters: 50 - newProjectName?.length,
            }}
          >
            <Input
              {...register('projectName')}
              placeholder='Write a short and easy to identify name for your project'
              value={project.name}
              maxLength='50'
              css={{marginBottom: '50px'}}
            />
          </LabelBox>
        }
      />
    </form>
  )
}
