import {useState, createRef, useEffect} from 'react'
import {selectTheme, selectMethod, createDispatchActions, selectWorkspaces, selectTempTaskData} from '../../store'
import {useClickOutside, RIF, _, useCurrentProjectState, useCurrentWorkspaceState, RequestResult} from '../../lib'
import {
  ButtonReverse,
  DevicePage,
  DeviceRemovePopup,
  DeviceRemoveConfirmPopup,
  AddDevicePopup,
  DeviceInstructionPop,
  Tooltip,
  DexcomIntegrationForm,
  DexcomSelectorPopup,
} from '..'
import {TaskStateType, TempTask, TaskTypeForDevicePage, TaskTypeForInstructionPage, TempGarminDevice} from '../../model'
import {
  DeviceIcon,
  PlusBlueIcon,
  GarminDeviceImg,
  MovesenseImg,
  DexcomImg,
  WarningIcon,
  ParticipantGreyIcon,
} from '../../asset/image'
import {IGarminDevice, IStopwatchGarminStream, IStopwatchMovesenseStream, TaskType} from '../../shared/db'

export const DeviceBlock = () => {
  const {pad, color, fontWeight, fontSize} = selectTheme()

  const {
    doREQUEST_TASK_DELETE,
    doREQUEST_METHOD_UPDATE,
    doREQUEST_DEXCOM_INTEGRATION_DELETE,
    doTEMP_TASK_DATA_UPDATE_TASK,
    doTEMP_TASK_DATA_DELETE_TASK,
    doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE,
    doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE,
    doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE,
    doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE,
  }: any = createDispatchActions()

  const [requestResultOfMethodUpdate, setRequestResultOfMethodUpdate] = useState<RequestResult | null>(null)

  /* ------------------ Basic ------------------ */
  const methodState = selectMethod()
  const {project} = useCurrentProjectState()
  const methodId = methodState?.id as string

  const workspacesState = selectWorkspaces()
  const {workspaceId, workspace: currentWorkspace} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  const isEditingLiveProject = project?.status === 'live'
  const tempTaskData = selectTempTaskData()

  // add
  const [displayAddDevicePopup, setDisplayAddDevicePopup] = useState<boolean>(false)
  const [displayDevicePage, setDisplayDevicePage] = useState<TaskTypeForDevicePage>('')
  const [displayInstructionPop, setDisplayInstructionPop] = useState<TaskTypeForInstructionPage>('')

  // remove
  // todo check_stopwatch_movesense_stream
  const [deviceRemoveType, setDeviceRemoveType] =
    useState<'' | 'garmin_device' | 'stopwatch_movesense_stream' | 'dexcom'>('')
  const [showDeviceRemovePopup, setShowDeviceRemovePopup] = useState(false)
  const [showDeviceRemoveConfirmPopup, setShowDeviceRemoveConfirmPopup] = useState(false)

  /* ------------------ Garmin  ------------------ */
  const [displayGarminDropdown, setDisplayGarminDropdown] = useState(false)
  const garminDropdownRef: React.RefObject<HTMLDivElement> = createRef()
  useClickOutside(garminDropdownRef, () => setDisplayGarminDropdown(false))

  // garmin connect
  const garminConnectEnable = methodState?.garminConnectEnable || false
  const tempGarminConnectEnable = tempTaskData?.garminConnectEnable

  const garminDeviceTask: TaskStateType | undefined = _.find(methodState?.taskList, {type: TaskType.GarminDevice})
  const garminDeviceEnable =
    methodState?.garminDeviceEnable ||
    _.filter(garminDeviceTask?.garminDevice, (item) => {
      return typeof item === 'boolean' && item === true
    }).length > 0
  const tempGarminDeviceEnable = tempTaskData?.garminDeviceEnable

  const garminDeviceTaskDetail: IGarminDevice | null = garminDeviceTask?.garminDevice || null
  const tempGarminDeviceTask: TempTask | undefined = _.find(tempTaskData?.taskList, {
    type: TaskType.GarminDevice,
  })
  const tempGarminDeviceTaskDetail: TempGarminDevice | undefined = tempGarminDeviceTask?.garminDevice

  // garmin stream
  const garminStreamEnable: boolean = isEditingLiveProject
    ? tempTaskData?.garminStreamEnable
    : methodState?.garminStreamEnable

  const garminStreamTasks: TaskStateType[] | undefined = _.filter(methodState?.taskList, {
    type: TaskType.StopwatchGarminStream,
  })
  const tempGarminStreamTasks: TempTask[] | undefined = _.filter(tempTaskData?.taskList, {
    type: TaskType.StopwatchGarminStream,
  })
  const hasGarminStreamTasks: boolean = !!garminStreamTasks.length || !!tempGarminStreamTasks.length
  const [garminStreamTaskIdList, setGarminStreamTaskIdList] = useState<string[]>([])

  /* ------------------ Movesense ------------------ */
  const movesenseDeviceEnable = !!methodState?.movesenseDeviceEnable
  const movesenseTasks: TaskStateType[] = _.filter(methodState?.taskList, {
    type: TaskType.StopwatchMovesenseStream || TaskType.StopwatchMovesenseLogData,
  })
  const tempMovesenseDeviceEnable = !!tempTaskData?.movesenseDeviceEnable
  const tempMovesenseTasks: TempTask[] = _.filter(tempTaskData?.taskList, {
    type: TaskType.StopwatchMovesenseStream || TaskType.StopwatchMovesenseLogData,
  })
  const [movesenseTaskIdList, setMovesenseTaskIdList] = useState<string[]>([])

  const [displayMovesenseDropdown, setDisplayMovesenseDropdown] = useState(false)
  const movesenseDropdownRef: React.RefObject<HTMLDivElement> = createRef()
  useClickOutside(movesenseDropdownRef, () => setDisplayMovesenseDropdown(false))

  // dexcom
  const dexcomIntegrationId = isEditingLiveProject ? tempTaskData.dexcomIntegrationId : methodState.dexcomIntegrationId
  const dexcomIntegrationList = currentWorkspace?.dexcomIntegrationList
  const dexcomIntegration = dexcomIntegrationId ? _.find(dexcomIntegrationList, {id: dexcomIntegrationId}) : undefined
  const dexcomButtonDisabled = role === 'viewer'
  const TOOLTIP_CONTENT_IF_CLIENT_ID_APPROVED = 'This is your unique key for secure integration with Dexcom.'
  const TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED =
    'Your application is waiting for Dexcom approval. Please check your email for update information. Contact us at hello@labfront.com if you have any other questions.'

  const [displayDexcomDropdown, setDisplayDexcomDropdown] = useState<boolean>(false)
  const dexcomDropdownRef: React.RefObject<HTMLDivElement> = createRef()
  useClickOutside(dexcomDropdownRef, () => setDisplayDexcomDropdown(false))

  useEffect(() => {
    if (!isEditingLiveProject) return
    if (tempGarminConnectEnable === null) doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE({garminConnectEnable})
    if (tempMovesenseDeviceEnable === null) doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE({movesenseDeviceEnable})
    if (tempGarminDeviceEnable === null) doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE({garminDeviceEnable})
  }, [garminConnectEnable, movesenseDeviceEnable, garminConnectEnable])

  useEffect(() => {
    if (movesenseTasks.length) setMovesenseTaskIdList(movesenseTasks.map((task) => task.id))
    if (tempMovesenseTasks.length) setMovesenseTaskIdList(tempMovesenseTasks.map((task) => task.id))
    if (garminStreamTasks.length) setGarminStreamTaskIdList(garminStreamTasks.map((task) => task.id))
    if (tempGarminStreamTasks.length) setGarminStreamTaskIdList(tempGarminStreamTasks.map((task) => task.id))
  }, [movesenseTasks.length, tempMovesenseTasks.length, garminStreamTasks.length, tempGarminStreamTasks.length])

  const isLiveTask = (taskId?: string) => {
    return isEditingLiveProject && methodState?.taskList.some((t) => t.id === taskId)
  }
  const handleDeleteTask = () => {
    if (isEditingLiveProject) {
      if (deviceRemoveType === 'garmin_device') {
        if (isLiveTask(tempGarminDeviceTask?.id)) {
          doTEMP_TASK_DATA_UPDATE_TASK({
            ...tempGarminDeviceTask,
            enabled: false,
            actionType: 'hide',
          })
        } else {
          if (tempGarminDeviceTask?.id) doTEMP_TASK_DATA_DELETE_TASK({taskId: tempGarminDeviceTask?.id})
        }
        // remove garmin, also remove garmin stream
        if (hasGarminStreamTasks) {
          garminStreamTaskIdList.map((taskId) => {
            const garminStreamTask = _.find(tempGarminStreamTasks, {id: taskId})
            if (isLiveTask(garminStreamTask?.id)) {
              doTEMP_TASK_DATA_UPDATE_TASK({
                ...garminStreamTask,
                enabled: false,
                actionType: 'hide',
              })
            } else {
              doTEMP_TASK_DATA_DELETE_TASK({taskId: garminStreamTask?.id})
            }
          })
        }
        doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE({garminDeviceEnable: false})
        doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE({garminConnectEnable: false})
        doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE({garminStreamEnable: false})
      }

      // todo check_stopwatch_movesense_stream
      if (deviceRemoveType === 'stopwatch_movesense_stream') {
        movesenseTaskIdList.map((taskId) => {
          const movesenseTask = _.find(tempMovesenseTasks, {id: taskId})
          if (isLiveTask(movesenseTask?.id)) {
            doTEMP_TASK_DATA_UPDATE_TASK({
              ...movesenseTask,
              enabled: false,
              actionType: 'hide',
            })
          } else {
            doTEMP_TASK_DATA_DELETE_TASK({taskId: movesenseTask?.id})
          }
        })
        doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE({movesenseDeviceEnable: false})
      }
      setShowDeviceRemovePopup(false)
      setShowDeviceRemoveConfirmPopup(false)
    } else {
      if (deviceRemoveType === 'garmin_device') {
        if (garminDeviceTask) {
          doREQUEST_TASK_DELETE({
            payload: {
              taskId: garminDeviceTask?.id,
            },
          })
        }
        if (hasGarminStreamTasks) {
          garminStreamTaskIdList.map((taskId) => {
            doREQUEST_TASK_DELETE({
              payload: {
                taskId: taskId,
              },
            })
          })
        }
      }
      // todo check_stopwatch_movesense_stream
      if (deviceRemoveType === 'stopwatch_movesense_stream') {
        movesenseTaskIdList.map((taskId) => {
          doREQUEST_TASK_DELETE({
            payload: {
              taskId: taskId,
            },
          })
        })
      }
      if (deviceRemoveType === 'dexcom') {
        doREQUEST_DEXCOM_INTEGRATION_DELETE({
          payload: {
            workspaceId,
            dexcomIntegrationId,
          },
        })
      }
      doREQUEST_METHOD_UPDATE({
        setRequestResult: setRequestResultOfMethodUpdate,
        payload: {
          methodId,
          garminDeviceEnable: deviceRemoveType === 'garmin_device' ? false : undefined,
          garminConnectEnable: deviceRemoveType === 'garmin_device' ? false : undefined,
          garminStreamEnable: deviceRemoveType === 'garmin_device' ? false : undefined,
          movesenseDeviceEnable: deviceRemoveType === 'stopwatch_movesense_stream' ? false : undefined,
          dexcomIntegrationId: deviceRemoveType === 'dexcom' ? '' : undefined,
        },
      })
    }
  }

  useEffect(() => {
    if (requestResultOfMethodUpdate?.success) {
      setShowDeviceRemovePopup(false)
      setShowDeviceRemoveConfirmPopup(false)
    }
  }, [requestResultOfMethodUpdate])

  const handleCloseAction = () => {
    setDisplayDevicePage('')
    setDisplayAddDevicePopup(false)
  }

  const garminDescription = isEditingLiveProject
    ? convertGarminDescription(tempGarminDeviceTaskDetail, tempGarminDeviceEnable, garminStreamEnable)
    : convertGarminDescription(garminDeviceTaskDetail, garminDeviceEnable, garminStreamEnable)

  return (
    <>
      {RIF(
        displayAddDevicePopup,
        <AddDevicePopup
          {...{
            closeAction: setDisplayAddDevicePopup,
            setDisplayDevicePage,
            setDisplayInstructionPop,
          }}
        />,
      )}

      {RIF(
        displayInstructionPop === TaskType.StopwatchMovesenseStream,
        <DeviceInstructionPop
          {...{
            deviceType: TaskType.StopwatchMovesenseStream,
            setDisplayInstructionPop,
          }}
        />,
      )}

      {RIF(
        displayInstructionPop === TaskType.StopwatchGarminStream,
        <DeviceInstructionPop
          {...{
            deviceType: TaskType.StopwatchGarminStream,
            setDisplayInstructionPop,
          }}
        />,
      )}

      {RIF(
        !!displayDevicePage && !displayDevicePage.includes('dexcom'),
        <DevicePage
          {...{
            device: displayDevicePage,
            closeAction: handleCloseAction,
            setDisplayInstructionPop,
          }}
        />,
      )}

      {RIF(
        displayDevicePage === 'dexcomIntegrationForm',
        <DexcomIntegrationForm
          {...{
            setDisplayDevicePage,
          }}
        />,
      )}

      {RIF(
        displayDevicePage === 'dexcomSelectorPopup',
        <DexcomSelectorPopup
          {...{
            setDisplayDevicePage,
          }}
        />,
      )}

      {RIF(
        showDeviceRemovePopup,
        <DeviceRemovePopup
          {...{
            closeAction: () => setShowDeviceRemovePopup(false),
            doAction: handleDeleteTask,
            removeType: deviceRemoveType,
          }}
        />,
      )}

      {RIF(
        showDeviceRemoveConfirmPopup,
        <DeviceRemoveConfirmPopup
          {...{
            closeAction: () => setShowDeviceRemoveConfirmPopup(false),
            doAction: handleDeleteTask,
            name: 'Remove Movesense?',
            description: 'Remove Movesense will also remove the Movesense tasks you’ve created.',
            term1: 'I understand that this will remove all the Movesense tasks I’ve created.',
            buttonText: 'Remove Movesense',
          }}
        />,
      )}

      <div
        css={{
          display: 'flex',
          background: color.white,
          borderRadius: '5px',
          width: '55vw',
          boxShadow: '0px 4px 12px 0px #D4D4D440',
          marginBottom: pad.large,
        }}
      >
        {/* left */}
        <div
          css={{
            padding: pad.large,
            borderRight: `1px solid ${color.grey_100}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '26%',
          }}
        >
          <div>
            <div
              css={{
                width: '40px',
                height: '40px',
                background: color.background,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: pad.large,
              }}
            >
              <img src={DeviceIcon} width='24px' />
            </div>
            <p
              css={{
                fontSize: '18px',
                fontWeight: fontWeight.thick,
                marginBottom: pad.medium,
              }}
            >
              Devices and Data
            </p>
            <p css={{lineHeight: '19px', fontWeight: fontWeight.regular}}>
              Select and customize devices for continuous background data collection.
            </p>
          </div>
        </div>

        {/* right */}
        <div css={{padding: pad.xl, width: '74%'}}>
          <p
            css={{
              fontSize: '14px',
              fontWeight: fontWeight.medium,
              marginBottom: '14px',
            }}
          >
            Devices and types of data collected:
          </p>
          {RIF(
            role !== 'viewer',
            <ButtonReverse onClick={() => setDisplayAddDevicePopup(true)} btnPadding='medium'>
              <>
                <img src={PlusBlueIcon} width='18' height='18' />
                <span css={{fontWeight: fontWeight.thick, marginLeft: '5px', color: color.primary}}>Add Device(s)</span>
              </>
            </ButtonReverse>,
          )}

          {/* container */}
          <div css={{minHeight: '150px', width: '100%', marginTop: pad.large}}>
            {/* no device */}
            {RIF(
              isEditingLiveProject
                ? !(
                    (
                      tempGarminDeviceTask?.enabled ||
                      tempGarminConnectEnable ||
                      tempMovesenseDeviceEnable ||
                      garminStreamEnable ||
                      dexcomIntegrationId
                    )
                    // tempDexcomEnable
                  )
                : !(
                    garminDeviceTask?.garminDevice ||
                    movesenseDeviceEnable ||
                    garminConnectEnable ||
                    garminStreamEnable ||
                    dexcomIntegrationId
                  ),
              <div
                css={{
                  borderRadius: '5px',
                  border: `1px dashed ${color.grey_400}`,
                  height: '300px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p css={{fontSize: fontSize.h4, color: color.grey_400}}>You don&#39;t have any devices set up yet</p>
              </div>,
            )}

            {/* garmin device */}
            {RIF(
              isEditingLiveProject
                ? tempGarminDeviceTask?.enabled || garminStreamEnable || tempGarminConnectEnable
                : garminDeviceTask?.garminDevice || garminConnectEnable || garminStreamEnable,
              <div
                data-testid='device_block_garmin_section'
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  if (role !== 'viewer') {
                    const target = e.target as HTMLDivElement
                    if (target.id === 'displayGarminDropdownBtn') return e.preventDefault()
                    setDisplayDevicePage('garmin_device')
                  }
                }}
                css={{
                  borderRadius: '5px',
                  border: `1px solid ${color.grey_160}`,
                  marginBottom: '10px',
                  cursor: 'pointer',
                  ':hover': {
                    border: `1px solid ${color.black}`,
                    background: '#00000005',
                  },
                }}
              >
                <div
                  css={{
                    borderBottom: `1px solid ${color.grey_160}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                  }}
                >
                  <div css={{display: 'flex', alignItems: 'center'}}>
                    <img src={GarminDeviceImg} width='64' height='64' />
                    <div css={{fontWeight: fontWeight.bold, fontSize: '16px', marginLeft: '8px'}}>Garmin Devices</div>
                  </div>
                  {RIF(
                    role !== 'viewer' && !(isEditingLiveProject && methodState?.garminStreamEnable),
                    <div
                      data-testid='garmin_vertical_dots'
                      onClick={() => {
                        setDisplayGarminDropdown(!displayGarminDropdown)
                        setDeviceRemoveType('garmin_device')
                      }}
                      ref={garminDropdownRef}
                      id='displayGarminDropdownBtn'
                      css={{
                        color: color.grey_400,
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '3px',
                        ':hover': {
                          background: '#0000000A',
                        },
                      }}
                    >
                      ⋮
                      {RIF(
                        displayGarminDropdown,
                        <div
                          css={{
                            width: '80px',
                            height: '44px',
                            border: `1px solid ${color.grey_160}`,
                            borderRadius: '5px',
                            position: 'absolute',
                            right: 0,
                            top: 25,
                            background: color.white,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            data-testid='garmin_remove_btn'
                            onClick={() => setShowDeviceRemovePopup(true)}
                            id='displayGarminDropdownBtn'
                            css={{
                              width: '100%',
                              textAlign: 'center',
                              padding: '6px',
                              ':hover': {
                                background: '#0000000A',
                              },
                            }}
                          >
                            Remove
                          </span>
                        </div>,
                      )}
                    </div>,
                  )}
                </div>

                <div css={{padding: '20px'}}>
                  {RIF(
                    isEditingLiveProject ? tempGarminConnectEnable : garminConnectEnable,
                    <>
                      <div css={{color: color.grey_400}}>Collect data from Garmin Connect app</div>
                      <div css={{margin: '8px 0 24px 0'}}>
                        Daily Summary (Daily), Activity Summary (15 min), Sleep (Auto-Detect), Heart Rate (1 min),
                        Stress (3 min), Steps (3 min), Respiration (3 min), Oxygen Saturation (3 min)
                      </div>
                    </>,
                  )}
                  {RIF(
                    garminDescription.trim().length !== 0,
                    <>
                      <div css={{color: color.grey_400}}>Collect data from Labfront Companion app</div>
                      <div css={{marginTop: '8px'}}>{garminDescription.trim().slice(0, -1).replace(/\s+/g, ' ')}</div>
                    </>,
                  )}
                </div>
              </div>,
            )}

            {/* movesense */}
            {RIF(
              isEditingLiveProject ? tempMovesenseDeviceEnable || movesenseDeviceEnable : movesenseDeviceEnable,
              <div
                css={{
                  borderRadius: '5px',
                  border: `1px solid ${color.grey_160}`,
                  height: '90px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '8px 16px',
                }}
              >
                <div css={{display: 'flex', alignItems: 'center'}}>
                  <img src={MovesenseImg} width='64' height='64' />
                  <div css={{marginLeft: '8px', fontWeight: fontWeight.bold, fontSize: '16px'}}>Movesense</div>
                </div>
                {RIF(
                  role !== 'viewer' && !(isEditingLiveProject && movesenseDeviceEnable),
                  <div
                    data-testid='movesense_vertical_dots'
                    onClick={() => {
                      setDisplayMovesenseDropdown(!displayMovesenseDropdown)
                      setDeviceRemoveType('stopwatch_movesense_stream') // todo check_stopwatch_movesense_stream
                    }}
                    ref={movesenseDropdownRef}
                    id='displayMovesenseDropdownId'
                    css={{
                      color: color.grey_400,
                      width: '32px',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '24px',
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: '3px',
                      ':hover': {
                        background: '#0000000A',
                      },
                    }}
                  >
                    ⋮
                    {RIF(
                      displayMovesenseDropdown,
                      <div
                        css={{
                          width: '80px',
                          height: '44px',
                          border: `1px solid ${color.grey_160}`,
                          borderRadius: '5px',
                          position: 'absolute',
                          right: 0,
                          top: 25,
                          background: color.white,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          data-testid='movesense_remove_btn'
                          onClick={() => {
                            if (!!movesenseTasks.length || !!tempMovesenseTasks.length) {
                              setShowDeviceRemoveConfirmPopup(true)
                            } else {
                              setShowDeviceRemovePopup(true)
                            }
                          }}
                          id='displayMovesenseDropdownId'
                          css={{
                            width: '100%',
                            textAlign: 'center',
                            padding: '6px',
                            ':hover': {
                              background: '#0000000A',
                            },
                          }}
                        >
                          Remove
                        </span>
                      </div>,
                    )}
                  </div>,
                )}
              </div>,
            )}

            {/* dexcom */}
            {RIF(
              dexcomIntegrationId,
              <button
                disabled={dexcomButtonDisabled}
                type='button'
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  const target = e.target as HTMLButtonElement
                  if (['displayDexcomDropdownBtn', 'removeDexcomBtn'].includes(target.id)) return e.preventDefault()
                  setDisplayDevicePage('dexcomSelectorPopup')
                  if (!isEditingLiveProject) {
                    setDisplayDevicePage('dexcomSelectorPopup')
                  }
                }}
                css={{
                  borderRadius: '5px',
                  border: `1px solid ${color.grey_160}`,
                  minHeight: '90px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '16px',
                  outline: 'none',
                  background: '#fff',
                  width: '100%',
                  cursor: dexcomButtonDisabled ? 'auto' : 'pointer',
                  ':hover': {
                    border: `1px solid ${dexcomButtonDisabled ? color.grey_160 : color.black}`,
                    background: dexcomButtonDisabled ? '#fff' : '#00000005',
                  },
                }}
              >
                <div css={{display: 'flex', alignItems: 'center'}}>
                  <img src={DexcomImg} width='64' />
                  <div css={{marginLeft: '8px'}}>
                    <p
                      css={{
                        fontWeight: fontWeight.bold,
                        fontSize: '16px',
                        textAlign: 'start',
                      }}
                    >
                      Dexcom
                    </p>
                    <p
                      css={{
                        fontWeight: fontWeight.medium,
                        fontSize: fontSize.h7,
                        color: color.grey_400,
                        textAlign: 'start',
                        margin: '6px 0',
                      }}
                    >
                      {dexcomIntegration?.name}
                    </p>
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {RIF(
                        !!dexcomIntegration?.dexcomApp,
                        <p
                          css={{
                            fontWeight: fontWeight.medium,
                            color: color.grey_600,
                            textAlign: 'start',
                            // whiteSpace: 'nowrap',
                          }}
                        >
                          <span css={{whiteSpace: 'nowrap', color: color.highlightGreen}}>Approved -&nbsp;</span>
                          Client ID: {dexcomIntegration?.dexcomApp?.clientId}
                        </p>,
                      )}
                      {RIF(
                        !dexcomIntegration?.dexcomApp,
                        <div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <img src={WarningIcon} width={16} />
                          <p
                            css={{
                              marginLeft: '4px',
                              fontWeight: fontWeight.medium,
                              color: color.warning,
                            }}
                          >
                            Awaiting approval from Dexcom
                          </p>
                        </div>,
                      )}
                      <div
                        css={{
                          marginLeft: '4px',
                        }}
                      >
                        <Tooltip
                          {...{
                            content: dexcomIntegration?.dexcomApp
                              ? TOOLTIP_CONTENT_IF_CLIENT_ID_APPROVED
                              : TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED,
                          }}
                        />
                      </div>
                    </div>
                    {RIF(
                      dexcomIntegration?.dexcomApp,
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div
                          css={{
                            marginTop: '16px',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            background: color.background,
                            width: '181px',
                          }}
                        >
                          <img src={ParticipantGreyIcon} width={20} />
                          <p
                            css={{
                              marginLeft: '8px',
                              fontWeight: fontWeight.medium,
                              color: color.grey_400,
                            }}
                          >
                            {dexcomIntegration?.dexcomApp?.totalAccounts} Participant Joined
                          </p>
                        </div>
                      </div>,
                    )}
                  </div>
                </div>
                {RIF(
                  role !== 'viewer' && !isEditingLiveProject,
                  <div
                    data-testid='dexcom_device_tag_dropdown_btn'
                    onClick={() => {
                      setDisplayDexcomDropdown(!displayDexcomDropdown)
                      setDeviceRemoveType('dexcom')
                    }}
                    ref={dexcomDropdownRef}
                    id='displayDexcomDropdownBtn'
                    css={{
                      color: color.grey_400,
                      width: '32px',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '24px',
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: '3px',
                      ':hover': {
                        background: '#0000000A',
                      },
                    }}
                  >
                    ⋮
                    {RIF(
                      displayDexcomDropdown,
                      <div
                        css={{
                          width: '80px',
                          height: '44px',
                          border: `1px solid ${color.grey_160}`,
                          borderRadius: '5px',
                          position: 'absolute',
                          right: 0,
                          top: 25,
                          background: color.white,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          data-testid='remove_dexcom_btn'
                          onClick={() => {
                            setShowDeviceRemovePopup(true)
                          }}
                          id='removeDexcomBtn'
                          css={{
                            width: '100%',
                            textAlign: 'center',
                            padding: '6px',
                            ':hover': {
                              background: '#0000000A',
                            },
                          }}
                        >
                          Remove
                        </span>
                      </div>,
                    )}
                  </div>,
                )}
              </button>,
            )}

            {/* smartphone
            {RIF(smartphoneTaskData,
              <div css={{
                borderRadius: '5px',
                border: `1px solid ${color.grey_160}`,
                height: '90px',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px 0 10px'}}>

                <div css={{display: 'flex', alignItems: 'center'}}>
                  <img src={SmartPhoneImg} width='100' height='100'/>
                  <div css={{marginLeft: '10px'}}>
                    <div css={{fontWeight: fontWeight.bold, fontSize: '16px'}}>
                      Smart Phone</div>
                    <div css={{color: color.grey_400, marginTop: '8px'}}>Accelerometer (10 Hz)</div>
                  </div>
                </div>
                <div
                  css={{color: color.primary, fontWeight: fontWeight.bold, cursor: 'pointer'}}>
                  Edit</div>
              </div>)} */}

            {/* polar */}
            {/* {RIF(
              false,
              <div
                css={{
                  borderRadius: '5px',
                  border: `1px solid ${color.grey_160}`,
                  height: '90px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 20px 0 10px',
                }}
              >
                <div css={{display: 'flex', alignItems: 'center'}}>
                  <img src={PolarImg} width='100' height='100' />
                  <div css={{marginLeft: '10px'}}>
                    <div css={{fontWeight: fontWeight.bold, fontSize: '16px'}}>Polar H10</div>
                    <div css={{color: color.grey_400, marginTop: '8px'}}>Heart Rate Interval (Each Beat)</div>
                  </div>
                </div>
                <div css={{color: color.primary, fontWeight: fontWeight.bold, cursor: 'pointer'}}>Edit</div>
              </div>,
            )} */}
          </div>
        </div>
      </div>
    </>
  )
}

interface GarminDeviceTaskDetail {
  heartRateEnable: boolean
  stressEnable: boolean
  stepsEnable: boolean
  respirationEnable: boolean
  pulseOxEnable: boolean
  bbiEnable: boolean
  zeroCrossingEnable: boolean
  actigraphyEnable: boolean
  heartRateSampleRate: number
  stressSampleRate: number
  respirationSampleRate: number
  pulseOxSampleRate: number
  zeroCrossingSampleRate: number
  actigraphySampleRate: number
  actigraphyZeroCrossingThreshold: number
  actigraphyEnergyThreshold: number
  temperatureEnable: boolean
  temperatureSampleRate: number
}

export const convertGarminDescription = (
  garminDeviceTaskDetail: GarminDeviceTaskDetail | undefined | null,
  garminDeviceEnable: boolean,
  garminStreamEnable: boolean,
) => {
  const convertUnit = (num: number) => {
    if (num >= 60) return `${num / 60} min`
    if (num === 1) return `${num} sec`
    return `${num} secs`
  }
  let outputString = ''
  if (!garminDeviceEnable) outputString = `${garminStreamEnable ? '3-axis Accelerometer (25-100 Hz), ' : ''}`
  if (garminDeviceEnable && garminDeviceTaskDetail) {
    const {
      heartRateEnable,
      heartRateSampleRate,
      stressEnable,
      stressSampleRate,
      stepsEnable,
      respirationEnable,
      respirationSampleRate,
      pulseOxEnable,
      pulseOxSampleRate,
      bbiEnable,
      zeroCrossingEnable,
      zeroCrossingSampleRate,
      actigraphyEnable,
      actigraphySampleRate,
      actigraphyZeroCrossingThreshold,
      actigraphyEnergyThreshold,
      temperatureEnable,
      temperatureSampleRate,
    } = garminDeviceTaskDetail
    outputString = `
      ${heartRateEnable ? `Heart Rate (${convertUnit(heartRateSampleRate)}), ` : ''}
      ${stressEnable ? `Stress (${convertUnit(stressSampleRate)}), ` : ''}
      ${stepsEnable ? `Steps (1 min), ` : ''}
      ${respirationEnable ? `Respiration (${convertUnit(respirationSampleRate)}), ` : ''}
      ${pulseOxEnable ? `Oxygen Saturation (${convertUnit(pulseOxSampleRate)}), ` : ''}
      ${bbiEnable ? `Heart Rate Interval (Each Beat), ` : ''}
      ${zeroCrossingEnable ? `Zero Crossings (${convertUnit(zeroCrossingSampleRate)}), ` : ''}
      ${temperatureEnable ? `Skin Temperature (${convertUnit(temperatureSampleRate)}), ` : ''}
      ${garminStreamEnable ? '3-axis Accelerometer (25-100 Hz), ' : ''}
      ${actigraphyEnable ? `Actigraphy (${convertUnit(actigraphySampleRate)}), ` : ''}
      ${actigraphyEnable ? `Zero Crossing Threshold (${actigraphyZeroCrossingThreshold} mG), ` : ''}
      ${actigraphyEnable ? `Energy Threshold (${actigraphyEnergyThreshold} mG), ` : ''}
    `
  }
  return outputString
}

const convertSecondsToMinOrHr = (seconds: number) => {
  const SECONDS_IN_ONE_MINUTE = 60
  const SECONDS_IN_ONE_HOUR = 3600
  if (seconds >= SECONDS_IN_ONE_HOUR) {
    return `Task Max Length (${seconds / SECONDS_IN_ONE_HOUR} hr), `
  }
  return `Task Max Length (${seconds / SECONDS_IN_ONE_MINUTE} min), `
}

export const convertMovesenseDescription = (movesenseTask: IStopwatchMovesenseStream) => {
  const imu = movesenseTask.imuEnable ? `IMU (${movesenseTask.imuHz} Hz), ` : ''
  const acc = movesenseTask.accEnable ? `Accelerometer (${movesenseTask.accHz} Hz), ` : ''
  const gyro = movesenseTask.gyroscopeEnable ? `Gyrometer (${movesenseTask.gyroscopeHz} Hz), ` : ''
  const magnet = movesenseTask.magnetometerEnable ? `Magnometer (${movesenseTask.magnetometerHz} Hz), ` : ''
  const ecg = movesenseTask.ecgEnable ? `ECG Waveform (${movesenseTask.ecgHz} Hz), ` : ''
  const rr = movesenseTask.rrEnable ? `RR Interval (Each Beat), ` : ''
  const timeLimit = convertSecondsToMinOrHr(movesenseTask.timeLimitSeconds) ?? ''

  return (imu + acc + gyro + magnet + ecg + rr + timeLimit).replaceAll('13', '12.5').trim().slice(0, -1)
}

export const convertGarminStreamDescription = (garminStreamTask: IStopwatchGarminStream) => {
  const garminAccEnable = garminStreamTask.accEnable ? `Accelerometer (${garminStreamTask.accHz} Hz), ` : ''
  const timeLimit = convertSecondsToMinOrHr(garminStreamTask.timeLimitSeconds) ?? ''
  return (garminAccEnable + timeLimit).trim().slice(0, -1)
}
