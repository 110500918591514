import {Nullish} from 'utility-types'
import {IdentityType, IIdentity} from '../shared/db'

const staffUserEmailList: ReadonlyArray<string> = ['support@labfront.com', 'admin@labfront.com'] as const

export const checkIsStaffUser = (identity: Partial<IIdentity> | Nullish): boolean => {
  if (identity?.type === IdentityType.Admin) {
    return true
  }
  if (identity?.email) {
    const lowerCaseEmail = identity.email.toLowerCase()
    for (const staffEmail of staffUserEmailList) {
      if (lowerCaseEmail === staffEmail) {
        return true
      }
    }
  }
  return false
}
