import {ReactElement, ReactNode, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  _,
  checkIsStaffUser,
  collaboratorTypeHasRole,
  loadSessionStorage,
  RequestResult,
  RIF,
  useCollaboratorTypeInProject,
  useCurrentProjectState,
  useCurrentWorkspaceAccountPlan,
  useCurrentWorkspaceState,
} from '../../lib'

import {createDispatchActions, selectCollaborators, selectTheme} from '../../store'

import {
  Avatars,
  ButtonReverse,
  CollaboratorPage,
  DataDownloadPage,
  PopupDeleteProject,
  PopupResetProject,
  ProjectSwitchBar,
  SaveBar,
  Select,
  SlideCheckbox,
} from '..'

import {Collaborator} from '../../model'
import {
  ProjectAuditTrailAction,
  ProjectCollaboratorRole,
  ProjectStatus,
  WorkspaceCollaboratorRole,
} from '../../shared/db'

const BACKGROUND_SYNC_OPTIONS = [
  {value: 6 * 60 * 60, label: 'Every 6 hrs'},
  {value: 12 * 60 * 60, label: 'Every 12 hrs'},
  {value: 24 * 60 * 60, label: 'Every 24 hrs'},
]

export const ProjectSettingsPage = () => {
  const {color, pad, fontWeight, fontSize} = selectTheme()
  const navigate = useNavigate()
  const {
    doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH,
    doREQUEST_PROJECT_UPDATE,
    doREQUEST_PROJECT_RESET,
    doREQUEST_PROJECT_STATUS_UPDATE,
    doREQUEST_PROJECT_AUDIT_TRAIL_CREATE,
  }: any = createDispatchActions()
  const [requestResultOfWorksapceCollaboratorListFetch, setRequestResultOfWorksapceCollaboratorListFetch] =
    useState<RequestResult | null>(null)
  const [requestResultOfProjectUpdate, setRequestResultOfProjectUpdate] = useState<RequestResult | null>(null)
  const [requestResultOfProjectStatusUpdate, setRequestResultOfProjectStatusUpdate] =
    useState<RequestResult | null>(null)
  const [requestResultOfProjectReset, setRequestResultOfProjectReset] = useState<RequestResult | null>(null)
  const {workspaceId, workspace: currentWorkspace} = useCurrentWorkspaceState()
  const collaboratorsState = selectCollaborators()
  const {projectId, project} = useCurrentProjectState()
  const batchId = project?.batchList?.[0]?.id
  const collaboratorType = useCollaboratorTypeInProject()
  const {hasActivePlanPurchase} = useCurrentWorkspaceAccountPlan()
  const loadSession = loadSessionStorage()
  const isStaffUser = checkIsStaffUser(loadSession?.identity)
  const [backgroundSyncEnabled, setBackgroundSyncEnabled] = useState(project?.backgroundSyncIntervalSeconds !== null)
  const [backgroundSyncInterval, setBackgroundSyncInterval] = useState(project?.backgroundSyncIntervalSeconds ?? null)
  const [prevStatus, setPrevStatus] = useState<ProjectStatus | null>(null)

  const [displayCollaboratorsPage, setDisplayCollaboratorsPage] = useState(false)
  const [displayResetProjectPopup, setDisplayResetProjectPopup] = useState(false)
  const [displayDeleteProjectPopup, setDisplayDeleteProjectPopup] = useState(false)
  const [collaboratorList, setCollaboratorList] = useState<Collaborator[]>([])
  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)

  const canUpdateProject = collaboratorTypeHasRole({
    collaboratorType: collaboratorType,
    projectRole: ProjectCollaboratorRole.ProjectUpdate,
    workspaceRole: WorkspaceCollaboratorRole.ProjectUpdate,
  })
  const canDeleteProject = collaboratorTypeHasRole({
    collaboratorType: collaboratorType,
    projectRole: ProjectCollaboratorRole.ProjectStatusUpdate,
    workspaceRole: WorkspaceCollaboratorRole.ProjectDelete,
  })

  useEffect(() => {
    if (collaboratorsState[workspaceId ?? '']) {
      const collaboratorList: Collaborator[] = Object.values(collaboratorsState[workspaceId ?? ''])
      const owner: Collaborator = {
        id: currentWorkspace?.ownerProfile?.id ?? '',
        identityId: currentWorkspace?.ownerProfile?.identityId,
        workspaceId: workspaceId ?? '',
        type: 'owner',
        profile: {...currentWorkspace?.ownerProfile},
      }
      collaboratorList.splice(0, 0, owner).filter((c) => {
        if (['owner', 'admin'].includes(c.type)) return true
        if (c.type === 'project_collaborator') {
          if (_.find(c.projectCollaboratorList, ['projectId', projectId])) return true
          // if (findResult?.type === 'projectAdmin') return true
        }
        return false
      })
      setCollaboratorList(collaboratorList)
    } else if (workspaceId && !collaboratorsState[workspaceId] && !requestResultOfWorksapceCollaboratorListFetch) {
      doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH({
        setRequestResult: setRequestResultOfWorksapceCollaboratorListFetch,
        payload: {
          workspaceId,
          projectId,
        },
      })
    }
  }, [collaboratorsState, workspaceId])

  useEffect(() => {
    if (!project) {
      return
    }

    const projectBackgroundEnabled = project.backgroundSyncIntervalSeconds !== null
    const projectBackgroundSyncInterval = project.backgroundSyncIntervalSeconds
    if (projectBackgroundEnabled !== backgroundSyncEnabled) {
      setBackgroundSyncEnabled(projectBackgroundEnabled)
    }
    if (projectBackgroundSyncInterval !== backgroundSyncInterval) {
      setBackgroundSyncInterval(projectBackgroundSyncInterval ?? null)
    }
  }, [
    // backgroundSyncEnabled,
    // backgroundSyncInterval,
    project,
    requestResultOfProjectUpdate,
  ])

  const handleBackgroundSyncSettingChanged = (arg: {enabled: boolean} | {interval: number}) => {
    const backgroundSyncIntervalSeconds: number | null = (() => {
      if ('enabled' in arg) {
        if (arg.enabled) {
          return BACKGROUND_SYNC_OPTIONS[1].value
        } else {
          return null
        }
      } else if ('interval' in arg) {
        return arg.interval
      } else {
        return null
      }
    })()
    doREQUEST_PROJECT_UPDATE({
      setRequestResult: setRequestResultOfProjectUpdate,
      payload: {
        projectId: projectId,
        backgroundSyncIntervalSeconds: backgroundSyncIntervalSeconds,
      },
    })
    setBackgroundSyncEnabled(backgroundSyncIntervalSeconds !== null)
    setBackgroundSyncInterval(backgroundSyncIntervalSeconds)
  }

  const handleDeleteProject = () => {
    setPrevStatus(project?.status ?? null)
    doREQUEST_PROJECT_STATUS_UPDATE({
      setRequestResult: setRequestResultOfProjectStatusUpdate,
      payload: {
        projectId,
        status: ProjectStatus.Deleted,
        batchId, // order to delete batch
      },
    })
  }

  const handleResetProject = () => {
    setPrevStatus(project?.status ?? null)
    doREQUEST_PROJECT_RESET({
      setRequestResult: setRequestResultOfProjectReset,
      payload: {projectId, batchId},
    })
  }

  useEffect(() => {
    if (!requestResultOfProjectStatusUpdate?.success) return
    setDisplayDeleteProjectPopup(false)
    if (projectId && !isStaffUser) {
      doREQUEST_PROJECT_AUDIT_TRAIL_CREATE({
        payload: {
          projectId: projectId,
          action: ProjectAuditTrailAction.Update,
          before: {status: prevStatus},
          after: {status: ProjectStatus.Deleted},
        },
      })
    }
    return navigate('/project')
  }, [requestResultOfProjectStatusUpdate])

  useEffect(() => {
    if (!requestResultOfProjectReset?.success) return
    setDisplayResetProjectPopup(false)
    if (projectId) {
      if (!isStaffUser) {
        doREQUEST_PROJECT_AUDIT_TRAIL_CREATE({
          payload: {
            projectId,
            action: ProjectAuditTrailAction.Update,
            before: {status: prevStatus},
            after: {status: ProjectStatus.Draft},
          },
        })
      }
      return navigate(`/project_setup/${projectId}`)
    }
  }, [requestResultOfProjectReset])

  return (
    <>
      {RIF(
        displayResetProjectPopup,
        <PopupResetProject
          onClickReset={() => handleResetProject()}
          onClickClose={() => setDisplayResetProjectPopup(false)}
        />,
      )}
      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage
          {...{
            closeAction: () => {
              setRenderDataDownloadPage(false)
            },
          }}
        />,
      )}
      {RIF(
        displayDeleteProjectPopup,
        <PopupDeleteProject
          projectName={project?.name ?? ''}
          onClickDelete={() => handleDeleteProject()}
          onClickClose={() => setDisplayDeleteProjectPopup(false)}
        />,
      )}
      {RIF(displayCollaboratorsPage, <CollaboratorPage setDisplayCollaboratorsPage={setDisplayCollaboratorsPage} />)}
      {RIF(
        project?.status !== ProjectStatus.Draft,
        <ProjectSwitchBar {...{setRenderDataDownloadPage}} projectPanel='Settings' />,
      )}
      <div
        css={{
          width: '100vw',
          minHeight: project?.status === ProjectStatus.Draft ? '100vh' : 'calc(100vh - 106px)',
          backgroundColor: color.background,
          padding: project?.status === ProjectStatus.Draft ? '90px 0 40px' : '40px 0',
          position: 'relative',
        }}
      >
        {RIF(
          project?.status === ProjectStatus.Draft,
          <SaveBar closeAction={() => navigate(`/project_setup/${projectId}`)} hideButton />,
        )}
        <p
          css={{
            position: 'absolute',
            top: project?.status === ProjectStatus.Draft ? '80px' : '30px',
            left: '30px',
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h3,
          }}
        >
          Settings
        </p>
        <SettingsBlock
          btnTextAction={() => setDisplayCollaboratorsPage(true)}
          title='Collaborators'
          btnText='Manage Collaborators'
          showBtnText={true}
        >
          <div css={{color: color.grey_400, marginTop: '10px'}}>
            Total <span>{collaboratorList.length}</span> Collaborators.
          </div>
          <Avatars css={{marginTop: '20px', marginLeft: '0'}} {...{users: collaboratorList}} />
        </SettingsBlock>
        {RIF(
          canUpdateProject && hasActivePlanPurchase,
          <>
            <SettingsBlock title='Background Sync'>
              <p
                css={{
                  margin: '8px 0 24px',
                  color: color.grey_400,
                }}
              >
                This is an experimental feature, it is not guaranteed to work on all devices. When it is working, the
                Labfront Direct Garmin data should have the sync time reduced. However, we still need to upload the data
                which cannot be done in the background. We recommend participants to open the app every 3-5 days.
                <br />
                <br />
                Note: iOS and Android has different wake mechanisms with varying reliability. For example: the current
                version of iOS limits our ability to call the app awake, and may not work in many cases.
              </p>
              <div css={{display: 'flex', alignItems: 'center'}}>
                <SlideCheckbox
                  value={backgroundSyncEnabled}
                  onChange={(enabled) => {
                    handleBackgroundSyncSettingChanged({enabled})
                  }}
                />
                <p
                  css={{
                    fontWeight: fontWeight.bold,
                    marginLeft: pad.small,
                    color: `${backgroundSyncEnabled ? color.primary : color.black}`,
                  }}
                >
                  {backgroundSyncEnabled ? 'ON' : 'OFF'}
                </p>
              </div>
              {RIF(
                backgroundSyncInterval,
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    background: color.surface.blue.light,
                    borderRadius: '5px',
                    marginTop: '16px',
                    padding: '16px',
                    width: 'max-content',
                  }}
                >
                  <p css={{fontSize: fontSize.h6}}>Frequency of syncing</p>
                  <Select
                    value={BACKGROUND_SYNC_OPTIONS.find(({value}) => value === backgroundSyncInterval)}
                    options={BACKGROUND_SYNC_OPTIONS}
                    isSearchable={false}
                    onChange={(e: {value: number; label: string}) =>
                      handleBackgroundSyncSettingChanged({interval: e.value})
                    }
                    css={{fontSize: fontSize.h6, marginLeft: '16px'}}
                  />
                </div>,
              )}
            </SettingsBlock>
          </>,
        )}
        {RIF(
          canUpdateProject && project?.status !== ProjectStatus.Draft,
          <SettingsBlock title='Reset Project' showBtnText={false}>
            <p
              css={{
                margin: '8px 0 24px',
                color: color.grey_400,
              }}
            >
              This action will disconnect all participants and delete all their collected data.
            </p>
            <ButtonReverse onClick={() => setDisplayResetProjectPopup(true)} btnColor='warning'>
              Reset Project To Draft
            </ButtonReverse>
          </SettingsBlock>,
        )}
        {RIF(
          canDeleteProject,
          <SettingsBlock title='Delete Project' showBtnText={false}>
            <p
              css={{
                margin: '8px 0 24px',
                color: color.grey_400,
              }}
            >
              This will remove all project settings, content and previously collected data. This action cannot be
              undone.
            </p>
            <ButtonReverse onClick={() => setDisplayDeleteProjectPopup(true)} btnColor='warning'>
              Delete Project
            </ButtonReverse>
          </SettingsBlock>,
        )}
      </div>
    </>
  )
}

interface SettingsBlockProps {
  title: string
  btnText?: string
  btnTextAction?: () => void
  showBtnText?: boolean
  children?: ReactElement | ReactElement[] | ReactNode
}

const SettingsBlock = (props: SettingsBlockProps) => {
  const {color, fontWeight} = selectTheme()
  const {title, btnText, btnTextAction, showBtnText, ...rest} = props
  return (
    <div
      css={{
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        background: color.white,
        margin: '0 auto 14px',
        padding: '30px',
        width: '592px',
      }}
    >
      <div css={{display: 'flex', justifyContent: 'space-between', alignItems: 'start'}}>
        <div css={{fontSize: '16px', fontWeight: fontWeight.bold}}>{title}</div>
        {RIF(
          showBtnText,
          <div onClick={btnTextAction} css={{color: color.primary, fontWeight: fontWeight.bold, cursor: 'pointer'}}>
            {btnText}
          </div>,
        )}
      </div>
      {/* pass HTML here */}
      <div {...rest} />
    </div>
  )
}
