import React, {useState, useEffect} from 'react'
import {RIF} from '../../lib'
import {selectTheme} from '../../store'
import {CSSObject} from '@emotion/react'

interface QuestionnaireTextareaProps {
  defaultValue?: string
  initialValue: string
  onChangeAction: (arg: string) => void
  placeholder: string
  maxLength: number
  rows: number
}

export const QuestionnaireTextarea = (props: QuestionnaireTextareaProps) => {
  const {color, fontWeight, pad} = selectTheme()
  const {defaultValue, initialValue, placeholder, onChangeAction, maxLength, rows} = props
  const [focusing, setFocusing] = useState(false)
  const [inputValue, setInputValue] = useState(defaultValue || '')

  useEffect(() => {
    setInputValue(defaultValue || '')
  }, [defaultValue])

  const handleFocus = () => {
    setFocusing(true)
    if (inputValue === initialValue) {
      setInputValue('')
    }
  }

  const handleBlur = () => {
    setFocusing(false)
    if (!inputValue) {
      setInputValue(initialValue)
      if (onChangeAction) onChangeAction(initialValue)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
    if (onChangeAction) onChangeAction(e.target.value)
  }

  return (
    <div
      css={{
        width: '100%',
        height: '40px',
        position: 'relative',
        borderRadius: '5px',
        overflow: 'hidden',
        border: `1px solid ${focusing ? color.grey_400 : color.disabled}`,
        backgroundColor: color.white,
        ':hover': {
          border: `1px solid ${color.grey_400}`,
        },
      }}
    >
      <textarea
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        rows={rows}
        value={inputValue}
        placeholder={placeholder}
        maxLength={maxLength}
        css={{
          border: 'none',
          width: '100%',
          height: '100%',
          resize: 'none',
          padding: '11px 100px 11px 16px',
          backgroundColor: color.white,
          ':focus': {
            outline: 'none',
          },
        }}
      />
      {RIF(
        focusing,
        <p
          css={{
            color: color.grey_400,
            fontWeight: fontWeight.medium,
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            right: '20px',
            bottom: '12px',
          }}
        >
          {inputValue.length} / {maxLength}
        </p>,
      )}
    </div>
  )
}
