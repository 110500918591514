import {useState, Dispatch, SetStateAction} from 'react'
import {Controller} from 'react-hook-form'
import {RIF, _} from '../../lib'
import {selectTheme} from '../../store'
import {EditingTask} from '../../model'
import {CalendarIcon} from '../../asset/image'
import {Input, Label, RequiredTag, RadioGroup, RadioOption} from '..'

export interface AddTaskScheduleSectionPropsT {
  control: any
  register: any
  watch: any
  formState: any
  taskScheduleReadonly: boolean
  setTaskScheduleReadonly: Dispatch<SetStateAction<boolean>>
  scheduleDescriptionLength: number
  setScheduleDescriptionLength: Dispatch<SetStateAction<number>>
  isEditing: EditingTask | null
}

export const AddTaskScheduleSection = (props: AddTaskScheduleSectionPropsT) => {
  const {color} = selectTheme()
  const {control, register, watch, formState, isEditing, taskScheduleReadonly, scheduleDescriptionLength} = props

  const [showOneTimePopup, setShowOneTimePopup] = useState(false)
  const [showRepeatPopup, setShowRepeatPopup] = useState(false)
  const radioGroupDefaultValue = isEditing?.id ? (isEditing?.scheduleRepeat ? 'repeat' : 'oneTime') : 'repeat'
  const radioOptionDefaultValue = isEditing?.id ? isEditing?.scheduleRepeat : true

  return (
    <div css={{marginBottom: '40px', paddingTop: '40px', borderTop: `1px solid ${color.grey_100}`}}>
      <Controller
        control={control}
        name='scheduleRepeat'
        defaultValue={radioGroupDefaultValue}
        render={({field: {name, value, onChange}}) => (
          <RadioGroup
            {...{
              name: name,
              value: value,
              onChange: onChange,
            }}
          >
            <div css={{display: 'flex', alignItems: 'center', marginBottom: '8px', gap: '8px'}}>
              <img src={CalendarIcon} width={20} height={20} />
              <Label>Schedule</Label>
              <RequiredTag />
            </div>
            <p css={{color: color.grey_600, marginBottom: '16px'}}>
              Set up task schedule so participants know when they should complete this task.
            </p>

            <div css={{background: color.grey_50, borderRadius: '5px', padding: '24px'}}>
              <p css={{fontWeight: 500}}>Is this task repeatable or is it a one-time-only event?</p>

              {/* ---------- Radio: repeat ---------- */}
              <div
                onMouseEnter={() => taskScheduleReadonly && setShowRepeatPopup(true)}
                onMouseLeave={() => taskScheduleReadonly && setShowRepeatPopup(false)}
                css={{position: 'relative', marginTop: '32px'}}
              >
                <RadioOption
                  disabled={taskScheduleReadonly}
                  defaultChecked={radioOptionDefaultValue}
                  value={'repeat'}
                  label='Repeatable'
                />
                {RIF(
                  showRepeatPopup,
                  <div
                    css={{
                      position: 'absolute',
                      left: '-103px',
                      bottom: '25px',
                      width: '221px',
                      borderRadius: '5px',
                      padding: '10px 8px',
                      textAlign: 'center',
                      background: color.black,
                      color: color.white,
                    }}
                  >
                    You cannot edit the task schedule on a live project.
                  </div>,
                )}
              </div>

              <p css={{color: color.grey_600, marginTop: '5px', marginLeft: '27px'}}>
                For tasks that can be completed multiple times. The task will continue to show up on the task list.
              </p>

              {RIF(
                watch('scheduleRepeat') === 'repeat',
                <ScheduleDescriptionSection
                  {...{
                    register,
                    formState,
                    isEditing,
                    taskScheduleReadonly,
                    placeholder: 'i.e. Every day, Once a week, Every other night',
                  }}
                />,
              )}

              {/* ---------- Radio: on time ---------- */}
              <div
                onMouseEnter={() => taskScheduleReadonly && setShowOneTimePopup(true)}
                onMouseLeave={() => taskScheduleReadonly && setShowOneTimePopup(false)}
                css={{position: 'relative', marginTop: '32px'}}
              >
                <RadioOption
                  disabled={taskScheduleReadonly}
                  defaultChecked={!radioOptionDefaultValue}
                  value={'oneTime'}
                  label='One Time'
                />
                {RIF(
                  showOneTimePopup,
                  <div
                    css={{
                      position: 'absolute',
                      left: '-103px',
                      bottom: '25px',
                      width: '221px',
                      borderRadius: '5px',
                      padding: '10px 8px',
                      textAlign: 'center',
                      background: color.black,
                      color: color.white,
                    }}
                  >
                    You cannot edit the task schedule on a live project.
                  </div>,
                )}
              </div>

              <p css={{color: color.grey_600, marginTop: '5px', marginLeft: '27px'}}>
                For tasks that should only be completed once. When completed, the task will be removed from the task
                list.
              </p>

              {RIF(
                watch('scheduleRepeat') === 'oneTime',
                <ScheduleDescriptionSection
                  {...{
                    register,
                    formState,
                    isEditing,
                    taskScheduleReadonly,
                    placeholder: 'i.e. First day of study, Last day of study',
                  }}
                />,
              )}
            </div>
          </RadioGroup>
        )}
      />
    </div>
  )
}

export interface ScheduleDescriptionSectionPropsT {
  register: any
  formState: any
  isEditing: EditingTask | null
  taskScheduleReadonly: boolean
  placeholder: string
}

export const ScheduleDescriptionSection = (props: ScheduleDescriptionSectionPropsT) => {
  const {color} = selectTheme()
  const {register, formState, isEditing, taskScheduleReadonly, placeholder} = props

  return (
    <div css={{background: color.grey_20, padding: '16px', borderRadius: '5px', marginTop: '16px', marginLeft: '27px'}}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '8px',
          gap: '8px',
        }}
      >
        <p css={{fontWeight: '500'}}>Schedule Instruction</p>
        <RequiredTag />
      </div>
      <Input
        {...register('scheduleDescription', {required: true})}
        autoComplete='off'
        readOnly={taskScheduleReadonly}
        placeholder={placeholder}
        maxLength='50'
        value={isEditing?.scheduleDescription}
        hasError={formState.errors.scheduleDescription ? true : false}
      />
      {RIF(
        formState.errors.scheduleDescription,
        <p css={{color: color.task.orange, margin: '7px 0 0 30px'}}>Please enter task schedule</p>,
      )}
    </div>
  )
}
