import React from 'react'
import {selectTheme} from '../../store'

export const RequiredTag = () => {
  const {color} = selectTheme()
  return (
    <div
      css={{
        background: '#EB72570D',
        borderRadius: '5px',
        color: color.warning,
        fontSize: '12px',
        padding: '4px 6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Required
    </div>
  )
}
