import {PopupConfirmation} from '..'

export interface ProjectResetPopPropsT {
  onClickReset: () => void
  onClickClose: () => void
}

export const PopupResetProject = (props: ProjectResetPopPropsT) => {
  const {onClickReset, onClickClose} = props

  return (
    <div
      data-testid='project_reset_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PopupConfirmation
        {...props}
        method='Reset'
        name='Project'
        description='Your project will reset to draft with the current setup.'
        term1='I understand that this will disconnect all participants and log them out of the project on the participant app.'
        term2="I understand that this will delete all the participants' information and data."
        buttonText='Reset Project to Draft'
        closeAction={() => onClickClose()}
        doAction={() => onClickReset()}
      />
    </div>
  )
}
