export enum OperationType {
  UpsertTaskRecord = 'upsert_task_record',
  UpsertTaskRecordCompletionDataTime = 'upsert_task_record_completion_data_time',
  UpsertDeviceStreamData = 'upsert_device_stream_data',
  UpsertGarminDeviceData = 'upsert_garmin_device_data',
  UpsertGarminConnectData = 'upsert_garmin_connect_data',
  UpsertDexcomData = 'upsert_dexcom_data',
  UpsertAnalyzedData = 'upsert_analyzed_data',
  RequestAnalysis = 'request_analysis',
  SetDataMigrated = 'set_data_migrated',
  SetGarminConnectDataMigrated = 'set_garmin_connect_data_migrated',
  UpsertGenericFileIndex = 'upsert_generic_file_index',
}

export interface UpsertDataErrorLog {
  createdAt: Date
  operationType: OperationType
  sourceId: string[]
  result?: object
  msg?: string
  participantId?: string
}
