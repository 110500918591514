import {SetStateAction, Dispatch} from 'react'
import {handleEsc} from '../../lib'
import {selectTheme} from '../../store'
import {Legend, ButtonCancel, TextLink, Button, PopupTemplate, PopupSize, ButtonColorTheme} from '..'

export interface PopupConfirmDiscardQuestionnaireChangesProps {
  closeAction: Dispatch<SetStateAction<boolean>>
  confirmAction: () => void
}

export const PopupConfirmDiscardQuestionnaireChanges = (props: PopupConfirmDiscardQuestionnaireChangesProps) => {
  const {color, fontWeight} = selectTheme()

  const {closeAction, confirmAction} = props

  const handleCloseAction = () => {
    if (closeAction) closeAction(false)
  }

  const handleConfirmAction = () => {
    if (confirmAction) confirmAction()
  }

  handleEsc(closeAction)

  return (
    <PopupTemplate
      {...{
        title: 'Discard unsaved changes',
        description:
          'You have unsaved changes in your questionnaire. Are you sure you want to exit without saving? All unsaved changes will be discarded.',
        background: true,
        handleClosePopup: handleCloseAction,
        popupSize: PopupSize.Small,
        mainFunction: handleConfirmAction,
        buttonContent: 'Confirm',
        buttonColorTheme: ButtonColorTheme.Warning,
      }}
    />
  )
}
