import Lottie from 'lottie-web'
import AddingAnimation from '../../asset/lotties/adding_animation.json'
import {useEffect, createRef, SetStateAction, Dispatch} from 'react'
import {selectTheme} from '../../store'
import {Button, ButtonCancel, ProfileSettingsProcessMethodString} from '..'
import {RIF} from '../../lib'
import {TickWhiteIcon} from '../../asset/image'
import {QuestionnaireTempQuestion, QuestionnaireTempSection} from '../../model'

export interface SaveBarProps {
  closeAction?: (arg: boolean) => void | Dispatch<SetStateAction<boolean>>
  title?: string
  viewOnly?: boolean
  html?: any
  formId?: string
  hideButton?: boolean
  buttonText?: string | any
  disabled?: boolean
  handleClickSave?: () => void
  css?: any
  isSubmitting?: boolean
  finishedSubmitting?: boolean
}

/* header bar with save and close button */
export const SaveBar = (props: SaveBarProps) => {
  const {pad, color} = selectTheme()
  const {
    closeAction,
    title,
    viewOnly,
    html,
    formId,
    hideButton,
    buttonText,
    disabled,
    handleClickSave,
    isSubmitting,
    finishedSubmitting,
    ...rest
  } = props

  const clickSave = () => {
    if (handleClickSave) handleClickSave()
  }

  const handleClickCloseButton = () => {
    if (closeAction) {
      closeAction(false)
    }
  }

  const animRef: any = createRef()
  // save button animation
  useEffect(() => {
    if (isSubmitting && !finishedSubmitting) {
      Lottie.loadAnimation({
        container: animRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: AddingAnimation,
      })
    }
  }, [isSubmitting, finishedSubmitting])

  return (
    <div
      {...rest}
      css={{
        width: '100vw',
        height: '50px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.13)',
        padding: `0 ${pad.large}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        backgroundColor: color.white,
        zIndex: 101,
      }}
    >
      {/* left */}
      <div css={{display: 'flex', alignItems: 'center'}}>
        <ButtonCancel data-testid='save_bar_cancel_btn' onClick={handleClickCloseButton} />
        <p css={{marginLeft: '8px'}}>{title}</p>
      </div>
      {/* right */}
      {RIF(
        !viewOnly,
        <div css={{display: 'flex', alignItems: 'center'}}>
          {html}
          {RIF(
            !hideButton,
            <>
              {RIF(
                !isSubmitting,
                <Button
                  data-testid='save_bar_save_btn'
                  onClick={clickSave}
                  type='submit'
                  btnPadding='medium'
                  disabled={disabled}
                >
                  {buttonText ? buttonText : 'Save'}
                </Button>,
              )}
              {RIF(
                isSubmitting,
                <>
                  {RIF(
                    !finishedSubmitting,
                    <Button css={{width: '66.32px', height: '36px'}}>
                      <div ref={animRef} css={{width: '66.32px', height: '35px'}} />
                    </Button>,
                  )}
                  {RIF(
                    finishedSubmitting,
                    <div
                      css={{
                        borderRadius: '5px',
                        width: '66.32px',
                        height: '36px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: color.task.green,
                      }}
                    >
                      <img src={TickWhiteIcon} width='14' />
                      <span css={{color: color.white, marginLeft: '4px'}}>Saved</span>
                    </div>,
                  )}
                </>,
              )}
            </>,
          )}
        </div>,
      )}
    </div>
  )
}
