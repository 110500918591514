import {useState, ChangeEvent, CSSProperties} from 'react'
import {selectTheme} from '../../store'
import {PopupConfirmation, Input} from '..'
import {calculateInputCSS, RIF} from '../../lib'

export interface ProjectDeletePopPropsT {
  projectName: string
  onClickDelete: () => void
  onClickClose: () => void
}

export const PopupDeleteProject = (props: ProjectDeletePopPropsT) => {
  const {color} = selectTheme()
  const {projectName, onClickDelete, onClickClose, ...rest} = props
  const name = projectName ? projectName : 'kiipo'

  const [value, setValue] = useState('')
  const [hasError, setHasError] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setHasError(false)
  }

  return (
    <div
      data-testid='project_delete_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '103',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PopupConfirmation
        {...rest}
        method='Delete'
        name='Project'
        description='Delete a project and its setup, including all participants and collected data. To continue, please enter the project name.'
        term1='I understand that all content within this project, including project setup, participants and collected data will be deleted.'
        term2='I understand that deleted projects cannot be restored.'
        buttonText='Delete Project'
        closeAction={() => onClickClose()}
        doAction={() => {
          if (value.toUpperCase().trim() !== name.toUpperCase().trim()) {
            setHasError(true)
            return
          }
          onClickDelete()
        }}
        checkInputPage={true}
        checkInput={value.length > 0}
      >
        <>
          <Input
            data-testid='enter_project_name_input'
            placeholder={projectName ? projectName : 'Enter Project Name'}
            value={value.toUpperCase()}
            onChange={handleChange}
            css={{...(calculateInputCSS({hasError}) as CSSProperties)}}
          />
          {RIF(
            hasError,
            <div
              css={{
                color: color.attention,
                margin: '8px 0 -15px 0',
              }}
            >
              Please enter correct project name
            </div>,
          )}
        </>
      </PopupConfirmation>
    </div>
  )
}
