import {Dispatch, SetStateAction} from 'react'
import {selectTheme} from '../../store'
import {handleEsc, useProgressiveImg} from '../../lib'
import {Legend, Button, ButtonCancel, OptimizedImage} from '..'
import {TaskType} from '../../shared/db'
import MovesenseInstructionImg from '../../asset/image/movesense_instruction.png'
import GarminStreamInstructionImg from '../../asset/image/garmin_stream_instruction.png'

const deviceTypeData = {
  stopwatch_movesense_stream: {
    testId: 'movesense_instruction_pop',
    image: MovesenseInstructionImg,
    title: 'Next: Add a Movesense Task',
    description: `Your participants will need to activate the data recording through a Movesense Task on the participant app. Please create Movesense Task in the project builder.`,
    btnText: "I'll add a Movesense Task later",
  },
  stopwatch_garmin_stream: {
    testId: 'garmin_stream_instruction_pop',
    image: GarminStreamInstructionImg,
    title: 'Next: Add a Garmin Streaming Task',
    description: `You've selected a high-resolution data type that cannot be collected continuously due to the overwhelming amount of data generated. Your participants will need to activate the data recording through a Garmin Streaming Task on the participant app. Please create a Garmin Streaming Task to guide the participants on what they need to do.`,
    btnText: "I'll add a Garmin Streaming Task later",
  },
}

export interface DeviceInstructionPopPropsT {
  deviceType: TaskType.StopwatchMovesenseStream | TaskType.StopwatchGarminStream
  // setAutoAddTaskType: Dispatch<SetStateAction<TaskType.StopwatchMovesenseStream | TaskType.StopwatchGarminStream | ''>>
  setDisplayInstructionPop: Dispatch<
    SetStateAction<TaskType.StopwatchMovesenseStream | TaskType.StopwatchGarminStream | ''>
  >
}

export const DeviceInstructionPop = (props: DeviceInstructionPopPropsT) => {
  const {color, fontWeight} = selectTheme()

  const {deviceType, setDisplayInstructionPop} = props

  // const imgSrc = useProgressiveImg(MovesenseInstructionPlaceholderImg, MovesenseInstructionImg)

  const closeAction = () => setDisplayInstructionPop('')

  handleEsc(closeAction)

  return (
    <div
      data-testid={deviceTypeData[deviceType].testId}
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
        top: 0,
        left: 0,
        zIndex: 102,
      }}
    >
      <div
        css={{
          top: 'calc(50vh - 300px)',
          left: 'calc(50vw - 300px)',
          position: 'fixed',
          width: '560px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            minHeight: '240px',
          }}
        >
          <div>
            <ButtonCancel onClick={closeAction} />
            <div css={{marginTop: '14px'}}>
              <OptimizedImage src={deviceTypeData[deviceType].image} width={500} height={247} />
              <Legend css={{marginTop: '32px'}}>{deviceTypeData[deviceType].title}</Legend>
              <div css={{margin: '16px 0 40px 0', whiteSpace: 'pre-line'}}>
                {deviceTypeData[deviceType].description}
              </div>
            </div>
          </div>
          <div>
            <div css={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <a
                onClick={closeAction}
                css={{
                  color: color.primary,
                  fontWeight: fontWeight.bold,
                  cursor: 'pointer',
                }}
              >
                {deviceTypeData[deviceType].btnText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
