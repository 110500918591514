import React, {MutableRefObject, useEffect, useState, SetStateAction, Dispatch, CSSProperties} from 'react'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import {_, isInBetaType, RIF, t} from '../../lib'
import {selectTheme, useSelectSettings} from '../../store'
import {
  ButtonMore,
  ButtonMoreSize,
  ButtonReverse,
  ButtonText,
  Checkbox,
  DragHandle,
  QuestionnaireMediaDropdown,
  QuestionnaireInput,
  QuestionnaireOption,
  RequiredTag,
  Select,
  SlideCheckbox,
  Tooltip,
  QuestionnaireInputSize,
  QuestionnaireDropdown,
  // PendingMedia,
} from '..'
import {ContentListItem, QuestionnaireTempQuestion} from '../../model'
import {
  CalendarGreyIcon,
  MultiSelectionIcon,
  MultiChoiceIcon,
  OpenQuestionIcon,
  TimeQuestionIcon,
  DateQuestionIcon,
  DateTimeQuestionIcon,
  NumberQuestionIcon,
  TimeIcon,
  CheckGreyIcon,
  NewCloseIcon,
} from '../../asset/image'
import {BetaType, QuestionnaireQuestionType} from '../../shared/db'
import {components, OptionProps} from 'react-select'

export const QuestionnaireQuestion = (props: {
  id: string
  index: number
  question: QuestionnaireTempQuestion
  questionnaireContentList: ContentListItem[]
  setQuestionnaireContentList: Dispatch<SetStateAction<ContentListItem[]>>
  isEditing: boolean
  setEditingBlock: Dispatch<SetStateAction<string | null>>
  viewOnly: boolean
  addSection: (index: number) => void
  addQuestion: (index: number) => void
  duplicateItem: (index: number) => void
  deleteItem: (index: number) => void
}) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()

  const {
    id,
    index,
    question,
    questionnaireContentList,
    setQuestionnaireContentList,
    isEditing,
    setEditingBlock,
    viewOnly,
    addSection,
    addQuestion,
    duplicateItem,
    deleteItem,
  } = props

  const [hovering, setHovering] = useState(false)
  const settings = useSelectSettings()
  const questionIndex = questionnaireContentList.filter((c) => c.question).findIndex((q) => q.question?.id === id) + 1
  const QuestionTypeOption = (props: {label: string; imageUrl: string}) => {
    const {color} = selectTheme()
    const {label, imageUrl} = props
    return (
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <img src={imageUrl} width={24} height={24} />
        <span css={{color: color.textIcon.secondary}}>{label}</span>
      </div>
    )
  }

  const optionNameMap = {
    [QuestionnaireQuestionType.MultiSelect]: 'Multiple Selection',
    [QuestionnaireQuestionType.Radio]: 'Multiple Choice',
    [QuestionnaireQuestionType.Text]: 'Open Question',
    [QuestionnaireQuestionType.Time]: 'Time',
    [QuestionnaireQuestionType.Date]: 'Date',
    [QuestionnaireQuestionType.DateTime]: 'Date and Time',
    // [QuestionnaireQuestionType.Textarea]: 'Textarea',
    // [QuestionnaireQuestionType.Number]: 'Number',
    // [QuestionnaireQuestionType.Select]: 'Select',
    // [QuestionnaireQuestionType.Checkbox]: 'Checkbox',
  }

  const getIconForType = (type: QuestionnaireQuestionType): string => {
    const icons: Record<QuestionnaireQuestionType, string> = {
      [QuestionnaireQuestionType.MultiSelect]: MultiSelectionIcon,
      [QuestionnaireQuestionType.Radio]: MultiChoiceIcon,
      [QuestionnaireQuestionType.Text]: OpenQuestionIcon,
      [QuestionnaireQuestionType.Time]: TimeQuestionIcon,
      [QuestionnaireQuestionType.Date]: DateQuestionIcon,
      [QuestionnaireQuestionType.DateTime]: DateTimeQuestionIcon,
      [QuestionnaireQuestionType.Number]: NumberQuestionIcon,
      [QuestionnaireQuestionType.Textarea]: NumberQuestionIcon,
      [QuestionnaireQuestionType.Checkbox]: NumberQuestionIcon,
      [QuestionnaireQuestionType.Select]: NumberQuestionIcon,
    }
    return icons[type]
  }

  const questionTypeOptions = Object.entries(optionNameMap).map(([key, label]) => ({
    value: key as QuestionnaireQuestionType,
    label: <QuestionTypeOption label={label} imageUrl={getIconForType(key as QuestionnaireQuestionType)} />,
  }))
  // const questionTypeOptions = [
  //   {
  //     value: QuestionnaireQuestionType.MultiSelect,
  //     label: (
  //       <QuestionTypeOption
  //         {...{
  //           label: optionNameMap[QuestionnaireQuestionType.MultiSelect],
  //           imageUrl: MultiSelectionIcon,
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     value: QuestionnaireQuestionType.Radio,
  //     label: (
  //       <QuestionTypeOption
  //         {...{
  //           label: optionNameMap[QuestionnaireQuestionType.Radio],
  //           imageUrl: MultiChoiceIcon,
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     value: QuestionnaireQuestionType.Text,
  //     label: (
  //       <QuestionTypeOption
  //         {...{
  //           label: optionNameMap[QuestionnaireQuestionType.Text],
  //           imageUrl: OpenQuestionIcon,
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     value: QuestionnaireQuestionType.Time,
  //     label: (
  //       <QuestionTypeOption
  //         {...{
  //           label: optionNameMap[QuestionnaireQuestionType.Time],
  //           imageUrl: TimeQuestionIcon,
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     value: QuestionnaireQuestionType.Date,
  //     label: (
  //       <QuestionTypeOption
  //         {...{
  //           label: optionNameMap[QuestionnaireQuestionType.Date],
  //           imageUrl: DateQuestionIcon,
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     value: QuestionnaireQuestionType.DateTime,
  //     label: (
  //       <QuestionTypeOption
  //         {...{
  //           label: optionNameMap[QuestionnaireQuestionType.DateTime],
  //           imageUrl: DateTimeQuestionIcon,
  //         }}
  //       />
  //     ),
  //   },
  // ]

  const SelectOption = (props: OptionProps<typeof questionTypeOptions>) => {
    return (
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: props.isSelected ? color.surface.default : color.white,
          ':hover': {
            backgroundColor: color.surface.default,
          },
          paddingLeft: '8px',
          paddingRight: '16px',
        }}
      >
        <components.Option
          {...props}
          css={{
            backgroundColor: 'transparent',
          }}
        />
        {RIF(props.isSelected, <img src={CheckGreyIcon} width={16} />)}
      </div>
    )
  }

  if (isInBetaType({betaType: BetaType.ClosedBeta, settings})) {
    questionTypeOptions.push({
      value: QuestionnaireQuestionType.Number,
      label: (
        <QuestionTypeOption
          {...{
            label: 'Number',
            imageUrl: NumberQuestionIcon,
          }}
        />
      ),
    })
  }

  const addOption = () => {
    const newContentList = [...questionnaireContentList]
    const inputConfig = question.inputConfig
    const optionList = inputConfig?.options ?? []
    newContentList.splice(index, 1, {
      question: {...question, inputConfig: {...inputConfig, options: [...optionList, {name: 'New Option'}]}},
    })
    setQuestionnaireContentList(newContentList)
  }

  const editOptionName = (optionIndex: number, name: string) => {
    const newContentList = [...questionnaireContentList]
    const tempQuestion = {...question}
    if (tempQuestion?.inputConfig?.options?.[optionIndex]) {
      tempQuestion.inputConfig.options[optionIndex].name = name
    }
    newContentList.splice(index, 1, {question: tempQuestion})
    setQuestionnaireContentList(newContentList)
  }

  const deleteOption = (optionIndex: number) => {
    const newContentList = [...questionnaireContentList]
    const tempQuestion = {...question}
    if (tempQuestion?.inputConfig?.options) {
      tempQuestion.inputConfig?.options.splice(optionIndex, 1)
    }
    newContentList.splice(index, 1, {question: tempQuestion})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeQuestionType = ({value}: {value: QuestionnaireQuestionType}) => {
    const tempQuestion = {...question, type: value}
    if (
      [
        QuestionnaireQuestionType.Text,
        QuestionnaireQuestionType.Time,
        QuestionnaireQuestionType.Date,
        QuestionnaireQuestionType.DateTime,
      ].includes(value)
    ) {
      tempQuestion.inputConfig = undefined
    }
    if (value === QuestionnaireQuestionType.Number) {
      tempQuestion.inputConfig = {
        integer: false,
        max: undefined,
        min: undefined,
      }
    }
    if (
      [QuestionnaireQuestionType.MultiSelect, QuestionnaireQuestionType.Radio].includes(tempQuestion.type) &&
      ![QuestionnaireQuestionType.MultiSelect, QuestionnaireQuestionType.Radio].includes(question.type)
    ) {
      tempQuestion.inputConfig = {
        options: [{name: 'New Option'}],
        allowOther: false,
      }
    }
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: tempQuestion})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeEnableRange = (value: boolean) => {
    const tempQuestion = {...question, inputConfig: {...question.inputConfig}}
    if (value) {
      tempQuestion.inputConfig.min = 0
      tempQuestion.inputConfig.max = 100
    } else {
      tempQuestion.inputConfig.min = undefined
      tempQuestion.inputConfig.max = undefined
    }
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: tempQuestion})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeInteger = (value: boolean) => {
    const tempQuestion = {...question, inputConfig: {...question.inputConfig}}
    tempQuestion.inputConfig.min = undefined
    tempQuestion.inputConfig.max = undefined
    tempQuestion.inputConfig.integer = value
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: tempQuestion})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeNumberMax = (value: string) => {
    const tempQuestion = {...question, inputConfig: {...question.inputConfig}}
    if (value === '') {
      tempQuestion.inputConfig.max = 0
    } else {
      const number = parseInt(value)
      if (!isNaN(number)) {
        tempQuestion.inputConfig.max = parseInt(value)
      }
    }
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: tempQuestion})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeNumberMin = (value: string) => {
    const tempQuestion = {...question, inputConfig: {...question.inputConfig}}
    if (value === '') {
      tempQuestion.inputConfig.min = 0
    } else {
      const number = parseInt(value)
      if (!isNaN(number)) {
        tempQuestion.inputConfig.min = parseInt(value)
      }
    }
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: tempQuestion})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeQuestionDescription = (value: string) => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: {...question, description: value}})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeRequiredStatus = (e: boolean) => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: {...question, required: e}})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeOfAllowOtherStatus = (e: boolean) => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: {...question, inputConfig: {...question.inputConfig, allowOther: e}}})
    setQuestionnaireContentList(newContentList)
  }

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setEditingBlock(id)
  }

  const handleClickAddNewQuestion = (e: React.MouseEvent) => {
    e.stopPropagation()
    addQuestion(index)
  }

  const handleClickAddNewSection = (e: React.MouseEvent) => {
    e.stopPropagation()
    addSection(index)
  }

  const handleMouseEnter = () => {
    setHovering(true)
  }

  const handleMouseLeave = () => {
    setHovering(false)
  }

  const removeMediaUrl = () => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: {...question, mediaUrl: null}})
    setQuestionnaireContentList(newContentList)
  }

  const sanitizedMediaUrl = () => {
    if (!question.mediaUrl) return ''
    if (question.mediaUrl.includes('youtube')) {
      return `https://img.youtube.com/vi/${extractVideoId(question.mediaUrl)}/hqdefault.jpg`
    }
    return question.mediaUrl
  }

  const extractVideoId = (mediaUrl?: string | null) => {
    if (!mediaUrl) return ''
    const videoId = mediaUrl.split('v=')[1]
    const ampersandPosition = videoId.indexOf('&')
    if (ampersandPosition !== -1) {
      return videoId.substring(0, ampersandPosition)
    } else {
      return videoId
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (typeof result.destination?.index !== 'number') return
    const newContentList = [...questionnaireContentList]
    if (!question.inputConfig?.options) return
    const newOptions = [...question.inputConfig.options]
    const [draggedItem] = newOptions.splice(result.source.index, 1)
    newOptions.splice(result.destination.index, 0, draggedItem)
    newContentList.splice(index, 1, {
      question: {...question, inputConfig: {...question.inputConfig, options: newOptions}},
    })
    setQuestionnaireContentList(newContentList)
    return
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          css={{display: 'flex', flexDirection: 'column', gap: '8px'}}
        >
          <div
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            css={{
              width: '100%',
              borderRadius: '5px',
              border: isEditing ? `1px solid ${color.border.blue}` : 'none',
              background: color.white,
              position: 'relative',
              // cursor: viewOnly ? 'auto' : 'pointer',
              boxShadow: '0px 4px 12px rgba(213, 213, 213, 0.25)',
              ':hover': {
                background: viewOnly ? color.white : color.grey_20,
              },
            }}
          >
            {/* drag handle */}
            {RIF(
              !viewOnly,
              <DragHandle
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                isShowing={isEditing || hovering || snapshot.isDragging}
                css={{
                  position: 'absolute',
                  top: '0',
                  left: '-24px',
                }}
              />,
            )}
            {/* type selection, switch for required state and dropdown menu */}
            {RIF(
              isEditing,
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '12px',
                  backgroundColor: color.surface.default,
                  borderRadius: '5px 5px 0 0',
                }}
              >
                <Select
                  components={{Option: SelectOption}}
                  options={questionTypeOptions}
                  defaultValue={{
                    value: question?.type,
                    label:
                      questionTypeOptions.find((option) => option.value === question?.type)?.label ??
                      questionTypeOptions[0].label,
                  }}
                  style={{
                    option: (base: CSSProperties) => ({
                      ...base,
                      fontSize: fontSize.h7,
                      color: color.textIcon.title,
                    }),
                  }}
                  onChange={handleChangeQuestionType}
                  css={{
                    height: '40px',
                    width: '210px',
                  }}
                />
                <div
                  css={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  {/* switch for required state */}
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <SlideCheckbox value={question?.required || false} onChange={handleChangeRequiredStatus} />
                    <span css={{fontWeight: fontWeight.bold, color: color.textIcon.secondary}}>Required</span>
                  </div>
                  {/* dropdown menu */}
                  <QuestionnaireDropdown
                    {...{
                      index,
                      deleteItem,
                      duplicateItem,
                    }}
                  />
                </div>
              </div>,
            )}
            {/* question content */}
            <div
              css={{
                width: '100%',
                padding: '16px 24px 24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {/* question index & description when not editing */}
              {RIF(
                !isEditing,
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <span
                    css={{
                      width: '20px',
                      maxWidth: '20px',
                      minWidth: '20px',
                    }}
                  >
                    {questionIndex}.
                  </span>
                  <span>{question?.description}</span>
                  {RIF(question?.required, <RequiredTag />)}
                </div>,
              )}
              {/* question index & description input & media dropdown when editing */}
              {RIF(
                isEditing,
                <div css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <span css={{width: '20px', minWidth: '20px'}}>{`${questionIndex}.`}</span>
                  <QuestionnaireInput
                    defaultValue={question?.description}
                    onChangeAction={handleChangeQuestionDescription}
                    placeholder={'New Question'}
                    initialValue={'New Question'}
                    maxLength={250}
                    // size={QuestionnaireInputSize.extraSmall}
                  />
                  <QuestionnaireMediaDropdown
                    {...{
                      question,
                      questionnaireContentList,
                      setQuestionnaireContentList,
                      // addPendingMedia,
                      index,
                    }}
                  />
                </div>,
              )}
              {/* media */}
              {RIF(
                question.mediaUrl,
                <div
                  css={{
                    width: '120px',
                    height: '108px',
                    position: 'relative',
                    marginLeft: '28px',
                    boxShadow: '0px 4px 10px 1px #68686840',
                    borderRadius: '5px',
                    padding: '4px',
                    backgroundColor: color.surface.white,
                  }}
                >
                  {RIF(
                    isEditing,
                    <button
                      onClick={removeMediaUrl}
                      css={{
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: color.black,
                        backgroundImage: `url(${NewCloseIcon})`,
                        backgroundSize: '12px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'absolute',
                        top: '-4px',
                        right: '-4px',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    />,
                  )}
                  <img width={112} height={100} src={sanitizedMediaUrl()} />
                </div>,
              )}
              {/* content */}
              <div
                css={{
                  marginLeft: '28px',
                }}
              >
                {/* question type: text */}
                {RIF(
                  question?.type === QuestionnaireQuestionType.Text,
                  <p
                    css={{
                      padding: '11px 16px',
                      color: color.textIcon.hint,
                      borderBottom: `1px dashed ${color.border.default}`,
                    }}
                  >
                    Participant's answer goes here
                  </p>,
                )}
                {/* question type: number */}
                {RIF(
                  question?.type === QuestionnaireQuestionType.Number,
                  <>
                    <div
                      css={{
                        height: '40px',
                        minHeight: '40px',
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        color: color.textIcon.hint,
                        borderBottom: `1px dashed ${color.border.default}`,
                      }}
                    >
                      Participant's answer goes here
                    </div>
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '64px',
                        marginTop: '24px',
                      }}
                    >
                      {/* left part */}
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <Checkbox value={question?.inputConfig?.integer ?? false} onChange={handleChangeInteger} />
                        <p css={{color: color.textIcon.secondary, whiteSpace: 'nowrap'}}>
                          The answer should be an integer
                        </p>
                        <Tooltip content='The answer should be a whole number (no decimals, can be negative)' />
                      </div>
                      {/* right part */}
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        <div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <Checkbox
                            value={question?.inputConfig?.max !== undefined}
                            onChange={handleChangeEnableRange}
                          />
                          <p css={{color: color.textIcon.secondary, whiteSpace: 'nowrap'}}>Range between</p>
                        </div>
                        <QuestionnaireInput
                          size={QuestionnaireInputSize.extraSmall}
                          defaultValue={(question?.inputConfig?.min ?? 0) + ''}
                          onChangeAction={handleChangeNumberMin}
                          placeholder={'0'}
                          initialValue={'0'}
                          disabled={question?.inputConfig?.min === undefined}
                          maxLength={250}
                          inputType={question?.inputConfig?.integer ? 'integer' : 'number'}
                          hideWordCount={true}
                        />
                        <p css={{color: color.textIcon.secondary, fontWeight: fontWeight.bold}}>to</p>
                        <QuestionnaireInput
                          size={QuestionnaireInputSize.extraSmall}
                          defaultValue={(question?.inputConfig?.max ?? 100) + ''}
                          onChangeAction={handleChangeNumberMax}
                          placeholder={'100'}
                          initialValue={'100'}
                          disabled={question?.inputConfig?.max === undefined}
                          inputType={question?.inputConfig?.integer ? 'integer' : 'number'}
                          maxLength={250}
                          hideWordCount={true}
                        />
                      </div>
                    </div>
                  </>,
                )}
                {/* question type: time */}
                {RIF(
                  question?.type === QuestionnaireQuestionType.Time,
                  <div css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <img src={TimeIcon} width={20} />
                    <p
                      css={{
                        color: color.textIcon.secondary,
                      }}
                    >
                      Hour / Minutes
                    </p>
                  </div>,
                )}
                {/* question type: date */}
                {RIF(
                  question?.type === QuestionnaireQuestionType.Date,
                  <div css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <img src={CalendarGreyIcon} width={20} />
                    <p
                      css={{
                        color: color.textIcon.secondary,
                      }}
                    >
                      Month / Day / Year
                    </p>
                  </div>,
                )}
                {/* question type: date and time */}
                {RIF(
                  question?.type === QuestionnaireQuestionType.DateTime,
                  <div
                    css={{
                      display: 'flex',
                      gap: '40px',
                    }}
                  >
                    <div css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                      <img src={CalendarGreyIcon} width={20} />
                      <p
                        css={{
                          color: color.textIcon.secondary,
                        }}
                      >
                        Month / Day / Year
                      </p>
                    </div>
                    <div css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                      <img src={TimeIcon} width={20} />
                      <p
                        css={{
                          color: color.textIcon.secondary,
                        }}
                      >
                        Hour / Minutes
                      </p>
                    </div>
                  </div>,
                )}
                {/* question type: multi select/choice */}
                {RIF(
                  [QuestionnaireQuestionType.MultiSelect, QuestionnaireQuestionType.Radio].includes(
                    question?.type || '',
                  ),
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: isEditing ? '8px' : '16px',
                      paddingLeft: isEditing ? 0 : '28px',
                    }}
                  >
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId={`optionList-${id}`} type={`optionList-${id}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            css={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: isEditing ? '8px' : '16px',
                            }}
                          >
                            {question?.inputConfig?.options?.map((option, i, a) => (
                              <QuestionnaireOption
                                {...{
                                  key: i,
                                  id: t.uuid(),
                                  index: i,
                                  isEditing,
                                  question,
                                  option,
                                  editOptionName,
                                  deleteOption,
                                  showDeleteButton: a.length > 1,
                                  isOtherOption: false,
                                  viewOnly,
                                }}
                              />
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {RIF(
                      question?.inputConfig?.allowOther,
                      <QuestionnaireOption
                        {...{
                          key: t.uuid(),
                          id: t.uuid(),
                          index: question?.inputConfig?.options?.length ?? 0,
                          isEditing,
                          question,
                          option: {name: 'Other'},
                          editOptionName,
                          deleteOption,
                          showDeleteButton: false,
                          isOtherOption: true,
                          viewOnly,
                        }}
                      />,
                    )}
                  </div>,
                )}
              </div>
              {/* add option button & switch for other option */}
              {RIF(
                isEditing && ['radio', 'multi_select'].includes(question?.type),
                <div
                  css={{
                    paddingLeft: '24px',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '24px',
                  }}
                >
                  <ButtonReverse onClick={addOption} btnPadding='mediumSmall' children='+ Add New Option' />
                  {RIF(isInBetaType({betaType: BetaType.ClosedBeta, settings}), () => (
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      <SlideCheckbox
                        value={question?.inputConfig?.allowOther ?? false}
                        onChange={handleChangeOfAllowOtherStatus}
                      />
                      <span css={{color: color.textIcon.secondary}}>"Other" Option</span>
                    </div>
                  ))}
                </div>,
              )}
            </div>
          </div>
          {RIF(
            isEditing,
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
              }}
            >
              <button
                onClick={handleClickAddNewQuestion}
                css={{
                  fontWeight: fontWeight.thick,
                  color: color.textIcon.link,
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                }}
              >
                + New Question
              </button>
              <button
                onClick={handleClickAddNewSection}
                css={{
                  fontWeight: fontWeight.thick,
                  color: color.textIcon.link,
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                }}
              >
                + New Section
              </button>
            </div>,
          )}
        </div>
      )}
    </Draggable>
  )
}
