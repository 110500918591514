import {snakeToCamel} from '../lib'
import {
  IProject,
  IQuestionnaire,
  IQuestionnaireQuestion,
  IQuestionnaireSection,
  ITask,
  ITaskSet,
  ITimer,
  ITodo,
  IWorkspace,
  MovesenseECGSampleRate,
  MovesenseSampleRate,
  QuestionnaireQuestionType,
  StopwatchGarminStreamAccSampleRate,
  TaskType,
} from '../shared/db'
import {IGarminDevice, IStopwatchGarminStream, IStopwatchMovesenseStream, ITaskSchedule} from '../shared/db'

export enum ThirdPartyTaskType {
  Dexcom = 'dexcom',
  GarminConnect = 'garmin_connect',
}

export type TaskStateType = ITask

export interface TempTask
  extends Omit<
    ITask,
    | 'createdAt'
    | 'updatedAt'
    | 'todo'
    | 'timer'
    | 'stopwatchGarminStream'
    | 'taskSet'
    | 'questionnaire'
    | 'garminDevice'
    | 'taskSetParentId'
    | 'taskSetParent'
    | 'stopwatchMovesenseStream'
  > {
  actionType: 'create' | 'update' | 'hide' | 'show'
  todo?: TempTodo
  timer?: TempTimer
  stopwatchGarminStream?: TempStopwatchGarminStream
  taskSet?: TempTaskSet
  questionnaire?: TempQuestionnaire
  garminDevice?: TempGarminDevice
  stopwatchMovesenseStream?: TempStopwatchMovesenseStream
}

export type TempTodo = Omit<ITodo, 'id' | 'createdAt' | 'updatedAt'>
export type TempTimer = Omit<ITimer, 'id' | 'createdAt' | 'updatedAt'>
export interface TempStopwatchGarminStream {
  name?: string
  description?: string
  accEnable?: boolean
  accHz?: StopwatchGarminStreamAccSampleRate
  timeLimitSeconds?: number
}
export type TempTaskSet = Omit<ITaskSet, 'id' | 'createdAt' | 'updatedAt'>
export type TempGarminDevice = Omit<IGarminDevice, 'id' | 'createdAt' | 'updatedAt'>
export interface TempStopwatchMovesenseStream {
  taskId?: string
  projectId?: string
  workspaceId?: string
  project?: IProject
  workspace?: IWorkspace
  name?: string
  description?: string
  task?: ITask
  rrEnable?: boolean
  imuEnable?: boolean
  accEnable?: boolean
  ecgEnable?: boolean
  gyroscopeEnable?: boolean
  magnetometerEnable?: boolean
  ecgHz?: MovesenseECGSampleRate
  imuHz?: MovesenseSampleRate
  accHz?: MovesenseSampleRate
  gyroscopeHz?: MovesenseSampleRate
  magnetometerHz?: MovesenseSampleRate
  timeLimitSeconds?: number
}

export enum ContentListItemCategory {
  Section = 'section',
  Question = 'question',
}
export interface ContentListItem {
  section?: QuestionnaireTempSection
  question?: QuestionnaireTempQuestion
  // category: T
  // item: ItemType
}

export interface TempQuestionnaire
  extends Pick<IQuestionnaire, 'taskId' | 'projectId' | 'workspaceId' | 'name' | 'description'> {
  sectionList: QuestionnaireTempSection[]
  questionList?: QuestionnaireTempQuestion[]
}

export interface QuestionnaireTempSection
  extends Pick<IQuestionnaireSection, 'id' | 'description' | 'legend' | 'mediaUrl'> {
  index?: number
  questionnaireId?: string
  questionList?: QuestionnaireTempQuestion[]
}
export interface QuestionnaireTempQuestion<
  T extends QuestionnaireQuestionType = QuestionnaireQuestionType,
  TempInput = T extends
    | QuestionnaireQuestionType.Select
    | QuestionnaireQuestionType.MultiSelect
    | QuestionnaireQuestionType.Radio
    ? QuestionnaireTempInputConfigOptions
    : undefined,
> extends Pick<IQuestionnaireQuestion, 'id' | 'description' | 'required' | 'label' | 'type' | 'mediaUrl'> {
  index?: number
  questionnaireId?: string
  questionnaireSectionId?: string
  inputConfig?: TempInput
}
export interface QuestionnaireTempInputConfigOptions {
  allowOther?: boolean
  integer?: boolean
  max?: number
  min?: number
  options?: {
    name: string
  }[]
}

export type TaskContentName = 'todo' | 'timer' | 'questionnaire' | 'stopwatchMovesenseStream' | 'stopwatchGarminStream'

export const getTaskContentName = (taskType: TaskType | ThirdPartyTaskType): TaskContentName => {
  if (taskType == TaskType.StopwatchMovesenseStream || taskType == TaskType.StopwatchMovesenseLogData) {
    return 'stopwatchMovesenseStream'
  }
  return snakeToCamel(taskType) as TaskContentName
}

export type EditingTask = ITask | TempTask

export type GarminDeviceSpecCopy = Omit<
  IGarminDevice,
  | 'accelerometerEnable'
  | 'batteryPercentageSupport'
  | 'id'
  | 'workspaceId'
  | 'projectId'
  | 'taskId'
  | 'createdAt'
  | 'updatedAt'
>

export interface tempStopwatchMovesenseStreamSpec {
  task?: ITask
  project?: IProject
  workspace?: IWorkspace
  rrEnable?: boolean
  imuEnable?: boolean
  accEnable?: boolean
  ecgEnable?: boolean
  gyroscopeEnable?: boolean
  magnetometerEnable?: boolean
  ecgHz?: MovesenseECGSampleRate
  imuHz?: MovesenseSampleRate
  accHz?: MovesenseSampleRate
  gyroscopeHz?: MovesenseSampleRate
  magnetometerHz?: MovesenseSampleRate
  timeLimitSeconds?: number
}

export interface tempStopwatchGarminStreamSpec {
  accEnable?: boolean
  accHz?: StopwatchGarminStreamAccSampleRate
  timeLimitSeconds?: number
}

export type TaskTypeForDevicePage =
  | 'garmin_device'
  | 'smartphone'
  | 'dexcomIntegrationForm'
  | 'dexcomSelectorPopup'
  | 'polar'
  | ''
export type TaskTypeForInstructionPage = TaskType.StopwatchMovesenseStream | TaskType.StopwatchGarminStream | ''
