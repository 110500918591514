import {useState, MutableRefObject, useEffect, SetStateAction, Dispatch} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {RIF, t, _} from '../../lib'
import {selectTheme, createDispatchActions} from '../../store'
import {
  DragHandle,
  ButtonText,
  QuestionnaireInput,
  QuestionnaireTextarea,
  QuestionnaireInputHeight,
  QuestionnaireDropdown,
} from '..'

import {ContentListItem, ContentListItemCategory, QuestionnaireTempSection} from '../../model'

export const QuestionnaireSection = (props: {
  key: string
  id: string
  index: number
  section: QuestionnaireTempSection
  isEditing: boolean
  setEditingBlock: Dispatch<SetStateAction<string | null>>
  addSection: (index: number) => void
  addQuestion: (index: number) => void
  duplicateItem: (index: number) => void
  deleteItem: (index: number) => void
  viewOnly: boolean
  questionnaireContentList: ContentListItem[]
  setQuestionnaireContentList: Dispatch<SetStateAction<ContentListItem[]>>
}) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()
  const {
    id,
    index,
    section,
    isEditing,
    setEditingBlock,
    addSection,
    addQuestion,
    duplicateItem,
    deleteItem,
    viewOnly,
    questionnaireContentList,
    setQuestionnaireContentList,
  } = props
  const [hovering, setHovering] = useState(false)
  const sectionIndex = questionnaireContentList.filter((c) => c.section).findIndex((s) => s.section?.id === id) + 1
  const handleChangeSectionLegend = (value: string) => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {section: {...section, legend: value}})
    setQuestionnaireContentList(newContentList)
  }

  const handleChangeSectionDescription = (value: string) => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {section: {...section, description: value}})
    setQuestionnaireContentList(newContentList)
  }

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setEditingBlock(id)
  }

  const handleClickAddNewQuestion = (e: React.MouseEvent) => {
    e.stopPropagation()
    addQuestion(index)
  }

  const handleClickAddNewSection = (e: React.MouseEvent) => {
    e.stopPropagation()
    addSection(index)
  }

  const handleMouseEnter = () => {
    setHovering(true)
  }

  const handleMouseLeave = () => {
    setHovering(false)
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={provided.innerRef}
            {...provided.draggableProps}
            css={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
            }}
          >
            {/* drag handle */}
            {RIF(
              !viewOnly,
              <DragHandle
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                isShowing={isEditing || hovering || snapshot.isDragging}
                css={{
                  position: 'absolute',
                  top: '0',
                  left: '-24px',
                }}
              />,
            )}
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {/* section index tag */}
              <span
                css={{
                  whiteSpace: 'nowrap',
                  width: '85px',
                  minWidth: '85px',
                  maxWidth: '85px',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: color.border._600,
                  borderRadius: '8px 8px 0 0',
                  color: color.textIcon.white,
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.thick,
                }}
              >
                Section {sectionIndex}
              </span>
              <div
                onClick={handleClick}
                css={{
                  width: '100%',
                  borderRadius: `0 ${isEditing ? '5px' : '0'} 5px 5px`,
                  border: isEditing ? `1px solid ${color.border.blue}` : 'none',
                  borderTop: isEditing ? `1px solid ${color.border.blue}` : `1px solid ${color.border._600}`,
                  padding: `16px ${isEditing ? '16px' : '24px'}`,
                  background: color.white,
                  boxShadow: '0px 4px 12px rgba(213, 213, 213, 0.25)',
                  cursor: viewOnly ? 'auto' : 'pointer',
                  ':hover': {
                    background: viewOnly ? color.white : color.grey_20,
                  },
                }}
              >
                {RIF(
                  !isEditing,
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <p css={{fontWeight: fontWeight.bold, fontSize: fontSize.h3}}>{section?.legend}</p>
                    <p>{section?.description}</p>
                  </div>,
                )}
                {RIF(
                  isEditing,
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <QuestionnaireInput
                        placeholder='New Section Title'
                        initialValue='New Section Title'
                        onChangeAction={handleChangeSectionLegend}
                        defaultValue={section.legend}
                        maxLength={50}
                        questionnaireInputHeight={QuestionnaireInputHeight.big}
                      />
                      <QuestionnaireDropdown
                        {...{
                          index,
                          deleteItem,
                          duplicateItem,
                        }}
                      />
                    </div>
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <QuestionnaireTextarea
                        defaultValue={section?.description}
                        onChangeAction={handleChangeSectionDescription}
                        placeholder='Description goes here'
                        initialValue='Description goes here'
                        maxLength={250}
                        rows={4}
                      />
                      <div css={{width: '32px', minWidth: '32px'}} />
                    </div>
                  </div>,
                )}
              </div>
            </div>
            {RIF(
              isEditing,
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '30px',
                }}
              >
                <button
                  onClick={handleClickAddNewQuestion}
                  css={{
                    fontWeight: fontWeight.thick,
                    color: color.textIcon.link,
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '0',
                  }}
                >
                  + New Question
                </button>
                <button
                  onClick={handleClickAddNewSection}
                  css={{
                    fontWeight: fontWeight.thick,
                    color: color.textIcon.link,
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '0',
                  }}
                >
                  + New Section
                </button>
              </div>,
            )}
          </div>
        )
      }}
    </Draggable>
  )
}
