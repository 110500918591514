import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'

import {ITask, ITaskSchedule} from '../../../shared/db'

export enum MethodActionType {
  METHOD_SET = 'METHOD_SET',
  METHOD_DELETE = 'METHOD_DELETE',
  METHOD_ADD_TASK = 'METHOD_ADD_TASK',
  METHOD_UPDATE_TASK = 'METHOD_UPDATE_TASK',
  METHOD_UPDATE_TASK_SCHEDULE_LIST = 'METHOD_UPDATE_TASK_SCHEDULE_LIST',
  METHOD_ADD_TASK_SCHEDULE = 'METHOD_CREATE_TASK_SCHEDULE',
  METHOD_UPDATE_TASK_SCHEDULE = 'METHOD_UPDATE_TASK_SCHEDULE',
  METHOD_DELETE_TASK_SCHEDULE = 'METHOD_DELETE_TASK_SCHEDULE',
  METHOD_UPDATE_TASK_ENABLE_STATUS = 'METHOD_UPDATE_TASK_ENABLE_STATUS',
  METHOD_DELETE_TASK = 'METHOD_DELETE_TASK',
  METHOD_TASK_LIST_UPDATE = 'METHOD_TASK_LIST_UPDATE',
  METHOD_GARMIN_CONNECT_ENABLE_UPDATE = 'METHOD_GARMIN_CONNECT_ENABLE_UPDATE',
  METHOD_GARMIN_STREAM_ENABLE_UPDATE = 'METHOD_GARMIN_STREAM_ENABLE_UPDATE',
  METHOD_MOVESENSE_DEVICE_ENABLE_UPDATE = 'METHOD_MOVESENSE_DEVICE_ENABLE_UPDATE',
  METHOD_DEXCOM_INTEGRATION_ID_SET = 'METHOD_DEXCOM_INTEGRATION_ID_SET',
}

export const doMETHOD_SET = createAction(MethodActionType.METHOD_SET)
export const doMETHOD_DELETE = createAction(MethodActionType.METHOD_DELETE)
export const doMETHOD_ADD_TASK = createAction(MethodActionType.METHOD_ADD_TASK)
export const doMETHOD_UPDATE_TASK = createAction(MethodActionType.METHOD_UPDATE_TASK)
export const doMETHOD_UPDATE_TASK_SCHEDULE_LIST = createAction(MethodActionType.METHOD_UPDATE_TASK_SCHEDULE_LIST)
export const doMETHOD_ADD_TASK_SCHEDULE = createAction(MethodActionType.METHOD_ADD_TASK_SCHEDULE)
export const doMETHOD_UPDATE_TASK_SCHEDULE = createAction(MethodActionType.METHOD_UPDATE_TASK_SCHEDULE)
export const doMETHOD_DELETE_TASK_SCHEDULE = createAction(MethodActionType.METHOD_DELETE_TASK_SCHEDULE)
export const doMETHOD_UPDATE_TASK_ENABLE_STATUS = createAction(MethodActionType.METHOD_UPDATE_TASK_ENABLE_STATUS)
export const doMETHOD_DELETE_TASK = createAction(MethodActionType.METHOD_DELETE_TASK)
export const doMETHOD_TASK_LIST_UPDATE = createAction(MethodActionType.METHOD_TASK_LIST_UPDATE)
export const doMETHOD_GARMIN_CONNECT_ENABLE_UPDATE = createAction(MethodActionType.METHOD_GARMIN_CONNECT_ENABLE_UPDATE)
export const doMETHOD_GARMIN_STREAM_ENABLE_UPDATE = createAction(MethodActionType.METHOD_GARMIN_STREAM_ENABLE_UPDATE)
export const doMETHOD_MOVESENSE_DEVICE_ENABLE_UPDATE = createAction(
  MethodActionType.METHOD_MOVESENSE_DEVICE_ENABLE_UPDATE,
)
export const doMETHOD_DEXCOM_INTEGRATION_ID_SET = createAction(MethodActionType.METHOD_DEXCOM_INTEGRATION_ID_SET)
interface MethodState {
  id: string | null
  identityId: string | null
  workspaceId: string | null
  projectId: string | null
  garminConnectEnable: boolean
  garminDeviceEnable: boolean
  garminStreamEnable: boolean
  movesenseDeviceEnable: boolean
  dexcomIntegrationId?: string | null
  updatedAt: string | null
  createdAt: string | null
  description: string | null
  name: string | null
  taskList: ITask[]
}

interface RootState {
  method: MethodState
}

/* selector */
export const selectMethod = () => {
  return useSelector((state: RootState) => state.method)
}

export const methodActionCreators = {
  doMETHOD_SET,
  doMETHOD_DELETE,
  doMETHOD_ADD_TASK,
  doMETHOD_UPDATE_TASK,
  doMETHOD_UPDATE_TASK_ENABLE_STATUS,
  doMETHOD_DELETE_TASK,
  doMETHOD_TASK_LIST_UPDATE,
  doMETHOD_GARMIN_CONNECT_ENABLE_UPDATE,
  doMETHOD_GARMIN_STREAM_ENABLE_UPDATE,
  doMETHOD_MOVESENSE_DEVICE_ENABLE_UPDATE,
  doMETHOD_DEXCOM_INTEGRATION_ID_SET,
  doMETHOD_UPDATE_TASK_SCHEDULE_LIST,
  doMETHOD_ADD_TASK_SCHEDULE,
  doMETHOD_UPDATE_TASK_SCHEDULE,
  doMETHOD_DELETE_TASK_SCHEDULE,
}

export const methodDefaultState: MethodState = {
  id: null,
  updatedAt: null,
  createdAt: null,
  identityId: null,
  workspaceId: null,
  projectId: null,
  description: null,
  garminConnectEnable: false,
  garminDeviceEnable: false,
  garminStreamEnable: false,
  movesenseDeviceEnable: false,
  dexcomIntegrationId: undefined,
  name: null,
  taskList: [],
}

type Action =
  | {
      type: MethodActionType.METHOD_SET
      payload: {
        id: string
        identityId: string
        workspaceId: string
        projectId: string
        garminConnectEnable: boolean
        garminDeviceEnable: boolean
        garminStreamEnable: boolean
        movesenseDeviceEnable: boolean
        dexcomIntegrationId?: string
        updatedAt: string
        createdAt: string
        description: string
        name: string
        taskList: ITask[]
      }
    }
  | {
      type: MethodActionType.METHOD_DELETE
      payload: undefined
    }
  | {
      type: MethodActionType.METHOD_ADD_TASK
      payload: ITask
    }
  | {
      type: MethodActionType.METHOD_UPDATE_TASK
      payload: ITask
    }
  | {
      type: MethodActionType.METHOD_UPDATE_TASK_SCHEDULE_LIST
      payload: {
        taskId: string
        scheduleList: ITaskSchedule[]
      }
    }
  | {
      type: MethodActionType.METHOD_ADD_TASK_SCHEDULE
      payload: ITaskSchedule
    }
  | {
      type: MethodActionType.METHOD_UPDATE_TASK_SCHEDULE
      payload: ITaskSchedule
    }
  | {
      type: MethodActionType.METHOD_DELETE_TASK_SCHEDULE
      payload: Pick<ITaskSchedule, 'id'>
    }
  | {
      type: MethodActionType.METHOD_UPDATE_TASK_ENABLE_STATUS
      payload: {
        taskId: string
        enabled: boolean
      }
    }
  | {
      type: MethodActionType.METHOD_DELETE_TASK
      payload: string
    }
  | {
      type: MethodActionType.METHOD_TASK_LIST_UPDATE
      payload: {
        taskList: ITask[]
      }
    }
  | {
      type: MethodActionType.METHOD_GARMIN_CONNECT_ENABLE_UPDATE
      payload: {
        garminConnectEnable: boolean
      }
    }
  | {
      type: MethodActionType.METHOD_MOVESENSE_DEVICE_ENABLE_UPDATE
      payload: {
        movesenseDeviceEnable: boolean
      }
    } // TODO: combine all enable update method?
  | {
      type: MethodActionType.METHOD_GARMIN_STREAM_ENABLE_UPDATE
      payload: {
        garminStreamEnable: boolean
      }
    }
  | {
      type: MethodActionType.METHOD_DEXCOM_INTEGRATION_ID_SET
      payload: {
        dexcomIntegrationId: string
      }
    }

export const methodReducer = (state = {...methodDefaultState}, {type, payload}: Action): MethodState => {
  let newTaskList
  switch (type) {
    case MethodActionType.METHOD_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          identityId: v.string().uuid().optional().allow('', null),
          workspaceId: v.string().uuid().exist(),
          projectId: v.string().uuid().exist(),
          garminConnectEnable: v.boolean().exist(),
          garminDeviceEnable: v.boolean().exist(),
          garminStreamEnable: v.boolean().optional(), // TODO: production
          movesenseDeviceEnable: v.boolean().exist(),
          dexcomIntegrationId: v.string().optional().allow('', null),
          taskList: v.array().optional(),
          updatedAt: v.string().optional(),
          createdAt: v.string().optional(),
          description: v.string().optional().allow('', null),
          name: v.string().optional(),
        }),
      })
      return {...state, ...payload, dexcomIntegrationId: payload.dexcomIntegrationId ?? undefined}

    case MethodActionType.METHOD_DELETE:
      return methodDefaultState

    case MethodActionType.METHOD_ADD_TASK:
      assertPartialSchema({
        payload,
        schema: v.object(), // TODO: complete the schema include todo, timer, qn, garmin
      })
      newTaskList = [...state.taskList]
      newTaskList.push(payload)
      return {...state, taskList: newTaskList}

    case MethodActionType.METHOD_UPDATE_TASK: {
      assertPartialSchema({
        payload,
        schema: v.object(), // TODO: complete the schema include todo, timer, qn, garmin
      })
      newTaskList = [...state.taskList]
      const updateItemIndex = newTaskList.findIndex((item) => item.id === payload.id)
      newTaskList[updateItemIndex] = {...newTaskList[updateItemIndex], ...payload}
      return {...state, taskList: newTaskList}
    }
    case MethodActionType.METHOD_UPDATE_TASK_SCHEDULE_LIST: {
      newTaskList = [...state.taskList]
      const updateItemIndex = newTaskList.findIndex((item) => item.id === payload.taskId)
      newTaskList[updateItemIndex] = {
        ...newTaskList[updateItemIndex],
        scheduleList: payload.scheduleList,
      }
      return {...state, taskList: newTaskList}
    }
    case MethodActionType.METHOD_UPDATE_TASK_ENABLE_STATUS: {
      assertPartialSchema({
        payload,
        schema: v.object({
          taskId: v.string().uuid().exist(),
          enabled: v.boolean().exist(),
        }),
      })
      newTaskList = [...state.taskList]
      const updateItemIndex = newTaskList.findIndex((item) => item.id === payload.taskId)
      newTaskList[updateItemIndex] = {...newTaskList[updateItemIndex], enabled: payload.enabled}
      return {...state, taskList: newTaskList}
    }

    case MethodActionType.METHOD_DELETE_TASK: {
      assertPartialSchema({
        payload,
        schema: v.string().uuid(),
      })
      newTaskList = [...state.taskList]
      const deleteItemIndex = newTaskList.findIndex((item) => item.id === payload)
      newTaskList.splice(deleteItemIndex, 1)
      return {...state, taskList: newTaskList}
    }

    case MethodActionType.METHOD_TASK_LIST_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          taskList: v.array().items(
            v.object(), // TODO: complete schema
          ),
        }),
      })

      return {...state, taskList: payload.taskList}

    case MethodActionType.METHOD_GARMIN_CONNECT_ENABLE_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          garminConnectEnable: v.boolean().exist(),
        }),
      })

      return {...state, garminConnectEnable: payload.garminConnectEnable}

    case MethodActionType.METHOD_MOVESENSE_DEVICE_ENABLE_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          movesenseDeviceEnable: v.boolean().exist(),
        }),
      })

      return {...state, movesenseDeviceEnable: payload.movesenseDeviceEnable}

    case MethodActionType.METHOD_GARMIN_STREAM_ENABLE_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          garminStreamEnable: v.boolean().exist(),
        }),
      })

      return {...state, garminStreamEnable: payload.garminStreamEnable}

    case MethodActionType.METHOD_DEXCOM_INTEGRATION_ID_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          dexcomIntegrationId: v.string().exist().allow('', null),
        }),
      })

      return {...state, dexcomIntegrationId: payload.dexcomIntegrationId}

    case MethodActionType.METHOD_ADD_TASK_SCHEDULE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().exist(),
          taskId: v.string().exist(),
        }),
      })

      newTaskList = [...state.taskList]
      const newTask = newTaskList.find(({id}) => id === payload.taskId)
      if (newTask) {
        const newScheduleList = [...(newTask.scheduleList ?? []), payload]
        newTask.scheduleList = newScheduleList
      }

      return {...state, taskList: newTaskList}
    }

    case MethodActionType.METHOD_UPDATE_TASK_SCHEDULE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().exist(),
          taskId: v.string().exist(),
        }),
      })

      newTaskList = [...state.taskList]
      const newTask = newTaskList.find(({id}) => id === payload.taskId)
      if (newTask) {
        const newScheduleList = [...(newTask.scheduleList ?? [])]
        const scheduleIndex = newScheduleList.findIndex(({id}) => id === payload.id)
        if (scheduleIndex > -1) {
          newScheduleList[scheduleIndex] = payload
          newTask.scheduleList = newScheduleList
        }
      }

      return {...state, taskList: newTaskList}
    }

    case MethodActionType.METHOD_DELETE_TASK_SCHEDULE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().exist(),
        }),
      })

      newTaskList = [...state.taskList]

      for (const task of newTaskList) {
        const newScheduleList = [...(task.scheduleList ?? [])]
        const index = newScheduleList.findIndex(({id}) => id === payload.id)
        if (index > -1) {
          newScheduleList.splice(index, 1)
          task.scheduleList = newScheduleList
          break
        }
      }

      return {...state, taskList: newTaskList}
    }
    default:
      return {...state}
  }
}
