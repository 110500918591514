import {Dispatch, SetStateAction} from 'react'
import {omitBy, isNil} from 'lodash'

import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {IEntityModel, ITaskSchedule} from '../../shared/db'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doMETHOD_ADD_TASK_SCHEDULE} from '../state'
import {getToken} from './token_fetcher'
import {Subtract} from 'utility-types'
import {getTaskScheduleSchemaMap} from '../../model/schedule'

export const REQUEST_TASK_SCHEDULE_CREATE = 'REQUEST_TASK_SCHEDULE_CREATE'
export const doREQUEST_TASK_SCHEDULE_CREATE = createDoRequestAction(REQUEST_TASK_SCHEDULE_CREATE)

export const requestTaskScheduleCreateActionCreators = {
  doREQUEST_TASK_SCHEDULE_CREATE,
}

interface Payload {
  payload: {
    requestId: string
    schedule: Subtract<Omit<ITaskSchedule, 'inactive'>, IEntityModel>
  }
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestTaskScheduleCreate({payload, setRequestResult}: Payload): SagaIterator {
  debug('saga*requestTaskScheduleCreate')

  payload.schedule = omitBy(payload.schedule, isNil) as Payload['payload']['schedule']

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        schedule: v.object(getTaskScheduleSchemaMap({required: ['taskId', 'type']})),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_TASK_SCHEDULE_CREATE,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const accessToken = yield call(getToken)

  const {schedule} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-schedule-create`,
    accessToken,
    data: schedule,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_TASK_SCHEDULE_CREATE,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/task-schedule-create',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(doMETHOD_ADD_TASK_SCHEDULE(result.payload))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_TASK_SCHEDULE_CREATE,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* taskScheduleCreateSaga() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield takeEvery(REQUEST_TASK_SCHEDULE_CREATE as any, requestTaskScheduleCreate)
}
