import {MouseEventHandler} from 'react'
import {selectTheme} from '../../store'
import {MoreIcon} from '../../asset/image'

export enum ButtonMoreSize {
  Regular = '24px',
  Big = '32px',
}
export interface ButtonMorePropsT {
  onClick: MouseEventHandler
  id?: string
  type?: 'button' | 'submit' | 'reset'
  size?: ButtonMoreSize
}

export const ButtonMore = (props: ButtonMorePropsT) => {
  const {size} = props
  const {color} = selectTheme()

  return (
    <button
      {...props}
      type={props.type || 'button'}
      css={{
        width: size ?? '24px',
        height: size ?? '24px',
        borderRadius: '5px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        backgroundImage: `url(${MoreIcon})`,
        backgroundPosition: 'center',
        backgroundSize: '75%',
        backgroundRepeat: 'no-repeat',
        ':hover': {
          backgroundColor: color.hover,
        },
      }}
    />
  )
}
