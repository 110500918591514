import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {useNavigate} from 'react-router-dom'
import {_, RequestResult, useCurrentProjectState} from '../../lib'
import {createDispatchActions, selectMethod, selectTempTaskData} from '../../store'
import {PopupSimple} from '..'
import {TempTask, getTaskContentName} from '../../model'
import {TaskType} from '../../shared/db'

export interface ProjectConfirmUpdatePopPropsT {
  closeAction: Dispatch<SetStateAction<boolean>>
}

// WARNING: do not delete garmin device task in live edit, instead task enabled = false and update spec
// or mobile side need to modify
export const ProjectConfirmUpdatePop = (props: ProjectConfirmUpdatePopPropsT) => {
  const {closeAction} = props

  const {
    doREQUEST_METHOD_UPDATE,
    doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE,
    doREQUEST_PROJECT_UPDATE,
    doREQUEST_TASK_UPDATE,
    doREQUEST_TASK_TODO_CREATE,
    doREQUEST_TASK_TODO_UPDATE,
    doREQUEST_TASK_TIMER_CREATE,
    doREQUEST_TASK_TIMER_UPDATE,
    doREQUEST_TASK_QUESTIONNAIRE_CREATE,
    doREQUEST_V2_TASK_QUESTIONNAIRE_UPDATE,
    doREQUEST_TASK_GARMIN_DEVICE_CREATE,
    doREQUEST_TASK_GARMIN_DEVICE_UPDATE,
    doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_CREATE,
    doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_UPDATE,
    doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE,
    doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_UPDATE,
  }: any = createDispatchActions()

  /* ------------------ reqId/completeId ------------------ */
  const [requestUpdateProjectResult, setRequestUpdateProjectResult] = useState<RequestResult | null>(null)
  const [requestMethodUpdateResult, setRequestMethodUpdateResult] = useState<RequestResult | null>(null)
  const [lastTaskRelatedRequestResult, setLastTaskRelatedRequestResult] = useState<RequestResult | null>(null)
  const [methodOrderUpdateRequestResult, setMethodOrderUpdateRequestResult] = useState<RequestResult | null>(null)

  /* ------------------ basic state ------------------ */
  const navigate = useNavigate()
  const methodState = selectMethod()
  const methodId = methodState.id
  const {projectId} = useCurrentProjectState()
  const tempTaskData = selectTempTaskData()

  const tempTaskList = tempTaskData?.taskList || []
  const tempGarminConnectEnable = tempTaskData.garminConnectEnable
  const tempGarminDeviceEnable = tempTaskData.garminDeviceEnable
  const tempGarminStreamEnable = tempTaskData.garminStreamEnable
  const tempMovesenseDeviceEnable = tempTaskData.movesenseDeviceEnable
  const tempDexcomIntegrationId = tempTaskData.dexcomIntegrationId

  const [finished, setFinished] = useState(false)
  const [finishedUpdateProject, setFinishedUpdateProject] = useState(false)
  const [finishedUpdateTasks, setFinishedUpdateTasks] = useState(false)
  const [garminRelatedRequestFinished, setGarminRelatedRequestFinished] = useState(false)
  const [startUpdateProject, setStartUpdateProject] = useState(false)

  useEffect(() => {
    if (!finishedUpdateTasks) return
    if (!garminRelatedRequestFinished) return
    if (!finishedUpdateProject) return
    setFinished(true)
  }, [finishedUpdateTasks, garminRelatedRequestFinished, finishedUpdateProject])

  // check if garmin related request are all completed
  useEffect(() => {
    if (!startUpdateProject) return
    if (!!requestMethodUpdateResult && !requestMethodUpdateResult.success) return
    setGarminRelatedRequestFinished(true)
  }, [startUpdateProject, requestMethodUpdateResult])

  // check if task related request are all completed
  useEffect(() => {
    if (lastTaskRelatedRequestResult?.success) {
      setFinishedUpdateTasks(true)
    }
  }, [lastTaskRelatedRequestResult])

  // check if project update request is completed
  useEffect(() => {
    if (!startUpdateProject) return
    if (!requestUpdateProjectResult?.success) return
    setFinishedUpdateProject(true)
  }, [startUpdateProject, requestUpdateProjectResult])

  useEffect(() => {
    if (!finished) return
    const taskIdList = tempTaskList
      .filter((item) => item.enabled)
      .map((task) => {
        return task.id
      })
    doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE({
      setRequestResult: setMethodOrderUpdateRequestResult,
      payload: {
        methodId,
        taskIdList,
      },
    })
  }, [finished])

  const updateProject = (projectContentFromLocalStorage: Record<string, any>) => {
    doREQUEST_PROJECT_UPDATE({
      setRequestResult: setRequestUpdateProjectResult,
      payload: {
        projectId: projectId,
        name: projectContentFromLocalStorage.name || undefined,
        investigator: projectContentFromLocalStorage.investigator || undefined,
        organization: projectContentFromLocalStorage.organization || undefined,
        description: projectContentFromLocalStorage.description || undefined,
        participantInstructions: projectContentFromLocalStorage.instructionValue || undefined,
        contactDescription: projectContentFromLocalStorage.contactValue || undefined,
      },
    })
  }

  const createTaskFromTempTaskList = (task: TempTask, isLast: boolean) => {
    const taskContentName = getTaskContentName(task.type)
    const input = {
      name: task[taskContentName]?.name,
      description: task[taskContentName]?.description,
      methodId: task.methodId,
      scheduleRepeat: task.scheduleRepeat,
      scheduleDescription: task.scheduleDescription,
      reminderList: task.reminderList,
      color: task.color,
      tempTaskId: task.id,
    }
    switch (task.type) {
      case 'todo':
        return doREQUEST_TASK_TODO_CREATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...input,
          },
        })
      case 'timer':
        return doREQUEST_TASK_TIMER_CREATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...input,
          },
        })
      case 'questionnaire':
        return doREQUEST_TASK_QUESTIONNAIRE_CREATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            sectionList: task.questionnaire?.sectionList,
            ...input,
          },
        })
      case 'garmin_device':
        return doREQUEST_TASK_GARMIN_DEVICE_CREATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            methodId: task.methodId,
            garminDevice: task.garminDevice,
          },
        })
      case 'stopwatch_garmin_stream': {
        task.stopwatchGarminStream = _.omit(task.stopwatchGarminStream, [
          'id',
          'taskId',
          'projectId',
          'workspaceId',
          'createdAt',
          'updatedAt',
        ])
        return doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_CREATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...task.stopwatchGarminStream,
            ...input,
          },
        })
      }
      // todo check_stopwatch_movesense_stream
      case 'stopwatch_movesense_stream': {
        task.stopwatchMovesenseStream = _.omit(task.stopwatchMovesenseStream, [
          'id',
          'taskId',
          'projectId',
          'workspaceId',
          'createdAt',
          'updatedAt',
        ])
        return doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_CREATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...task.stopwatchMovesenseStream,
            ...input,
          },
        })
      }
      default:
        break
    }
  }

  const updateTaskFromTempTaskList = (task: TempTask, isLast: boolean) => {
    const taskContentName = getTaskContentName(task.type)
    const input = {
      taskId: task.id || undefined,
      name: task[taskContentName]?.name,
      description: task[taskContentName]?.description,
      scheduleRepeat: task.scheduleRepeat,
      scheduleDescription: task.scheduleDescription,
      reminderList: task.reminderList,
      color: task.color,
    }
    switch (task.type) {
      case 'todo':
        return doREQUEST_TASK_TODO_UPDATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...input,
          },
        })
      case 'timer':
        return doREQUEST_TASK_TIMER_UPDATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...input,
          },
        })
      case 'questionnaire':
        delete input.taskId
        return doREQUEST_V2_TASK_QUESTIONNAIRE_UPDATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            taskId: task.id,
            scheduleRepeat: task.scheduleRepeat,
            scheduleDescription: task.scheduleDescription,
            reminderList: task.reminderList,
            name: task.questionnaire?.name,
            description: task.questionnaire?.description,
            color: task.color,
          },
        })
      case 'garmin_device': {
        doREQUEST_TASK_GARMIN_DEVICE_UPDATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            taskId: task.id,
            garminDevice: task.garminDevice,
          },
        })
        return doREQUEST_TASK_UPDATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            taskId: task.id,
            enabled: true,
          },
        })
      }
      case 'stopwatch_garmin_stream': {
        return doREQUEST_TASK_STOPWATCH_GARMIN_STREAM_UPDATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...input,
            timeLimitSeconds: task.stopwatchGarminStream?.timeLimitSeconds,
          },
        })
      }
      // todo check_stopwatch_movesense_stream
      case 'stopwatch_movesense_stream': {
        return doREQUEST_TASK_STOPWATCH_MOVESENSE_STREAM_UPDATE({
          setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
          payload: {
            ...input,
            timeLimitSeconds: task.stopwatchMovesenseStream?.timeLimitSeconds,
          },
        })
      }
      default:
        break
    }
  }

  const hideTaskFromTempTaskList = (task: TempTask, isLast: boolean) => {
    if (task.type === TaskType.GarminDevice) {
      doREQUEST_TASK_GARMIN_DEVICE_UPDATE({
        setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
        payload: {
          taskId: task.id,
          garminDevice: task.garminDevice,
        },
      })
    }
    return doREQUEST_TASK_UPDATE({
      setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
      payload: {
        taskId: task.id,
        enabled: false,
      },
    })
  }

  const showTaskFromTempTaskList = (task: TempTask, isLast: boolean) => {
    updateTaskFromTempTaskList(task, false)
    doREQUEST_TASK_UPDATE({
      setRequestResult: isLast ? setLastTaskRelatedRequestResult : null,
      payload: {
        taskId: task.id,
        enabled: true,
      },
    })
  }

  const handleEditConfirmPopClick = () => {
    setStartUpdateProject(true)
    const projectContentFromLocalStorage = JSON.parse(localStorage.getItem('labfront_project_content') as string)
    updateProject(projectContentFromLocalStorage)
    const listOfTaskNeedsToBeUpdate = tempTaskList.filter((item) => !!item.actionType)
    if (listOfTaskNeedsToBeUpdate.length) {
      listOfTaskNeedsToBeUpdate.map((task, index, arr) => {
        switch (task.actionType) {
          case 'create':
            return createTaskFromTempTaskList(task, index === arr.length - 1)
          case 'update':
            return updateTaskFromTempTaskList(task, index === arr.length - 1)
          case 'hide':
            return hideTaskFromTempTaskList(task, index === arr.length - 1)
          case 'show':
            return showTaskFromTempTaskList(task, index === arr.length - 1)
          default:
            return
        }
      })
    } else {
      setFinishedUpdateTasks(true)
    }
    doREQUEST_METHOD_UPDATE({
      setRequestResult: setRequestMethodUpdateResult,
      payload: {
        methodId,
        garminDeviceEnable: tempGarminDeviceEnable,
        garminConnectEnable: tempGarminConnectEnable,
        garminStreamEnable: tempGarminStreamEnable,
        movesenseDeviceEnable: tempMovesenseDeviceEnable,
        dexcomIntegrationId: tempDexcomIntegrationId,
      },
    })
  }

  useEffect(() => {
    if (methodOrderUpdateRequestResult?.success) {
      localStorage.removeItem('labfront_project_content')
      navigate(`/dashboard/${projectId}/?action=summary`)
    }
  }, [methodOrderUpdateRequestResult])

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <PopupSimple
        {...{
          method: '',
          name: 'Save changes and update participant app?',
          description: `This will automatically update the participant app. Depending on participants’ internet connection, it may take a moment for changes to be seen.`,
          buttonColor: 'primary',
          buttonText: `Save & Update`,
          closeAction,
          doAction: handleEditConfirmPopClick,
        }}
      />
    </div>
  )
}
