import {Dispatch, SetStateAction} from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {doMETHOD_UPDATE_TASK, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import {getToken} from './token_fetcher'

export const REQUEST_TASK_QUESTIONNAIRE_UPDATE = 'REQUEST_TASK_QUESTIONNAIRE_UPDATE'
export const doREQUEST_TASK_QUESTIONNAIRE_UPDATE = createDoRequestAction(REQUEST_TASK_QUESTIONNAIRE_UPDATE)

export const requestTaskQuestionnaireUpdateActionCreators = {
  doREQUEST_TASK_QUESTIONNAIRE_UPDATE,
}

interface Payload {
  payload: {
    id: string
    requestId: string
    projectId: string
    methodId: string
    workspaceId: string
    type: string
    scheduleRepeat: boolean
    scheduleDescription: string
    questionnaire: {[key: string]: any}
    color: string
    reminderList?: Record<string, string>[]
  }
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestTaskQuestionnaireUpdate({payload, setRequestResult}: Payload): SagaIterator {
  debug('saga*requestTaskQuestionnaireUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        id: v.string().uuid().exist(),
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        methodId: v.string().uuid().exist(),
        workspaceId: v.string().uuid().exist(),
        type: v.string().exist(),
        scheduleRepeat: v.boolean().optional(),
        scheduleDescription: v.string().optional(),
        questionnaire: v.object(),
        color: v.string().optional(),
        reminderList: v.array().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_TASK_QUESTIONNAIRE_UPDATE,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {
    scheduleRepeat,
    scheduleDescription,
    id,
    type,
    questionnaire,
    projectId,
    workspaceId,
    methodId,
    color,
    reminderList,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-questionnaire-update`,
    accessToken,
    data: {
      scheduleRepeat,
      scheduleDescription,
      id,
      type,
      questionnaire,
      projectId,
      workspaceId,
      methodId,
      color,
      required: false,
      reminderList,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_TASK_QUESTIONNAIRE_UPDATE,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/task-questionnaire-update',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  delete result.payload.enabled
  yield put(doMETHOD_UPDATE_TASK(result.payload))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_TASK_QUESTIONNAIRE_UPDATE,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* taskQuestionnaireUpdateSaga() {
  yield takeEvery(REQUEST_TASK_QUESTIONNAIRE_UPDATE as any, requestTaskQuestionnaireUpdate)
}
