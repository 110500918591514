import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {TempTask} from '../../../model'

export enum TempTaskDataActionType {
  TEMP_TASK_DATA_SET = 'TEMP_TASK_DATA_SET',
  TEMP_TASK_DATA_DELETE = 'TEMP_TASK_DATA_DELETE',
  TEMP_TASK_DATA_ADD_TASK = 'TEMP_TASK_DATA_ADD_TASK',
  TEMP_TASK_DATA_DELETE_TASK = 'TEMP_TASK_DATA_DELETE_TASK',
  TEMP_TASK_DATA_UPDATE_TASK = 'TEMP_TASK_DATA_UPDATE_TASK',
  TEMP_TASK_DATA_TASK_LIST_UPDATE = 'TEMP_TASK_DATA_TASK_LIST_UPDATE',
  TEMP_TASK_DATA_REPLACE_TEMP_TASK = 'TEMP_TASK_DATA_REPLACE_TEMP_TASK',
  TEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE = 'TEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE',
  TEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE = 'TEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE',
  TEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE = 'TEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE',
  TEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE = 'TEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE',
  TEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE = 'TEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE',
}

export const doTEMP_TASK_DATA_SET = createAction(TempTaskDataActionType.TEMP_TASK_DATA_SET)
export const doTEMP_TASK_DATA_DELETE = createAction(TempTaskDataActionType.TEMP_TASK_DATA_DELETE)
export const doTEMP_TASK_DATA_ADD_TASK = createAction(TempTaskDataActionType.TEMP_TASK_DATA_ADD_TASK)
export const doTEMP_TASK_DATA_DELETE_TASK = createAction(TempTaskDataActionType.TEMP_TASK_DATA_DELETE_TASK)
export const doTEMP_TASK_DATA_UPDATE_TASK = createAction(TempTaskDataActionType.TEMP_TASK_DATA_UPDATE_TASK)
export const doTEMP_TASK_DATA_TASK_LIST_UPDATE = createAction(TempTaskDataActionType.TEMP_TASK_DATA_TASK_LIST_UPDATE)
export const doTEMP_TASK_DATA_REPLACE_TEMP_TASK = createAction(TempTaskDataActionType.TEMP_TASK_DATA_REPLACE_TEMP_TASK)
export const doTEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE = createAction(
  TempTaskDataActionType.TEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE,
)
export const doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE = createAction(
  TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE,
)
export const doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE = createAction(
  TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE,
)
export const doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE = createAction(
  TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE,
)
export const doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE = createAction(
  TempTaskDataActionType.TEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE,
)

interface TempTaskDataState {
  taskList: TempTask[]
  garminDeviceEnable: boolean
  garminConnectEnable: boolean
  garminStreamEnable: boolean
  movesenseDeviceEnable: boolean
  dexcomIntegrationId: string | undefined | null
}

interface RootState {
  tempTaskData: TempTaskDataState
}

/* selector */
export const selectTempTaskData = () => {
  return useSelector((state: RootState) => state.tempTaskData)
}

export const tempTaskDataActionCreators = {
  doTEMP_TASK_DATA_SET,
  doTEMP_TASK_DATA_DELETE,
  doTEMP_TASK_DATA_ADD_TASK,
  doTEMP_TASK_DATA_DELETE_TASK,
  doTEMP_TASK_DATA_UPDATE_TASK,
  doTEMP_TASK_DATA_TASK_LIST_UPDATE,
  doTEMP_TASK_DATA_REPLACE_TEMP_TASK,
  doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE,
  doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE,
  doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE,
  doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE,
  doTEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE,
}

export const tempTaskDataDefaultState: TempTaskDataState = {
  taskList: [],
  garminDeviceEnable: false,
  garminConnectEnable: false,
  garminStreamEnable: false,
  movesenseDeviceEnable: false,
  dexcomIntegrationId: undefined,
}

type Action =
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_SET
      payload: {
        taskList: TempTask[]
        garminDeviceEnable: boolean
        garminConnectEnable: boolean
        garminStreamEnable: boolean
        movesenseDeviceEnable: boolean
        dexcomIntegrationId?: string | null
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_DELETE
      payload: undefined
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_ADD_TASK
      payload: TempTask
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_DELETE_TASK
      payload: {
        taskId: string
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_UPDATE_TASK
      payload: TempTask
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_TASK_LIST_UPDATE
      payload: {
        taskList: TempTask[]
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_REPLACE_TEMP_TASK
      payload: {
        tempTaskId: string
        newId: string
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE
      payload: {
        garminDeviceEnable: boolean
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE
      payload: {
        garminConnectEnable: boolean
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE
      payload: {
        garminStreamEnable: boolean
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE
      payload: {
        movesenseDeviceEnable: boolean
      }
    }
  | {
      type: TempTaskDataActionType.TEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE
      payload: {
        dexcomIntegrationId: string | undefined | null
      }
    }

export const tempTaskDataReducer = (
  state = {...tempTaskDataDefaultState},
  {type, payload}: Action,
): TempTaskDataState => {
  const newTaskList = [...state.taskList]
  switch (type) {
    case TempTaskDataActionType.TEMP_TASK_DATA_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          taskList: v.array().items(v.object()).exist(),
          garminConnectEnable: v.boolean().exist(),
          garminStreamEnable: v.boolean().exist(),
          dexconIntegrationId: v.string().optional().allow(null),
        }),
      })
      return {...state, ...payload}

    case TempTaskDataActionType.TEMP_TASK_DATA_DELETE:
      return tempTaskDataDefaultState

    case TempTaskDataActionType.TEMP_TASK_DATA_ADD_TASK:
      assertPartialSchema({
        payload,
        schema: v.object(), // TODO: complete the schema include todo, timer, qn, garmin
      })
      newTaskList.push(payload)
      return {...state, taskList: newTaskList}

    case TempTaskDataActionType.TEMP_TASK_DATA_DELETE_TASK: {
      assertPartialSchema({
        payload,
        schema: v.object({
          taskId: v.string().uuid().exist(),
        }),
      })
      const removeItemIndex = newTaskList.findIndex((item) => item.id === payload.taskId)
      newTaskList.splice(removeItemIndex, 1)

      return {...state, taskList: newTaskList}
    }

    case TempTaskDataActionType.TEMP_TASK_DATA_UPDATE_TASK: {
      assertPartialSchema({
        payload,
        schema: v.object(), // TODO: complete the schema include todo, timer, qn, garmin
      })
      const updateItemIndex = newTaskList.findIndex((item) => item.id === payload.id)
      newTaskList[updateItemIndex] = payload
      return {...state, taskList: newTaskList}
    }

    case TempTaskDataActionType.TEMP_TASK_DATA_TASK_LIST_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          taskList: v.array().items(
            v.object(), // TODO: complete schema
          ),
        }),
      })

      return {...state, taskList: payload.taskList}

    case TempTaskDataActionType.TEMP_TASK_DATA_REPLACE_TEMP_TASK: {
      assertPartialSchema({
        payload,
        schema: v.object({
          tempTaskId: v.string().uuid().exist(),
          newId: v.string().uuid().exist(),
        }),
      })

      const updateItemIndex = newTaskList.findIndex((item) => item.id === payload.tempTaskId)
      newTaskList[updateItemIndex].id = payload.newId
      return {...state, taskList: newTaskList}
    }

    case TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          garminDeviceEnable: v.boolean().exist(),
        }),
      })

      return {...state, garminDeviceEnable: payload.garminDeviceEnable}

    case TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          garminConnectEnable: v.boolean().exist(),
        }),
      })

      return {...state, garminConnectEnable: payload.garminConnectEnable}

    case TempTaskDataActionType.TEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          garminStreamEnable: v.boolean().exist(),
        }),
      })

      return {...state, garminStreamEnable: payload.garminStreamEnable}

    case TempTaskDataActionType.TEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          movesenseDeviceEnable: v.boolean().exist(),
        }),
      })

      return {...state, movesenseDeviceEnable: payload.movesenseDeviceEnable}

    case TempTaskDataActionType.TEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          dexcomIntegrationId: v.string().allow(null).optional(),
        }),
      })

      return {...state, dexcomIntegrationId: payload.dexcomIntegrationId}

    default:
      return {...state}
  }
}
