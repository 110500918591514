import {PopupConfirmation} from '..'

interface ProjectCompletePopProps {
  onClickComplete: () => void
  onClickClose: () => void
}

export const ProjectCompletePop = (props: ProjectCompletePopProps) => {
  const {onClickComplete, onClickClose} = props

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PopupConfirmation
        {...props}
        method='Complete'
        name='Project'
        description='This will end data collection and mark project as complete.'
        term1='I understand that this will end data collection for participants and log them out of the project on the participant app.'
        term2='I understand that this action cannot be undone.I understand that this action cannot be undone.'
        buttonText='End Data Collection and Complete Project'
        closeAction={() => onClickClose()}
        doAction={() => onClickComplete()}
      />
    </div>
  )
}
