import {SetStateAction, useEffect, useState, Dispatch, createRef, RefObject} from 'react'

import {selectTheme, selectWorkspaces, createDispatchActions} from '../../store'

import {v, useForm, RIF, _, useCurrentWorkspaceState, RequestResult} from '../../lib'

import {ButtonCancel, LabelBox, Input, Textarea, TextLink, Button} from '..'

import {Scrollbars} from 'react-custom-scrollbars-2'
import {Controller} from 'react-hook-form'
import {TaskTypeForDevicePage} from '../../model'
import Lottie from 'lottie-web'
import AddingAnimation from '../../asset/lotties/adding_animation.json'
import {TickWhiteIcon} from '../../asset/image'

const schema = v.object({
  name: v.string().exist(),
  description: v.string().exist(),
  investigator: v.string().exist(),
  organization: v.string().exist(),
  countries: v.string().exist(),
  contactDescription: v.string().exist(),
})
export interface DexcomIntegrationFormProps {
  setDisplayDevicePage?: Dispatch<SetStateAction<TaskTypeForDevicePage>>
  setDisplayDexcomIntegrationForm?: Dispatch<SetStateAction<boolean>>
}

interface FormData {
  name: string
  descripiton: string
  investigator: string
  organization: string
  countries: string
  contactDescription: string
}

export const DexcomIntegrationForm = (props: DexcomIntegrationFormProps) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {doREQUEST_DEXCOM_INTEGRATION_CREATE}: any = createDispatchActions()
  const {setDisplayDevicePage, setDisplayDexcomIntegrationForm} = props
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const {register, control, handleSubmit, watch, formState} = useForm({
    schema,
    mode: 'onChange',
  })
  const workspacesState = selectWorkspaces()
  const {workspaceId, workspace: currentWorkspace} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'
  const dexcomIntegrationList = currentWorkspace?.dexcomIntegrationList

  const [formSubmited, setFormSubmited] = useState(false)
  const [isSubmittingForm, setIsSubmittinForm] = useState(false)
  const animationRef: RefObject<HTMLDivElement> = createRef()

  useEffect(() => {
    if (isSubmittingForm) {
      Lottie.loadAnimation({
        container: animationRef.current as Element,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: AddingAnimation,
      })
    }
  }, [isSubmittingForm])

  useEffect(() => {
    if (formSubmited) return Lottie.stop()
  }, [formSubmited])

  const onSubmit: any = (data: FormData) => {
    setIsSubmittinForm(true)
    doREQUEST_DEXCOM_INTEGRATION_CREATE({
      setRequestResult,
      payload: {
        workspaceId,
        ...data,
      },
    })
  }

  useEffect(() => {
    if (requestResult?.success) {
      setFormSubmited(true)

      setTimeout(() => {
        handleClose()
      }, 2000)
    }
  }, [requestResult])

  const onError: any = (error: Error) => {
    console.log(error)
  }

  const handleClose = () => {
    if (setDisplayDexcomIntegrationForm) {
      return setDisplayDexcomIntegrationForm(false)
    }

    if (setDisplayDevicePage) {
      if (dexcomIntegrationList?.length) {
        return setDisplayDevicePage('dexcomSelectorPopup')
      }
      return setDisplayDevicePage('')
    }
  }

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <div
        css={{
          width: '560px',
          display: 'flex',
          flexDirection: 'column',
          padding: '30px',
          background: '#fff',
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
        }}
      >
        <ButtonCancel onClick={handleClose} />
        <p
          css={{
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h3,
            marginTop: '36px',
            marginBottom: '16px',
          }}
        >
          Request Dexcom Integration
        </p>
        <p
          css={{
            fontWeight: fontWeight.medium,
            marginBottom: '36px',
          }}
        >
          Thank you for your interest in adding Dexcom to your project. Please note that Dexcom requires approval for
          each integration, which involves completing a data use agreement. To start the process, fill out the form
          below.
          <br />
          <br />
          Once submitted, we will connect you with Dexcom via email. Please&nbsp;
          <a href='#' target='_blank' css={{color: color.primary}}>
            contact us
          </a>
          &nbsp;if you have any other questions. <br />
          <br />
          Important: The approval process typically takes around two months, even if participants use their own devices.
          We recommend starting early to keep your project on schedule.
        </p>
        <form onSubmit={handleSubmit(onSubmit, onError)} data-testid='dexcom_integration_form'>
          <Scrollbars
            autoHide={false}
            autoHideTimeout={800}
            style={{
              width: '100%',
              height: '370px',
            }}
          >
            <div css={{marginBottom: '36px'}}>
              <LabelBox
                {...{
                  label: 'Project Name',
                  required: true,
                  leftCharacters: isNaN(watch('name')?.length) ? 50 : 50 - watch('name').length,
                }}
              >
                <Input
                  {...register('name', {required: true})}
                  autoComplete='off'
                  maxLength='50'
                  readOnly={role === 'viewer'}
                  hasError={!!formState.errors.name}
                  placeholder='Write a short and easy name to identify name for your project'
                />
              </LabelBox>
            </div>
            <div css={{marginBottom: '36px'}}>
              <LabelBox
                {...{
                  label: 'Project Description',
                  required: true,
                  leftCharacters: isNaN(watch('description')?.length) ? 250 : 250 - watch('description').length,
                }}
              >
                <Controller
                  control={control}
                  name='description'
                  render={({field: {name, value, onChange}}) => (
                    <Textarea
                      {...{
                        name,
                        value,
                        onChange,
                      }}
                      placeholder='Briefly describe your project and how you will use Dexcom data.'
                      rows={6}
                      maxLength={250}
                      readOnly={role === 'viewer'}
                      hasError={!!formState.errors.description}
                    />
                  )}
                />
              </LabelBox>
            </div>
            <div css={{marginBottom: '36px'}}>
              <LabelBox
                {...{
                  label: 'Principal Investigator',
                  required: true,
                  leftCharacters: isNaN(watch('investigator')?.length) ? 50 : 50 - watch('investigator').length,
                }}
              >
                <Input
                  {...register('investigator', {required: true})}
                  autoComplete='off'
                  maxLength='50'
                  readOnly={role === 'viewer'}
                  hasError={!!formState.errors.investigator}
                  placeholder='Principal Investigator for this project'
                />
              </LabelBox>
            </div>
            <div css={{marginBottom: '36px'}}>
              <LabelBox
                {...{
                  label: 'Organization',
                  required: true,
                  leftCharacters: isNaN(watch('organization')?.length) ? 50 : 50 - watch('organization').length,
                }}
              >
                <Input
                  {...register('organization', {required: true})}
                  autoComplete='off'
                  maxLength='50'
                  readOnly={role === 'viewer'}
                  hasError={!!formState.errors.organization}
                  placeholder='Organization conducting this research.'
                />
              </LabelBox>
            </div>
            <div css={{marginBottom: '36px'}}>
              <LabelBox
                {...{
                  label: 'Countries (Researcher and Participants)',
                  required: true,
                  leftCharacters: isNaN(watch('countries')?.length) ? 50 : 50 - watch('countries').length,
                }}
              >
                <Input
                  {...register('countries', {required: true})}
                  autoComplete='off'
                  maxLength='50'
                  readOnly={role === 'viewer'}
                  hasError={!!formState.errors.countries}
                  placeholder='Where are the researchers and participants based?'
                />
              </LabelBox>
            </div>
            <LabelBox
              {...{
                label: 'Contact Email',
                required: true,
                leftCharacters: isNaN(watch('contactDescription')?.length)
                  ? 50
                  : 50 - watch('contactDescription').length,
              }}
            >
              <Input
                {...register('contactDescription', {required: true})}
                autoComplete='off'
                maxLength='50'
                readOnly={role === 'viewer'}
                hasError={!!formState.errors.contactDescription}
                placeholder='Please provide the email that will be used for further communications.'
              />
            </LabelBox>
          </Scrollbars>
          <div
            css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '48px',
            }}
          >
            <TextLink type='button' onClick={handleClose} css={{color: color.primary}} children='Cancel' />
            {RIF(
              !isSubmittingForm && !formSubmited,
              <Button
                data-testid='dexcom_integration_form_submit_btn'
                disabled={!formState.isValid}
                type='submit'
                btnPadding='medium'
                css={{width: '130px'}}
              >
                Send Request
              </Button>,
            )}
            {RIF(
              isSubmittingForm && !formSubmited,
              <Button css={{width: '130px', height: '35px'}}>
                <div ref={animationRef} css={{width: '132px', height: '34px'}} />
              </Button>,
            )}
            {RIF(
              formSubmited,
              <div
                css={{
                  borderRadius: '5px',
                  width: '130px',
                  height: '33px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: color.task.green,
                }}
              >
                <img src={TickWhiteIcon} width='18' height='18' />
                <span css={{color: color.white, marginLeft: '8px'}}>Sent</span>
              </div>,
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
