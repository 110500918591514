import {EmotionJSX} from '@emotion/react/types/jsx-namespace'
import {CloseNewIcon} from '../../asset/image'
import {selectTheme} from '../../store'
import {Color} from '../../store/state/theme/theme_init_state'
import {RIF} from '../../lib'
import {ButtonText, ButtonTemplate, ButtonSize, ButtonColorTheme, ParticipantPopupOperationType} from '..'
import {Dispatch, SetStateAction} from 'react'

export enum PopupSize {
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
}

export const PopupTemplate = (props: {
  title: string
  description: string
  background: boolean
  popupSize: PopupSize
  handleClosePopup: () => void
  mainFunction: (arg?: any) => void
  buttonContent: EmotionJSX.Element | string
  children?: EmotionJSX.Element | string
  buttonColorTheme?: ButtonColorTheme
  buttonDisabled?: boolean
  bannerImgUrl?: string
  thirdButtonText?: EmotionJSX.Element | string
  thirdButtonFunction?: (e: React.MouseEvent<HTMLButtonElement>) => void
  titleIconURL?: string
  hideCancelButton?: boolean
}) => {
  const {color, fontSize, fontWeight, borderRadius} = selectTheme()
  const {
    title,
    description,
    background,
    popupSize,
    handleClosePopup,
    mainFunction,
    buttonContent,
    children,
    buttonColorTheme,
    buttonDisabled,
    bannerImgUrl,
    thirdButtonText,
    thirdButtonFunction,
    titleIconURL,
    hideCancelButton,
  } = props

  const popupSizeMapping = {
    [PopupSize.Big]: {
      width: '880px',
      minHeight: '480px',
    },
    [PopupSize.Medium]: {
      width: '600px',
      minHeight: bannerImgUrl ? '444px' : '320px',
    },
    [PopupSize.Small]: {
      width: '480px',
      minHeight: '240px',
    },
  }

  return (
    <div
      css={{
        position: 'fixed',
        zIndex: 104,
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        backgroundColor: background ? color.popupBackground : 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          ...popupSizeMapping[popupSize],
          maxHeight: '80vh',
          borderRadius: borderRadius.popup,
          background: color.surface.white,
          overflow: 'hidden',
        }}
      >
        {/* banner image */}
        {RIF(
          bannerImgUrl,
          <div
            css={{
              width: '100%',
              height: '248px',
              backgroundImage: `url(${bannerImgUrl})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />,
        )}
        {/* top part: title + description */}
        <div
          css={{
            padding: '24px 24px 8px 24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          {/* title */}
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {RIF(
                titleIconURL,
                <img
                  src={titleIconURL}
                  css={{
                    width: '24px',
                    height: '24px',
                  }}
                />,
              )}
              <span
                css={{
                  fontSize: fontSize.h3,
                  fontWeight: fontWeight.thick,
                }}
              >
                {title}
              </span>
            </div>
            {RIF(
              !bannerImgUrl,
              <button
                onClick={handleClosePopup}
                css={{
                  width: '24px',
                  minWidth: '24px',
                  height: '24px',
                  minHeight: '24px',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: 'transparent',
                  backgroundImage: `url(${CloseNewIcon})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  ':hover': {
                    backgroundColor: color.surface.default,
                  },
                }}
              />,
            )}
          </div>

          {/* description */}
          {RIF(
            description.length > 0,
            <p
              css={{
                color: color.textIcon.secondary,
              }}
            >
              {description}
            </p>,
          )}
        </div>

        {/* center part: content */}
        {RIF(
          children,
          <div
            css={{
              padding: '32px 24px',
              flex: '1',
            }}
          >
            {children}
          </div>,
        )}
        {/* bottom part: cancel button + main button + (extra button)*/}
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px 24px',
            borderTop: `1px solid ${color.border.light}`,
          }}
        >
          <div>
            {RIF(
              thirdButtonText,
              <ButtonText
                {...{
                  onClick: thirdButtonFunction as (e: React.MouseEvent<HTMLButtonElement>) => void,
                  textColor: color.textIcon.warning,
                  textColorWhileHovered: color.surface.hover.warning,
                  backgroundColorWhileHovered: color.surface.warning,
                }}
              >
                {thirdButtonText as EmotionJSX.Element | string}
              </ButtonText>,
            )}
          </div>
          <div
            css={{
              display: 'inline-flex',
              gap: '8px',
            }}
          >
            {/* cancel button */}
            {RIF(
              !hideCancelButton,
              <ButtonTemplate
                {...{
                  buttonSize: ButtonSize.Big,
                  onClick: handleClosePopup,
                  colorTheme: ButtonColorTheme.Grey,
                }}
              >
                Cancel
              </ButtonTemplate>,
            )}

            {/* main button */}
            <ButtonTemplate
              {...{
                buttonSize: ButtonSize.Big,
                onClick: mainFunction,
                colorTheme: buttonColorTheme,
                disabled: buttonDisabled,
              }}
            >
              {buttonContent}
            </ButtonTemplate>
          </div>
        </div>
      </div>
    </div>
  )
}
