import {
  debug,
  assertTotalSchema,
  createDoRequestAction,
  v,
  SagaIterator,
  put,
  call,
  API_URL,
  request,
  takeEvery,
} from '../../lib'
import {ProjectAuditTrailAction} from '../../shared/db/project_audit_trail'
import {doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'
import {getToken} from './token_fetcher'

export const REQUEST_PROJECT_AUDIT_TRAIL_CREATE = 'REQUEST_PROJECT_AUDIT_TRAIL_CREATE'
export const doREQUEST_PROJECT_AUDIT_TRAIL_CREATE = createDoRequestAction(REQUEST_PROJECT_AUDIT_TRAIL_CREATE)

export const requestProjectAuditTrailCreateActionCreators = {
  doREQUEST_PROJECT_AUDIT_TRAIL_CREATE,
}

export function* requestProjectAuditTrailCreate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectAuditTrailCreate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        action: v.string().valid(...Object.values(ProjectAuditTrailAction)),
        before: v.when('action', {
          is: ProjectAuditTrailAction.Update,
          then: v.object().required(),
          otherwise: v.object().optional(),
        }),
        after: v.when('action', {
          is: ProjectAuditTrailAction.Update,
          then: v.object().required(),
          otherwise: v.object().optional(),
        }),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_AUDIT_TRAIL_CREATE,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {projectId, action, before, after} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-audit-trail-create`,
    accessToken,
    data: {
      projectId,
      action,
      before,
      after,
    },
  })

  // handle error
  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_AUDIT_TRAIL_CREATE,
          fromPayload: payload,
          url: '/v1/web/project-audit-trail-create',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_PROJECT_AUDIT_TRAIL_CREATE,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* projectAuditTrailCreateSaga() {
  yield takeEvery(REQUEST_PROJECT_AUDIT_TRAIL_CREATE, requestProjectAuditTrailCreate)
}
