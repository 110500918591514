import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {selectTheme} from '../../store'
import {
  SaveBar,
  PopupConfirmDiscardQuestionnaireChanges,
  QuestionnaireSection,
  QuestionnaireQuestion,
  // QuestionnaireQuestion
} from '..'
import {ContentListItem, QuestionnaireTempQuestion, QuestionnaireTempSection, TempQuestionnaire} from '../../model'
import {_, RIF, t} from '../../lib'
import {IQuestionnaire, QuestionnaireQuestionType} from '../../shared/db'
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd'

export const QuestionnairePage = (props: {
  taskId?: string
  questionnaire: TempQuestionnaire | IQuestionnaire | null
  setQuestionnaire: Dispatch<SetStateAction<TempQuestionnaire | IQuestionnaire | null>>
  closeAction: () => void
  viewOnly: boolean
}) => {
  const {pad, color} = selectTheme()
  const {questionnaire, setQuestionnaire, closeAction, viewOnly} = props
  const [questionnaireContentList, setQuestionnaireContentList] = useState<ContentListItem[]>([])
  useEffect(() => {
    if (!questionnaire) return
    setQuestionnaireContentList(
      questionnaire.sectionList
        .flatMap((section) => {
          const questionList = section.questionList?.map((question) => ({question})) ?? []
          return [{section}, ...questionList]
        })
        .sort((a: ContentListItem, b: ContentListItem) => {
          const indexA = a.section?.index ?? a.question?.index ?? 0
          const indexB = b.section?.index ?? b.question?.index ?? 0
          return indexA - indexB
        }),
    )
    setEditingBlock(questionnaire.sectionList[0].id)
  }, [questionnaire])

  const [renderPopupConfirmDiscard, setRenderPopupConfirmDiscard] = useState<boolean>(false)

  // track the id of editing block to control the ui
  const [editingBlock, setEditingBlock] = useState<string | null>(null)

  // when click on background, make the editing block return to normal state
  const handleClickBackground = () => {
    setEditingBlock(null)
  }

  // transform content list into the format of section list
  // TODO: refactor this function to put pending questions into a invisible section
  const generateSectionListFromContentList = () => {
    const newSectionList: QuestionnaireTempSection[] = []
    const pendingQuestionList: QuestionnaireTempQuestion[] = []
    questionnaireContentList.forEach((c, index) => {
      if (c.section) {
        c.section.questionList = []
        newSectionList.push({...c.section, index})
      } else {
        const question = {...c.question, index} as QuestionnaireTempQuestion
        // prevent app from breaking when the first item of the content list is not a section
        // put questions in pending list if there is no section in the newSectionList
        if (newSectionList.length > 0) {
          if (!newSectionList[newSectionList.length - 1].questionList) {
            newSectionList[newSectionList.length - 1].questionList = [question]
          } else {
            newSectionList[newSectionList.length - 1].questionList?.push(question)
          }
        } else {
          pendingQuestionList.push(question)
        }
      }
      // at the end of the iteration, put all pending questions into the quesiton list of the first section
      if (index === questionnaireContentList.length - 1) {
        if (newSectionList[0].questionList) {
          newSectionList[0].questionList?.push(...pendingQuestionList)
        } else {
          newSectionList[0].questionList = pendingQuestionList
        }
      }
    })
    return newSectionList
  }

  // when saving questionnaire, transform content list into the format of questionnaire
  const saveQuestionnaire = () => {
    setQuestionnaire({...questionnaire, sectionList: generateSectionListFromContentList()} as TempQuestionnaire)
    closeAction()
  }

  const handleCloseWithoutSaving = () => {
    if (!viewOnly && !_.isEqual(questionnaire?.sectionList, generateSectionListFromContentList())) {
      return setRenderPopupConfirmDiscard(true)
    }
    closeAction()
  }

  const newSection = (): QuestionnaireTempSection => {
    const emptySection: QuestionnaireTempSection = {
      id: t.uuid(),
      legend: 'New Section',
      description: 'Description goes here',
      questionList: [],
    }
    if (questionnaire && 'id' in questionnaire) {
      return {...emptySection, id: questionnaire.id}
    }
    return emptySection
  }

  const newQuestion = (): QuestionnaireTempQuestion => {
    const emptyQuestion = {
      id: t.uuid(),
      label: t.uuid(),
      type: QuestionnaireQuestionType.MultiSelect,
      description: 'New Question',
      required: false,
      inputConfig: {
        options: [{name: 'New Option'}],
      },
    }
    if (questionnaire && 'id' in questionnaire) {
      return {...emptyQuestion, id: questionnaire.id}
    }
    return emptyQuestion
  }

  const addSection = (index: number) => {
    const newContentList = [...questionnaireContentList]
    const section = newSection()
    newContentList.splice(index + 1, 0, {section})
    setEditingBlock(section.id)
    setQuestionnaireContentList(newContentList)
  }

  const addQuestion = (index: number) => {
    const newContentList = [...questionnaireContentList]
    const question = newQuestion()
    newContentList.splice(index + 1, 0, {question})
    setEditingBlock(question.id)
    setQuestionnaireContentList(newContentList)
  }

  const deleteItem = (index: number) => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1)
    setQuestionnaireContentList(newContentList)
  }

  const duplicateItem = (index: number) => {
    const newContentList = [...questionnaireContentList]
    const newItem = _.cloneDeep(questionnaireContentList[index])
    const newItemId = t.uuid()
    if (newItem.section?.id) {
      newItem.section.id = newItemId
    } else if (newItem.question?.id) {
      newItem.question.id = newItemId
    }
    newContentList.splice(index + 1, 0, newItem)
    setEditingBlock(newItemId)
    setQuestionnaireContentList(newContentList)
  }

  const onDragEnd = (result: DropResult) => {
    // move dragged item from source index to destination index
    if (typeof result.destination?.index !== 'number') return
    const newContentList = [...questionnaireContentList]
    const [draggedItem] = newContentList.splice(result.source.index, 1)
    newContentList.splice(result.destination.index, 0, draggedItem)
    setQuestionnaireContentList(newContentList)
  }

  return (
    <div
      onClick={handleClickBackground}
      id='questionnaireBackground'
      css={{
        width: '100vw',
        height: '100vh',
        background: color.background,
        paddingBottom: pad.xl,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 103,
        overflowY: 'scroll',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {RIF(
        renderPopupConfirmDiscard,
        <PopupConfirmDiscardQuestionnaireChanges
          {...{
            closeAction: setRenderPopupConfirmDiscard,
            confirmAction: closeAction,
          }}
        />,
      )}
      <SaveBar
        {...{
          handleClickSave: saveQuestionnaire,
          closeAction: handleCloseWithoutSaving,
          title: questionnaire?.name ?? 'New Questionnaire',
          viewOnly: viewOnly,
        }}
      />
      <main
        css={{
          width: '800px',
          paddingTop: '90px',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='contentList' type='contentList'>
            {(provided) => (
              <div
                css={{
                  paddingBottom: '150px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '40px',
                }}
                ref={provided.innerRef}
              >
                {questionnaireContentList.map((item, i) => {
                  if (item.section) {
                    return (
                      <QuestionnaireSection
                        {...{
                          key: item.section.id,
                          id: item.section.id,
                          index: i,
                          section: item.section,
                          isEditing: editingBlock === item.section.id,
                          setEditingBlock,
                          addSection,
                          addQuestion,
                          duplicateItem,
                          deleteItem,
                          viewOnly,
                          questionnaireContentList,
                          setQuestionnaireContentList,
                        }}
                      />
                    )
                  } else if (item.question) {
                    return (
                      <QuestionnaireQuestion
                        {...{
                          key: item.question.id,
                          id: item.question.id,
                          index: i,
                          question: item.question,
                          questionnaireContentList,
                          setQuestionnaireContentList,
                          isEditing: editingBlock === item.question.id,
                          setEditingBlock,
                          viewOnly,
                          addSection,
                          addQuestion,
                          duplicateItem,
                          deleteItem,
                        }}
                      />
                    )
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </main>
    </div>
  )
}
