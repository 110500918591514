import {useState, useEffect} from 'react'
import {Heatmap, HeatmapConfig} from '@ant-design/charts'
import {selectTheme} from '../../../store'
import {useNavigate, useParams} from 'react-router-dom'
import {Adherence} from '../../../model'

import {dailyGarminStepTheme, ChartCard} from '..'
import {DailySummaryAdherenceData, _} from '../../../lib'

interface DailyAdherenceChartProps {
  height: string
  width: string
  data: DailySummaryAdherenceData
  adherenceList: Adherence[]
}

export const GarminDailySummaryAdherenceChart = (props: DailyAdherenceChartProps) => {
  const {height, width, data, adherenceList} = props
  const navigate = useNavigate()
  const {projectId, participantId} = useParams()
  const xField = 'date'
  const yField = 'taskName'

  const {color} = selectTheme()
  const [xAxisTickCount, setXAxisTickCount] = useState<number>(0)

  useEffect(() => {
    setXAxisTickCount(_.uniq(data.dailySummaryAdherenceList.map((adhenerece) => adhenerece.date)).length)
  }, [data])

  // const meta = {
  //   date: {
  //     alias: '',
  //   },
  //   done: {
  //     alias: '',
  //   },
  //   taskName: {
  //     alias: '',
  //   },
  //   numberOfCompletions: {
  //     alias: '',
  //   }
  // }
  const yAxisConfig = {
    title: {text: ''},
  }

  const config: HeatmapConfig = {
    data: data.dailySummaryAdherenceList,
    xField,
    yField,
    xAxis: {
      title: {text: 'Date'},
      tickLine: null,
      tickCount: xAxisTickCount,
      label: {
        autoRotate: true,
        formatter: (text: string) => {
          const dateString = `20${text.slice(0, 2)}/${text.slice(2, 4)}/${text.slice(4, 6)}`
          return dateString
        },
      },
    },
    yAxis: yAxisConfig,
    padding: 'auto',
    // meta,
    sizeRatio: 0.3,
    colorField: 'done',
    color: ({done}) => {
      if (done != null) return data.dehydratedTaskList[done]?.color
      return color.grey_80
    },
    shape: 'circle',
    theme: dailyGarminStepTheme,
    heatmapStyle({done}) {
      if (done != null) {
        return {
          fill: data.dehydratedTaskList[done]?.color,
          stroke: color.grey_80,
          lineWidth: 2,
        }
      } else {
        return {
          fill: color.white,
          stroke: color.grey_80,
          lineWidth: 2,
        }
      }
    },
    onReady: ({chart}) => {
      chart.on('click', (ev: any) => {
        const yymmddIndex = ev.data.data.date
        navigate(`/data_visualization_timeseries/${projectId}/${participantId}/${yymmddIndex}`)
        // setGraphDateFromAdherenceTable(yymmddIndex)
        // setRenderTimeseriesPage(true)
      })
    },
    tooltip: {
      title: 'View Time Series',
      formatter: (datum) => {
        let numberOfCompletions = 0
        for (const dailySummary of data.dailySummaryAdherenceList) {
          if (datum.date === dailySummary.date && datum.taskName === dailySummary.taskName) {
            numberOfCompletions = dailySummary.numberOfCompletions
            break
          }
        }
        return {name: 'Number of Completions', value: numberOfCompletions}
      },
    },
  }

  const chartCardProps = {
    height,
    width,
    chartTitle: 'Daily Summary Adherence',
    hintDescription: '',
    contentPadding: '0',
    chart: <Heatmap {...config} />,
  }
  return <ChartCard {...chartCardProps} />
}
