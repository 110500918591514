import { IEntityModel } from './base/entity_model'
import { IProject } from './project'

export enum ProjectAuditTrailAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export interface IProjectAuditTrail extends IEntityModel {
  projectId: string
  identityId: string
  action: ProjectAuditTrailAction
  before?: Partial<IProject> // changed fields before the action, should exist if action is Update
  after?: Partial<IProject> // changed fields after the action, should exist if action is Update
}
