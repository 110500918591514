import {isNil} from 'lodash'
import {Nullish} from 'utility-types'

import {IIdentity} from '../../../shared/db'
import {createAction, setSessionStorage, useSelector, v} from '../../../lib'

export enum IdentityActionType {
  IDENTITY_SET = 'IDENTITY_SET',
}

export const doIDENTITY_SET = createAction(IdentityActionType.IDENTITY_SET)

type IdentityState = Partial<IIdentity> | null

interface RootState {
  identity: IdentityState
}

export const selectIdentity = () => {
  return useSelector((state: RootState) => state.identity)
}

export const identityActionCreators = {
  doIDENTITY_SET,
}

export const identityDefaultState: IdentityState = null

type Action = {
  type: IdentityActionType.IDENTITY_SET
  payload: IdentityState | Nullish
}

export const identityReducer = (state = identityDefaultState, {type, payload}: Action): IdentityState => {
  switch (type) {
    case IdentityActionType.IDENTITY_SET: {
      let newState: IdentityState
      if (isNil(payload)) {
        newState = null
      } else {
        newState = {...payload}
      }
      setSessionStorage({identity: newState})
      return newState
    }
    default:
      return state
  }
}
