import { IEntityModel } from './base'
import { IQuestionnaire } from './questionnaire'
import { IQuestionnaireSection } from './questionnaire_section'

export enum QuestionnaireQuestionType {
  Text = 'text',
  Textarea = 'textarea',
  Number = 'number',
  Date = 'date',
  Select = 'select',
  MultiSelect = 'multi_select',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Time = 'time',
  DateTime = 'date_time',
}

export interface SelectInputConfig {
  options: {
    name: string
    value: string
  }[]
  allowOther?: boolean
}

export interface NumberInputConfig {
  integer?: boolean
  max?: number
  min?: number
}

type QuestionnaireInputConfigType<T extends QuestionnaireQuestionType> =
  T extends
    | QuestionnaireQuestionType.Select
    | QuestionnaireQuestionType.MultiSelect
    | QuestionnaireQuestionType.Radio
    ? SelectInputConfig
    : T extends QuestionnaireQuestionType.Number
    ? NumberInputConfig
    : undefined

export interface IQuestionnaireQuestion<
  T extends QuestionnaireQuestionType = QuestionnaireQuestionType,
  TInput = QuestionnaireInputConfigType<T>,
> extends IEntityModel {
  questionnaireId: string
  questionnaireSectionId: string
  questionnaire?: IQuestionnaire
  questionnaireSection?: IQuestionnaireSection
  description: string
  required: boolean
  label: string
  type: T
  inputConfig?: TInput
  index: number
  mediaUrl?: string | null
}

export function isSelectionInputConfig<T extends QuestionnaireQuestionType>(
  type: T,
  inputConfig: QuestionnaireInputConfigType<T>,
  // @ts-ignore
): inputConfig is SelectInputConfig {
  return [
    QuestionnaireQuestionType.Select,
    QuestionnaireQuestionType.MultiSelect,
    QuestionnaireQuestionType.Radio,
  ].includes(type)
}

export function isNumberInputConfig<T extends QuestionnaireQuestionType>(
  type: T,
  inputConfig: QuestionnaireInputConfigType<T>,
  // @ts-ignore
): inputConfig is NumberInputConfig {
  return [QuestionnaireQuestionType.Number].includes(type)
}
