import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {selectTheme, createDispatchActions, selectWorkspaces, selectProjectExtension} from '../../store'
import {ButtonCancel} from '..'
import {PlusBlueIcon} from '../../asset/image'
import {IProject, ProjectStatus} from '../../shared/db'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {RequestResult} from '../../lib'

interface PopupCreateProjectProps {
  onClickStartFromScratch: () => void
  onClickClose: () => void
}

export const PopupCreateProject = (props: PopupCreateProjectProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {onClickStartFromScratch, onClickClose} = props
  const workspacesState = selectWorkspaces()
  const {globalWorkspacesList} = workspacesState

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color.surface.popup,
        zIndex: '103',
      }}
    >
      <div
        css={{
          padding: '24px',
          borderRadius: '5px',
          backgroundColor: 'white',
          width: '848px',
          height: '479px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ButtonCancel onClick={() => onClickClose()} />
        <p
          css={{
            marginTop: '16px',
            marginBottom: '24px',
            fontSize: fontSize.h5,
          }}
        >
          Get inspired to setup your project with our templates or start from scratch!
        </p>
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
          <div
            css={{
              display: 'flex',
              height: 'calc(100% - 18px)',
              width: 'auto',
              paddingRight: '-16px',
            }}
          >
            <button
              onClick={() => onClickStartFromScratch()}
              css={{
                height: '100%',
                minWidth: '256px',
                marginRight: '16px',
                borderRadius: '5px',
                border: `1px dashed ${color.textIcon.link}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                padding: '0 24px',
                cursor: 'pointer',
              }}
            >
              <img css={{marginBottom: '8px'}} src={PlusBlueIcon} width='24' />
              <p
                css={{
                  fontWeight: fontWeight.thick,
                  fontSize: fontSize.h5,
                  color: color.textIcon.link,
                  marginBottom: '8px',
                }}
              >
                Start from scratch
              </p>
              <p
                css={{
                  fontSize: fontSize.h7,
                  color: color.textIcon.light,
                }}
              >
                Setup your project based on your research protocol.{' '}
              </p>
            </button>
            {globalWorkspacesList.map((workspace) => {
              return workspace.projectList
                .filter((project) => project.status !== ProjectStatus.Deleted)
                .map((project) => (
                  <TemplateProjectCard
                    {...{
                      project,
                      handleClick: () => {
                        return
                      },
                    }}
                  />
                ))
            })}
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

interface TemplateProjectCardProps {
  project: IProject
}

const TemplateProjectCard = (props: TemplateProjectCardProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {project} = props
  const {doREQUEST_PROJECT_DUPLICATE}: any = createDispatchActions()
  const navigate = useNavigate()
  const workspacesState = selectWorkspaces()
  const projectExtensionState = selectProjectExtension()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const duplicateTemplateProject = () => {
    if (!workspacesState?.currentWorkspaceId) return

    doREQUEST_PROJECT_DUPLICATE({
      setRequestResult,
      payload: {
        projectId: project.id,
        projectName: project.name,
        workspaceId: workspacesState.currentWorkspaceId,
      },
    })
  }

  useEffect(() => {
    if (!requestResult) return
    if (!requestResult?.success) return
    const projectId = requestResult?.result?.payload?.id
    if (projectId) return navigate(`/project_setup/${projectId}`)
  }, [requestResult])

  return (
    <button
      onClick={duplicateTemplateProject}
      css={{
        height: '336px',
        minWidth: '256px',
        marginRight: '16px',
        borderRadius: '5px',
        border: `1px solid ${color.border._80}`,
        backgroundColor: 'transparent',
        padding: '16px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        css={{
          width: '100%',
          maxHeight: 'calc(100% - 40px)',
          marginBottom: '24px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          css={{
            width: '100%',
            display: 'flex',
            marginBottom: '16px',
          }}
        >
          <p
            css={{
              fontWeight: fontWeight.thick,
              fontSize: fontSize.h7,
              color: color.tag.blue.dark,
              padding: '4px 8px',
              borderRadius: '5px',
              backgroundColor: color.tag.blue.light,
              display: 'inline-block',
            }}
          >
            Template
          </p>
        </div>
        <div
          css={{
            width: '100%',
            height: '120px',
            minHeight: '120px',
            backgroundColor: color.background,
            backgroundImage: `url(${projectExtensionState[project.id]?.imageUrl ?? ''})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            marginBottom: '16px',
          }}
        />
        <div
          css={{
            maxHeight: '120px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            css={{
              marginBottom: '8px',
              fontWeight: fontWeight.bold,
              textAlign: 'left',
            }}
          >
            {project.name}
          </p>
          <p
            css={{
              textAlign: 'left',
              fontSize: fontSize.h7,
              color: color.textIcon.light,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {project.description}
          </p>
        </div>
      </div>
      <p
        css={{
          fontSize: fontSize.h7,
          fontWeight: fontWeight.thick,
          color: color.textIcon.link,
        }}
      >
        Use this Template
      </p>
    </button>
  )
}
