import React, {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {selectTheme, selectMethod, createDispatchActions, selectTempTaskData} from '../../store'
import {SaveBar, GarminSpecPanel, DeviceRemovePopup, GarminSidebar} from '..'
import {
  TaskStateType,
  TempTask,
  GarminDeviceSpecCopy,
  TaskTypeForInstructionPage,
  TempStopwatchGarminStream,
  TempGarminDevice,
} from '../../model'
import {RIF, _, t, useCurrentWorkspaceState, useCurrentProjectState, RequestResult} from '../../lib'
import {LinkToIcon} from '../../asset/image'
import GarminDeviceIcon from '../../asset/image/garmin_device.png'
import {GarminDeviceType, IGarminDevice, TaskType} from '../../shared/db'

const parseGarminDeviceSpec = (taskDetail: IGarminDevice | TempGarminDevice | undefined): GarminDeviceSpecCopy => {
  return {
    type: taskDetail?.type ?? GarminDeviceType.Vivosmart3,
    heartRateEnable: taskDetail?.heartRateEnable ?? false,
    heartRateSampleRate: taskDetail?.heartRateSampleRate ?? 3600,
    stressEnable: taskDetail?.stressEnable ?? false,
    stressSampleRate: taskDetail?.stressSampleRate ?? 3600,
    stepsEnable: taskDetail?.stepsEnable ?? true,
    respirationEnable: taskDetail?.respirationEnable ?? false,
    respirationSampleRate: taskDetail?.respirationSampleRate ?? 60,
    pulseOxEnable: taskDetail?.pulseOxEnable ?? false,
    pulseOxSampleRate: taskDetail?.pulseOxSampleRate ?? 3600,
    bbiEnable: taskDetail?.bbiEnable ?? false,
    zeroCrossingEnable: taskDetail?.zeroCrossingEnable ?? false,
    zeroCrossingSampleRate: taskDetail?.zeroCrossingSampleRate ?? 60,
    actigraphyEnable: taskDetail?.actigraphyEnable ?? false,
    actigraphySampleRate: taskDetail?.actigraphySampleRate ?? 60,
    actigraphyZeroCrossingThreshold: taskDetail?.actigraphyZeroCrossingThreshold ?? 50,
    actigraphyEnergyThreshold: taskDetail?.actigraphyEnergyThreshold ?? 50,
    temperatureEnable: taskDetail?.temperatureEnable ?? false,
    temperatureSampleRate: taskDetail?.temperatureSampleRate ?? 3600,
    enhancedBbiEnable: taskDetail?.enhancedBbiEnable ?? false,
  }
}
export interface DeviceGarminProps {
  closeAction: (arg: boolean) => void
  setDisplayInstructionPop: Dispatch<SetStateAction<TaskTypeForInstructionPage>>
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

export const DeviceGarmin = (props: DeviceGarminProps) => {
  const {color} = selectTheme()
  const {closeAction, setDisplayInstructionPop, setDisplayChangePlanPage} = props

  const {
    doREQUEST_TASK_GARMIN_DEVICE_CREATE,
    doREQUEST_TASK_GARMIN_DEVICE_UPDATE,
    doREQUEST_TASK_DELETE,
    doREQUEST_METHOD_UPDATE,
    doTEMP_TASK_DATA_ADD_TASK,
    doTEMP_TASK_DATA_UPDATE_TASK,
    doTEMP_TASK_DATA_DELETE_TASK,
    doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE,
    doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE,
    doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE,
  }: any = createDispatchActions()

  const [requestResultOfGarminUpdate, setRequestResultOfGarminUpdate] = useState<RequestResult | null>(null)
  const [requestResultOfMethodUpdate, setRequestResultOfMethodUpdate] = useState<RequestResult | null>(null)

  const methodState = selectMethod()
  const {workspaceId} = useCurrentWorkspaceState()
  const {projectId, project} = useCurrentProjectState()
  const isEditingLiveProject = project?.status === 'live'
  const methodId = methodState?.id as string
  const tempTaskData = selectTempTaskData()

  const [displayRemovePopup, setDisplayRemovePopup] = useState(false)

  /* -------------------- Garmin device -------------------- */
  const [garminConnectEnable, setGarminConnectEnable] = useState<boolean>(
    isEditingLiveProject ? tempTaskData?.garminConnectEnable : methodState?.garminConnectEnable,
  )

  // garmin device task
  const [garminDeviceEnable, setGarminDeviceEnable] = useState<boolean>(
    isEditingLiveProject ? tempTaskData?.garminDeviceEnable : methodState?.garminDeviceEnable,
  )
  const garminDeviceTask: TaskStateType | undefined = _.find(methodState?.taskList, {type: TaskType.GarminDevice})
  const garminDeviceTaskDetail: IGarminDevice | undefined = garminDeviceTask?.garminDevice
  const tempGarminDeviceTask: TempTask | undefined = _.find(tempTaskData?.taskList, {
    type: TaskType.GarminDevice,
  })
  const tempGarminDeviceTaskDetail: TempGarminDevice | undefined = tempGarminDeviceTask?.garminDevice
  const garminDeviceTaskIdFromMethod = garminDeviceTask?.id

  // garmin stream
  const [garminStreamEnable, setGarminStreamEnable] = useState<boolean>(
    isEditingLiveProject ? tempTaskData?.garminStreamEnable : methodState?.garminStreamEnable,
  )
  const garminStreamTasks: TaskStateType[] | undefined = _.filter(methodState?.taskList, {
    type: TaskType.StopwatchGarminStream,
  })
  const tempGarminStreamTasks: TempTask[] | undefined = _.filter(tempTaskData?.taskList, {
    type: TaskType.StopwatchGarminStream,
  })
  const hasGarminStreamTasks: boolean = !!garminStreamTasks.length || !!tempGarminStreamTasks.length
  const [garminStreamTaskIdList, setGarminStreamTaskIdList] = useState<string[]>([])

  const [garminDeviceSpecCopy, setGarminDeviceSpecCopy] = useState<GarminDeviceSpecCopy>(
    isEditingLiveProject
      ? parseGarminDeviceSpec(tempGarminDeviceTaskDetail)
      : parseGarminDeviceSpec(garminDeviceTaskDetail),
  )

  // whole section include garmin device and garmin stream
  const [garminDeviceSectionEnable, setGarminDeviceSectionEnable] = useState<boolean>(
    garminDeviceEnable || garminStreamEnable || false,
  )

  useEffect(() => {
    if (!garminDeviceSpecCopy) return

    if (!garminDeviceSectionEnable) {
      setGarminDeviceEnable(false)
      setGarminStreamEnable(false)
      return
    }

    const enabledDatatypes = _.filter(garminDeviceSpecCopy, (item) => {
      return typeof item === 'boolean' && item === true
    }).length
    if (enabledDatatypes === 0) setGarminDeviceEnable(false)
    if (enabledDatatypes > 0 && garminDeviceSectionEnable) setGarminDeviceEnable(true)
  }, [garminDeviceSpecCopy, garminDeviceSectionEnable])

  useEffect(() => {
    if (garminStreamTasks.length) setGarminStreamTaskIdList(garminStreamTasks.map((task) => task.id))
    if (tempGarminStreamTasks.length) setGarminStreamTaskIdList(tempGarminStreamTasks.map((task) => task.id))
  }, [garminStreamTasks.length, tempGarminStreamTasks.length])

  /* -------------------- other/common -------------------- */
  const updateGarminDevicePage = () => {
    if (!garminDeviceSpecCopy) return
    const updatePageType =
      !garminDeviceSectionEnable || !garminDeviceEnable
        ? 'remove_garmin_task'
        : garminDeviceSectionEnable && garminDeviceEnable && garminDeviceTask
        ? 'update_garmin_task'
        : garminDeviceSectionEnable && garminDeviceEnable && !garminDeviceTask
        ? 'create_garmin_task'
        : null

    if (updatePageType === 'create_garmin_task') {
      doREQUEST_TASK_GARMIN_DEVICE_CREATE({
        payload: {
          methodId,
          garminDevice: garminDeviceSpecCopy,
        },
      })
    }
    if (updatePageType === 'update_garmin_task') {
      doREQUEST_TASK_GARMIN_DEVICE_UPDATE({
        setRequestResult: setRequestResultOfGarminUpdate,
        payload: {
          taskId: garminDeviceTaskIdFromMethod,
          garminDevice: garminDeviceSpecCopy,
        },
      })
    }
    if (updatePageType === 'remove_garmin_task') {
      doREQUEST_TASK_DELETE({
        payload: {
          taskId: garminDeviceTaskIdFromMethod,
        },
      })
    }
    if (garminStreamEnable && !hasGarminStreamTasks) {
      setDisplayInstructionPop(TaskType.StopwatchGarminStream)
    } else if (!garminStreamEnable) {
      if (hasGarminStreamTasks) {
        garminStreamTaskIdList.map((taskId) => {
          doREQUEST_TASK_DELETE({
            payload: {
              taskId: taskId,
            },
          })
        })
      }
    }

    doREQUEST_METHOD_UPDATE({
      setRequestResult: setRequestResultOfMethodUpdate,
      payload: {
        methodId,
        garminConnectEnable,
        garminDeviceEnable:
          updatePageType === 'create_garmin_task'
            ? true
            : updatePageType === 'remove_garmin_task'
            ? false
            : garminDeviceEnable,
        garminStreamEnable,
      },
    })
  }

  const isLiveTask = (taskId?: string) => {
    return isEditingLiveProject && methodState?.taskList.some((t) => t.id === taskId)
  }

  const updateTempGarminDevicePage = () => {
    const updateTempPageType =
      !garminDeviceSectionEnable || !garminDeviceEnable
        ? 'remove_garmin_task'
        : garminDeviceSectionEnable && garminDeviceEnable && tempGarminDeviceTask
        ? 'update_garmin_task'
        : garminDeviceSectionEnable && garminDeviceEnable && !tempGarminDeviceTask
        ? 'create_garmin_task'
        : null

    const tempTask = {
      id: updateTempPageType === 'create_garmin_task' ? t.uuid() : tempGarminDeviceTask?.id,
      workspaceId,
      projectId,
      methodId,
      actionType: updateTempPageType === 'remove_garmin_task' ? 'hide' : garminDeviceTask ? 'update' : 'create',
      enabled: true,
      scheduleRepeat: true,
      scheduleDescription: 'all the time',
      type: TaskType.GarminDevice,
      garminDevice:
        updateTempPageType === 'remove_garmin_task'
          ? {
              ...garminDeviceSpecCopy,
              heartRateEnable: false,
              stressEnable: false,
              stepsEnable: false,
              respirationEnable: false,
              pulseOxEnable: false,
              bbiEnable: false,
              zeroCrossingEnable: false,
            }
          : garminDeviceSpecCopy,
    }

    if (updateTempPageType === 'create_garmin_task') {
      doTEMP_TASK_DATA_ADD_TASK(tempTask)
    }
    if (updateTempPageType === 'update_garmin_task') {
      doTEMP_TASK_DATA_UPDATE_TASK(tempTask)
    }
    if (updateTempPageType === 'remove_garmin_task' && !!tempGarminDeviceTask?.id) {
      // task will remove in project_confirm_update_pop
      doTEMP_TASK_DATA_UPDATE_TASK(tempTask)
    }

    if (garminStreamEnable && !hasGarminStreamTasks) {
      setDisplayInstructionPop(TaskType.StopwatchGarminStream)
    } else if (!garminStreamEnable) {
      if (hasGarminStreamTasks) {
        garminStreamTaskIdList.map((taskId) => {
          const garminStreamTask = _.find(tempGarminStreamTasks, {id: taskId})
          if (isLiveTask(garminStreamTask?.id)) {
            doTEMP_TASK_DATA_UPDATE_TASK({
              ...garminStreamTask,
              enabled: false,
              actionType: 'hide',
            })
          } else {
            doTEMP_TASK_DATA_DELETE_TASK({taskId: garminStreamTask?.id})
          }
        })
      }
    }
    doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE({garminDeviceEnable})
    doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE({garminConnectEnable})
    doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE({garminStreamEnable})
  }

  const handleClickSave = () => {
    if (!garminConnectEnable && !garminDeviceSectionEnable) return closeAction(false)
    if (isEditingLiveProject) updateTempGarminDevicePage()
    if (!isEditingLiveProject) updateGarminDevicePage()
    closeAction(false)
  }

  useEffect(() => {
    if (requestResultOfMethodUpdate?.success && requestResultOfGarminUpdate?.success) {
      closeAction(false)
    }
  }, [requestResultOfMethodUpdate, requestResultOfGarminUpdate])

  const handleRemoveGarminStreamTask = () => {
    if (isEditingLiveProject) {
      garminStreamTaskIdList.map((taskId) => {
        const garminStreamTask = _.find(tempGarminStreamTasks, {id: taskId})
        if (isLiveTask(garminStreamTask?.id)) {
          doTEMP_TASK_DATA_UPDATE_TASK({
            ...garminStreamTask,
            enabled: false,
            actionType: 'hide',
          })
        } else {
          doTEMP_TASK_DATA_DELETE_TASK({taskId: garminStreamTask?.id})
        }
      })
    } else {
      garminStreamTaskIdList.map((taskId) => {
        doREQUEST_TASK_DELETE({
          payload: {
            taskId: taskId,
          },
        })
      })
    }
  }

  const handleRemoveGarminDeviceTask = () => {
    if (isEditingLiveProject) {
      if (isLiveTask(tempGarminDeviceTask?.id)) {
        doTEMP_TASK_DATA_UPDATE_TASK({
          ...tempGarminDeviceTask,
          enabled: false,
          actionType: 'hide',
        })
      } else {
        doTEMP_TASK_DATA_DELETE_TASK({taskId: tempGarminDeviceTask?.id})
      }
    } else {
      doREQUEST_TASK_DELETE({
        payload: {
          taskId: garminDeviceTaskIdFromMethod,
        },
      })
    }
  }

  const handleRemoveDevice = () => {
    if (hasGarminStreamTasks) handleRemoveGarminStreamTask()
    if (garminDeviceTask || tempGarminDeviceTask) handleRemoveGarminDeviceTask()
    if (isEditingLiveProject) {
      doTEMP_TASK_DATA_GARMIN_DEVICE_ENABLE_UPDATE({garminDeviceEnable: false})
      doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE({garminConnectEnable: false})
      doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE({garminStreamEnable: false})
      closeAction(false)
    } else {
      doREQUEST_METHOD_UPDATE({
        setRequestResult: setRequestResultOfMethodUpdate,
        payload: {
          methodId,
          garminDeviceEnable: false,
          garminConnectEnable: false,
          garminStreamEnable: false,
        },
      })
    }
  }

  useEffect(() => {
    if (!requestResultOfMethodUpdate?.success) return
    closeAction(false)
  }, [requestResultOfMethodUpdate])

  return (
    <div>
      <SaveBar
        {...{
          handleClickSave,
          closeAction,
          disabled: !garminConnectEnable && !garminDeviceEnable && !garminStreamEnable,
          html: RIF(
            isEditingLiveProject && methodState?.garminStreamEnable
              ? false
              : garminDeviceSectionEnable || garminConnectEnable,
            <div
              onClick={() => setDisplayRemovePopup(true)}
              css={{
                marginRight: '20px',
                color: color.warning,
                cursor: 'pointer',
              }}
            >
              Remove Device
            </div>,
          ),
        }}
      />

      {RIF(
        displayRemovePopup,
        <DeviceRemovePopup
          {...{
            closeAction: setDisplayRemovePopup,
            doAction: handleRemoveDevice,
            removeType: 'garmin_device',
          }}
        />,
      )}

      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '1440px',
          margin: '0 auto',
          '@media (max-width: 1441px)': {
            paddingLeft: '40px',
          },
        }}
      >
        <div css={{width: '70%'}}>
          {/* top description */}
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingRight: '24px',
              borderBottom: `1px solid ${color.grey_160}`,
            }}
          >
            <div>
              <div css={{fontWeight: '700', fontSize: '24px', marginBottom: '16px'}}>Garmin Devices</div>
              <div css={{lineHeight: '18px', fontSize: '14px', marginBottom: '24px', width: '778px'}}>
                You have the option of collecting standard Garmin data through the Garmin Connect app, and/or using the
                Labfront Companion app to pull data directly from the watches themselves and alter sampling rates for
                more granular data and additional metrics such as Heart Rate Interval (BBI) data.{' '}
              </div>
              <div css={{marginBottom: '40px', display: 'flex', flexWrap: 'wrap'}}>
                <a
                  href='https://help.labfront.com/participant-experience-researcher-guide'
                  target='_blank'
                  css={{display: 'flex', alignItems: 'center', marginRight: '24px', marginBottom: '8px'}}
                >
                  <p
                    css={{
                      color: color.grey_600,
                      marginRight: '6px',
                      textDecoration: 'underline',
                      ':hover': {color: color.black},
                    }}
                  >
                    How does each source impact my participant experience?
                  </p>
                  <img src={LinkToIcon} width={12} height={12} />
                </a>
                <a
                  href='https://help.labfront.com/how-data-is-organized'
                  target='_blank'
                  css={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}
                >
                  <p
                    css={{
                      color: color.grey_600,
                      marginRight: '6px',
                      textDecoration: 'underline',
                      ':hover': {color: color.black},
                    }}
                  >
                    What does the data download look like?
                  </p>
                  <img src={LinkToIcon} width={12} height={12} />
                </a>
                <a
                  href='https://help.labfront.com/data-documentation'
                  target='_blank'
                  css={{display: 'flex', alignItems: 'center', marginRight: '24px'}}
                >
                  <p
                    css={{
                      color: color.grey_600,
                      marginRight: '6px',
                      textDecoration: 'underline',
                      ':hover': {color: color.black},
                    }}
                  >
                    What does each data type mean?
                  </p>
                  <img src={LinkToIcon} width={12} height={12} />
                </a>
                <a
                  href='https://airtable.com/shr7FqXL9I6jA3REi/tblRZKMryLtnnKm4x?backgroundColor=blue&viewControls=on'
                  target='_blank'
                  css={{display: 'flex', alignItems: 'center'}}
                >
                  <p
                    css={{
                      color: color.grey_600,
                      marginRight: '6px',
                      textDecoration: 'underline',
                      ':hover': {color: color.black},
                    }}
                  >
                    Which data types are supported by each compatible device?
                  </p>
                  <img src={LinkToIcon} width={12} height={12} />
                </a>
              </div>
            </div>
            <img src={GarminDeviceIcon} width={132} height={132} />
          </div>

          <GarminSpecPanel
            {...{
              garminDeviceSpecCopy,
              setGarminDeviceSpecCopy,
              garminDeviceSectionEnable,
              setGarminDeviceSectionEnable,
              garminConnectEnable,
              setGarminConnectEnable,
              garminStreamEnable,
              setGarminStreamEnable,
              setDisplayChangePlanPage,
            }}
          />
        </div>

        <GarminSidebar {...{garminConnectEnable, garminStreamEnable, garminDeviceSectionEnable}} />
      </div>
    </div>
  )
}
