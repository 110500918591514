import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {ContentListItem, QuestionnaireTempQuestion} from '../../model'
import {selectTheme, AcceptableMIMETypeSet, createDispatchActions} from '../../store'
import {SettingIcon} from '../../asset/image'
import {CreateDoRequestActionInput, RequestResult, RIF, useClickOutside} from '../../engine'
import {PopupSize, PopupTemplate, Input, LabelBox} from '..'
import {ChangeEvent} from 'mongodb'
import axios from 'axios'

export const QuestionnaireMediaDropdown = (props: {
  question: QuestionnaireTempQuestion
  questionnaireContentList: ContentListItem[]
  setQuestionnaireContentList: Dispatch<SetStateAction<ContentListItem[]>>
  // addPendingMedia: (i: PendingMedia) => void
  index: number
}) => {
  const dropdownRef = useRef(null)
  const {doREQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE}: Record<string, (i: CreateDoRequestActionInput) => void> =
    createDispatchActions()
  const {color, fontSize, fontWeight} = selectTheme()
  const {question, questionnaireContentList, setQuestionnaireContentList, index} = props
  const [renderYoutubeUrlPopup, setRenderYoutubeUrlPopup] = useState(false)
  const [renderDropdown, setRenderDropdown] = useState(false)
  const [youtubeUrl, setYoutubeUrl] = useState('')
  // const [mediaFile, setMediaFile] = useState<File | null>(null)
  const addYoutubeUrl = () => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: {...question, mediaUrl: youtubeUrl}})
    setQuestionnaireContentList(newContentList)
    setRenderDropdown(false)
    setRenderYoutubeUrlPopup(false)
  }
  const addImageUrl = (mediaUrl: string) => {
    const newContentList = [...questionnaireContentList]
    newContentList.splice(index, 1, {question: {...question, mediaUrl}})
    setQuestionnaireContentList(newContentList)
    setRenderDropdown(false)
  }
  useClickOutside(dropdownRef, () => setRenderDropdown(false))
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    setImageFile(file)
    doREQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE({
      setRequestResult,
      payload: {
        imageMimeType: file.type.split('/')[1],
      },
    })
  }
  useEffect(() => {
    if (!requestResult || !requestResult.success) return
    const {uploadUrl, imageUrl} = requestResult.result.payload
    uploadFile(uploadUrl, imageUrl)
  }, [requestResult])

  const uploadFile = async (uploadUrl: string, imageUrl: string) => {
    if (!imageFile) return
    try {
      await axios.put(uploadUrl, imageFile, {
        headers: {
          'Content-Type': imageFile.type,
        },
      })
      addImageUrl(imageUrl)
      return
    } catch (error) {
      return
    }
  }
  const acceptableMIMETypeSet = AcceptableMIMETypeSet.map((type: string) => `image/${type}`).join(', ')
  return (
    <div css={{position: 'relative'}}>
      {RIF(
        renderYoutubeUrlPopup,
        <PopupTemplate
          {...{
            title: 'Add Youtube Video',
            description: '',
            background: true,
            popupSize: PopupSize.Medium,
            handleClosePopup: () => setRenderYoutubeUrlPopup(false),
            mainFunction: addYoutubeUrl,
            buttonContent: 'Done',
            buttonDisabled: youtubeUrl.length === 0,
          }}
        >
          <LabelBox
            {...{
              label: 'Youtube URL',
              leftCharacters: 250 - youtubeUrl.length,
            }}
          >
            <Input
              {...{
                value: youtubeUrl,
                onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => setYoutubeUrl(value),
                maxLength: 250,
                autoFocus: true,
              }}
            />
          </LabelBox>
        </PopupTemplate>,
      )}
      <button
        onClick={() => setRenderDropdown(!renderDropdown)}
        css={{
          width: '24px',
          height: '24px',
          minWidth: '24px',
          backgroundImage: `url(${SettingIcon})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
        }}
      />
      {RIF(
        renderDropdown,
        <div
          ref={dropdownRef}
          css={{
            width: '216px',
            minWidth: '216px',
            zIndex: 103,
            padding: '8px 0',
            display: 'flex',
            flexDirection: 'column',
            gap: '1px',
            position: 'absolute',
            top: '24px',
            borderRadius: '5px',
            backgroundColor: color.surface.white,
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          <label
            css={{
              width: '100%',
              padding: '8px 12px',
              fontSize: fontSize.h7,
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: color.surface.default,
              },
            }}
          >
            <input css={{display: 'none'}} type='file' accept={acceptableMIMETypeSet} onChange={handleChangeFile} />
            Add Image
          </label>
          <button
            onClick={() => setRenderYoutubeUrlPopup(true)}
            css={{
              width: '100%',
              padding: '8px 12px',
              fontSize: fontSize.h7,
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: color.surface.default,
              },
            }}
          >
            Add Youtube Video
          </button>
        </div>,
      )}
    </div>
  )
}
