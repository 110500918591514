import {useEffect, Dispatch, SetStateAction} from 'react'
import {Controller} from 'react-hook-form'
import {selectTheme} from '../../store'
import {v, useForm, useCurrentWorkspaceAccountPlan, RIF, _} from '../../lib'
import {Label, RequiredTag, Select} from '..'
import {components, OptionProps} from 'react-select'
import {TempStopwatchGarminStream} from '../../model'
import {MovesenseECGSampleRate} from '../../shared/db'

const schema = v.object({
  accHz: v.number().valid(25, 50, 100).optional().allow(null),
  timeLimitSeconds: v.number().valid(300, 600, 900, 1800, 3600, 10800, 21600, 43200).optional().allow(null),
})

export interface AddTaskGarminStreamSectionPropsT {
  garminStreamTaskCopy: TempStopwatchGarminStream
  setGarminStreamTaskCopy: Dispatch<SetStateAction<TempStopwatchGarminStream>>
  garminStreamReadOnly: boolean
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

export const AddTaskGarminStreamSection = (props: AddTaskGarminStreamSectionPropsT) => {
  const {color, fontSize} = selectTheme()
  const {garminStreamTaskCopy, setGarminStreamTaskCopy, garminStreamReadOnly, setDisplayChangePlanPage} = props
  const {watch, control} = useForm({schema})

  // const {accEnable, accHz} = garminStreamTaskCopy
  const {accEnable, accHz, timeLimitSeconds} = garminStreamTaskCopy
  const {hasActivePlanPurchase} = useCurrentWorkspaceAccountPlan()

  const HzOptions = [
    {value: 25, label: '25 Hz'},
    {value: 50, label: '50 Hz', isDisabled: !hasActivePlanPurchase},
    {value: 100, label: '100 Hz', isDisabled: !hasActivePlanPurchase},
  ]

  const timeLimitSecondsOptions = [
    {value: 300, label: '5 min'},
    {value: 600, label: '10 min'},
    {value: 900, label: '15 min'},
    {value: 1800, label: '30 min', isDisabled: !hasActivePlanPurchase},
    {value: 3600, label: '1 hr', isDisabled: !hasActivePlanPurchase},
    {value: 10800, label: '3 hr', isDisabled: !hasActivePlanPurchase},
    {value: 21600, label: '6 hr', isDisabled: !hasActivePlanPurchase},
    {value: 43200, label: '12 hr', isDisabled: !hasActivePlanPurchase},
  ]

  const convertToOptionsForm = (num: number) => ({value: num, label: `${num} Hz`})
  const convertTaskMaxLengthValueToOptionFormat = (value: number | undefined) => {
    const option = timeLimitSecondsOptions.find((option) => option.value === value)
    return {value: value ?? 300, label: option?.label ?? '5 min'}
  }

  const Option = (props: OptionProps) => {
    return (
      <components.Option
        {...props}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {props.label}
        {RIF(
          props.isDisabled,
          <div
            onClick={() => {
              if (props.isDisabled) {
                setDisplayChangePlanPage(true)
              }
            }}
            css={{
              borderRadius: '2px',
              color: 'white',
              padding: '4px 6px',
              background: color.upgrade,
              fontSize: fontSize.h8,
              cursor: 'pointer',
            }}
          >
            Upgrade
          </div>,
        )}
      </components.Option>
    )
  }

  useEffect(() => {
    setGarminStreamTaskCopy((prevState) => ({
      ...prevState,
      accHz: watch('accHz')?.value,
      timeLimitSeconds: watch('timeLimitSeconds')?.value,
    }))
  }, [watch('accHz'), watch('timeLimitSeconds')])

  return (
    <div
      data-testid='add_task_garmin_section'
      css={{
        marginBottom: '40px',
        borderTop: `1px solid ${color.grey_100}`,
        paddingTop: '35px',
      }}
    >
      <div css={{display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '10px'}}>
        <Label css={{margin: 0}}>ACC Streaming Sampling Rate</Label>
        <RequiredTag />
      </div>
      <div>
        Select the resolution you need for your data collection. Note: The higher the resolution, the more likely the
        chance to drop data packets.
      </div>

      <Controller
        control={control}
        defaultValue={convertToOptionsForm(accHz ?? MovesenseECGSampleRate.HZ_125)}
        name='accHz'
        render={({field: {name, value, onChange}}) => (
          <Select
            {...{
              name,
              value,
              onChange,
            }}
            isDisabled={!accEnable || garminStreamReadOnly}
            options={HzOptions}
            components={{Option}}
            css={{width: '130px', minWidth: '104px', marginTop: '16px'}}
          />
        )}
      />

      <div
        css={{
          borderTop: `1px solid ${color.grey_100}`,
          paddingTop: '35px',
          marginTop: '35px',
          marginBottom: '40px',
        }}
        data-testid='task_max_length_section'
      >
        <div css={{display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '10px'}}>
          <Label css={{margin: 0}}>Task Max Length</Label>
          <RequiredTag />
        </div>
        <div
          css={{
            color: color.grey_600,
            marginBottom: '20px',
          }}
        >
          In order to optimize battery usage, data storage, and participant experience, please specify the amount of
          time required. If the participant does not stop the task beforehand, the task will automatically stop after
          the specified time.
        </div>
        <Controller
          control={control}
          defaultValue={convertTaskMaxLengthValueToOptionFormat(timeLimitSeconds)}
          name='timeLimitSeconds'
          render={({field: {name, value, onChange}}) => (
            <Select
              {...{
                name,
                value,
                onChange,
              }}
              menuPlacement='top'
              options={timeLimitSecondsOptions}
              components={{Option}}
              css={{width: '130px', minWidth: '104px', marginLeft: '5px'}}
            />
          )}
        />
      </div>
    </div>
  )
}
