import {useState} from 'react'
import {darken, RIF, t} from '../../lib'

import {RequiredTag} from '../atoms'
import {selectTheme} from '../../store'
import {TempTaskSchedule} from '../../model/schedule'

import {
  TaskScheduleEditPop,
  TaskScheduleEditPopProps,
  TaskScheduleDeletePop,
  TaskScheduleDeletePopProps,
} from '../molecules'
import {differenceInMilliseconds} from 'date-fns'
import {AddIcon} from '../../lib/svg/icon/add'
import {CalendarIcon} from '../../lib/svg/icon/calendar'
import {TaskScheduleCard} from '../molecules/task_schedule_card'

export interface TaskScheduleSectionProps {
  scheduleList: TempTaskSchedule[]
  isViewOnly?: boolean
  onRequestScheduleCreate: (schedule: TempTaskSchedule) => void
  onRequestScheduleUpdate: (schedule: TempTaskSchedule) => void
  onRequestScheduleDelete: (schedule: TempTaskSchedule) => void
}

type ShowingPop =
  | {
      action: 'create'
      props: TaskScheduleEditPopProps
    }
  | {
      action: 'update'
      props: TaskScheduleEditPopProps
    }
  | {
      action: 'delete'
      props: TaskScheduleDeletePopProps
    }

export const TaskScheduleSection = (props: TaskScheduleSectionProps) => {
  const {scheduleList, isViewOnly, onRequestScheduleCreate, onRequestScheduleUpdate, onRequestScheduleDelete} = props

  const {color, fontSize, fontWeight} = selectTheme()
  const [showingPop, setShowingPop] = useState<ShowingPop | null>(null)

  const onClickCreateSchedule = () =>
    setShowingPop({
      action: 'create',
      props: {
        schedule: {
          tempId: t.uuid(),
          createdAt: new Date(),
          values: {},
        },
        onClickSave: (schedule) => {
          onRequestScheduleCreate(schedule)
          setShowingPop(null)
        },
        onClickCancel: () => setShowingPop(null),
      },
    })

  const header = () => (
    <div
      css={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'fit-content',
      }}
    >
      <div>
        <div css={{display: 'flex', alignItems: 'center', marginBottom: '8px', gap: '8px'}}>
          <CalendarIcon width={20} height={20} />
          <p css={{fontWeight: '500'}}>Task Schedule</p>
          <RequiredTag />
        </div>
        <p css={{fontSize: fontSize.h6, fontWeight: fontWeight.medium, color: color.textIcon.secondary}}>
          Define when tasks will be sent to participants. Choose a fixed time or a random schedule, and participants
          will receive the tasks on their phone accordingly. You can add multiple schedules if needed.
        </p>
      </div>
    </div>
  )

  const createButton = () => (
    <button
      type='button'
      onClick={() => onClickCreateSchedule()}
      disabled={isViewOnly}
      css={{
        padding: '8px 16px',
        borderRadius: 4,
        width: 'fit-content',
        border: 'none',
        backgroundColor: isViewOnly ? color.state.disabled : color.state.default,
        cursor: isViewOnly ? 'not-allowed' : 'pointer',
        '&:hover': {
          backgroundColor: isViewOnly ? color.state.disabled : darken(color.state.default, 10),
        },
        '&:active': {
          backgroundColor: isViewOnly ? color.state.disabled : darken(color.state.default, 20),
        },
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <div css={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 8}}>
        <AddIcon />
        <span css={{color: color.white, fontSize: fontSize.h6, fontWeight: fontWeight.thick}}>Add Schedule</span>
      </div>
    </button>
  )

  const pop = () =>
    RIF(showingPop, ({action, props}) => {
      switch (action) {
        case 'create':
          return <TaskScheduleEditPop {...props} />
        case 'update':
          return <TaskScheduleEditPop {...props} />
        case 'delete':
          return <TaskScheduleDeletePop {...props} />
      }
    })

  return (
    <div
      data-testid='task_schedule_section'
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        marginBottom: 80,
        paddingTop: 40,
        borderTop: `1px solid ${color.grey_100}`,
      }}
    >
      {header()}
      {isViewOnly || createButton()}
      {scheduleList
        .sort((a, b) => differenceInMilliseconds(b.createdAt, a.createdAt))
        .map((schedule) => (
          <TaskScheduleCard
            style={{
              backgroundColor: color.white,
              cursor: isViewOnly ? 'default' : 'pointer',
            }}
            key={schedule.tempId}
            schedule={schedule.values}
            isViewOnly={isViewOnly}
            onClickSchedule={() => {
              setShowingPop({
                action: 'update',
                props: {
                  schedule,
                  isEdit: true,
                  onClickSave: (schedule) => {
                    onRequestScheduleUpdate(schedule)
                    setShowingPop(null)
                  },
                  onClickCancel: () => setShowingPop(null),
                },
              })
            }}
            onClickDeleteSchedule={() => {
              setShowingPop({
                action: 'delete',
                props: {
                  schedule,
                  onClickConfirm: (schedule) => {
                    onRequestScheduleDelete(schedule)
                    setShowingPop(null)
                  },
                  onClickCancel: () => setShowingPop(null),
                },
              })
            }}
          />
        ))}
      {pop()}
    </div>
  )
}
