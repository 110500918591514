import {PopupSimple} from '..'

export interface ProjectSimpleDeletePopPropsT {
  projectName: string
  onClickDelete: () => void
  onClickClose: () => void
}

export const ProjectSimpleDeletePop = (props: ProjectSimpleDeletePopPropsT) => {
  const {projectName, onClickDelete, onClickClose, ...rest} = props

  return (
    <div
      onClick={(e) => e.preventDefault()}
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
      }}
    >
      <PopupSimple
        {...rest}
        {...{
          method: 'Delete',
          name: 'Project',
          description: `Are you sure you want to delete project named ${projectName}?`,
          buttonText: 'Delete Project',
          buttonType: 'reverse',
          buttonColor: 'warning',
          closeAction: () => onClickClose(),
          doAction: () => onClickDelete(),
        }}
      />
    </div>
  )
}
