import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'
import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import {getToken} from './token_fetcher'

export const REQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE = 'REQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE'
export const doREQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE = createDoRequestAction(
  REQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE,
)

export const requestQuestionnaireImageUploadUrlGenerateActionCreators = {
  doREQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE,
}

export const AcceptableMIMETypeSet = ['gif', 'png', 'jpeg', 'tiff', 'heic', 'heif']

export function* requestQuestionnaireImageUploadUrlGenerate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestQuestionnaireImageUploadUrlGenerate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        imageMimeType: v
          .string()
          .valid(...AcceptableMIMETypeSet)
          .exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {imageMimeType} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/questionnaire-image-upload-url-generate`,
    accessToken,
    data: {
      imageMimeType,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/questionnaire-image-upload-url-generate',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* questionnaireImageUploadUrlGenerateSaga() {
  yield takeEvery(REQUEST_QUESTIONNAIRE_IMAGE_UPLOAD_URL_GENERATE, requestQuestionnaireImageUploadUrlGenerate)
}
