import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {Controller} from 'react-hook-form'
import {selectTheme, selectWorkspaces} from '../../store'
import {v, useForm, RIF, useCurrentProjectState, useCurrentWorkspaceState} from '../../lib'
import {AboutProjectIcon} from '../../asset/image'
import {Input, LabelBox, Textarea} from '..'

export interface AboutProjectBlockPropsT {
  setAboutProjectValueFilled: Dispatch<SetStateAction<boolean>>
  setAboutProjectValue: Dispatch<
    SetStateAction<Record<'name' | 'description' | 'investigator' | 'organization', string>>
  >
  aboutProjectValue: Record<'name' | 'description' | 'investigator' | 'organization', string>
}

export const AboutProjectBlock = (props: AboutProjectBlockPropsT) => {
  const {pad, color, fontWeight} = selectTheme()
  const workspacesState = selectWorkspaces()
  const {workspaceId} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  const enum WordCountMaximum {
    projectName = 50,
    projectInvestigator = 50,
    organization = 50,
    introduction = 250,
  }

  const {setAboutProjectValueFilled, setAboutProjectValue, aboutProjectValue} = props

  const schema = v.object({
    name: v.string().max(50).required(),
    investigator: v.string().max(50).required(),
    organization: v.string().max(50).required(),
    description: v.string().max(250).required(),
  })
  const {control, watch} = useForm({schema})

  // toggle the left checkIcon color according to input field completed
  useEffect(() => {
    const valueList = Object.values(aboutProjectValue)
    setAboutProjectValueFilled(valueList.length === 4 && valueList.every((v) => v.length > 0))
  }, [aboutProjectValue])

  useEffect(() => {
    setAboutProjectValue({
      ...aboutProjectValue,
      name: watch('name'),
    })
  }, [watch('name')])

  useEffect(() => {
    setAboutProjectValue({
      ...aboutProjectValue,
      investigator: watch('investigator'),
    })
  }, [watch('investigator')])

  useEffect(() => {
    setAboutProjectValue({
      ...aboutProjectValue,
      organization: watch('organization'),
    })
  }, [watch('organization')])

  useEffect(() => {
    setAboutProjectValue({
      ...aboutProjectValue,
      description: watch('description'),
    })
  }, [watch('description')])

  return (
    <div
      css={{
        display: 'flex',
        background: color.white,
        borderRadius: '5px;',
        width: '55vw',
        marginBottom: pad.large,
        boxShadow: '0px 4px 12px 0px #D4D4D440',
      }}
    >
      {/* left */}
      <div
        css={{
          padding: pad.large,
          borderRight: `1px solid ${color.grey_100}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '26%',
        }}
      >
        <div>
          <div
            css={{
              width: '40px',
              height: '40px',
              background: color.background,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: pad.large,
            }}
          >
            <img src={AboutProjectIcon} width='24px' />
          </div>
          <p
            css={{
              fontSize: '18px',
              fontWeight: fontWeight.thick,
              marginBottom: pad.medium,
            }}
          >
            About Project
          </p>

          <p css={{lineHeight: '19px', fontWeight: fontWeight.regular}}>
            Provide more details about your project for participants.
          </p>
        </div>
      </div>

      {/* right */}
      <div css={{padding: pad.xl, width: '74%'}}>
        <p
          css={{
            fontSize: '14px',
            fontWeight: fontWeight.medium,
            marginBottom: pad.xl,
          }}
        >
          Project information as seen by participants:
        </p>

        {/* projectName */}
        <LabelBox
          {...{
            label: 'Project Name',
            required: true,
            leftCharacters: WordCountMaximum.projectName - aboutProjectValue?.name.length,
          }}
        >
          <>
            <Controller
              control={control}
              name='name'
              render={({field: {name, value, onChange}}) => (
                <Input
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.name,
                    onChange: onChange,
                    // onInput: (e: any) => handleWordCount(e, 'projectName', setProjectNameWordCount),
                  }}
                  maxLength='50'
                  placeholder='Labfront Sleep Study'
                  css={{marginBottom: '27px'}}
                />
              )}
            />
          </>
        </LabelBox>

        {/* Investigator */}
        <LabelBox
          {...{
            label: 'Principal Investigator',
            required: true,
            leftCharacters: WordCountMaximum.projectInvestigator - aboutProjectValue?.investigator.length,
          }}
        >
          <>
            <Controller
              control={control}
              name='investigator'
              render={({field: {name, value, onChange}}) => (
                <Input
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.investigator,
                    onChange: onChange,
                    // onInput: (e: any) => handleWordCount(e, 'projectInvestigator', setProjectInvestigatorWordCount),
                  }}
                  maxLength='50'
                  placeholder='Dr. Luna Shiba'
                  css={{marginBottom: '27px'}}
                />
              )}
            />
          </>
        </LabelBox>

        {/* Organization */}
        <LabelBox
          {...{
            label: 'Organization or Institution',
            required: true,
            leftCharacters: WordCountMaximum.organization - aboutProjectValue?.organization.length,
          }}
        >
          <>
            <Controller
              control={control}
              name='organization'
              render={({field: {name, value, onChange}}) => (
                <Input
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.organization,
                    onChange: onChange,
                    // onInput: (e: any) => handleWordCount(e, 'organization', setOrganizationWordCount),
                  }}
                  maxLength='50'
                  placeholder='Labfront'
                  css={{marginBottom: '27px'}}
                />
              )}
            />
          </>
        </LabelBox>

        {/* Introduction */}
        <LabelBox
          {...{
            label: 'Introduction',
            required: true,
            leftCharacters: WordCountMaximum.introduction - aboutProjectValue?.description.length,
          }}
        >
          <>
            <Controller
              control={control}
              name='description'
              render={({field: {name, value, onChange}}) => (
                <Textarea
                  readOnly={role === 'viewer'}
                  {...{
                    name: name,
                    value: aboutProjectValue?.description,
                    onChange: onChange,
                    // onInput: (e) => handleWordCount(e, 'introduction', setIntroductionWordCount),
                  }}
                  maxLength={250}
                  placeholder='Briefly introduce your study here.'
                  rows={4}
                />
              )}
            />
          </>
        </LabelBox>
      </div>
    </div>
  )
}
