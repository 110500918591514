import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {
  doAUTH_SET,
  doSETTINGS_SET,
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doPROJECT_SETTINGS_LIST_SET,
  doFAVORITE_LIST_SET,
  doIDENTITY_SET,
} from '../state'

export const EMAIL_VERIFY_SUBMIT = 'EMAIL_VERIFY_SUBMIT'
export const doEMAIL_VERIFY_SUBMIT = createDoRequestAction(EMAIL_VERIFY_SUBMIT)

export const emailVerifySubmitActionCreators = {
  doEMAIL_VERIFY_SUBMIT,
}

export function* emailVerifySubmit({payload, setRequestResult}: any): SagaIterator {
  debug('saga*emailVerifySubmit')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        email: v.string().exist(),
        verificationCode: v.number().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: EMAIL_VERIFY_SUBMIT,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {verificationCode, email} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-email-verify-submit`,
    data: {
      email,
      verificationCode,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: EMAIL_VERIFY_SUBMIT,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/identity-email-verify-submit',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {
    settings,
    projectSettingsList,
    workspaceFavoriteList,
    accessToken,
    refreshToken,
    accessTokenExpiresInSeconds,
    refreshTokenExpiresInSeconds,
    identity,
  } = result.payload

  yield put(
    doAUTH_SET({
      accessToken,
      refreshToken,
      accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds,
    }),
  )

  yield put(doSETTINGS_SET(settings))

  yield put(doPROJECT_SETTINGS_LIST_SET(projectSettingsList))

  yield put(doFAVORITE_LIST_SET(workspaceFavoriteList))

  yield put(doIDENTITY_SET(identity))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: EMAIL_VERIFY_SUBMIT,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* emailVerifySubmitSaga() {
  yield takeEvery(EMAIL_VERIFY_SUBMIT, emailVerifySubmit)
}
