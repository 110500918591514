import {
  useState,
  useEffect,
  forwardRef,
  Ref,
  ChangeEvent,
  CSSProperties,
  ChangeEventHandler,
  KeyboardEventHandler,
} from 'react'
import {calculateInputCSS} from '../../lib/calculate_input_css'

export const Textarea = forwardRef(
  (
    props: {
      onChange: (e: ChangeEvent<HTMLTextAreaElement>, value: string) => void
      value: string
      clearOnFinish?: boolean
      rows?: number
      readOnly?: boolean
      placeholder?: string
      maxLength?: number
      style?: CSSProperties
      hasError?: boolean
      onFinish?: (value: string) => void
      onInput?: React.FormEventHandler<HTMLTextAreaElement>
    },
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    const [value, setValue] = useState(props.value || '')
    const sanitizedProps = {...props}
    delete sanitizedProps.hasError

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      setValue(e.target.value)
      if (props.onChange) props.onChange(e, e.target.value)
    }

    const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
      if (e.key === 'Enter') {
        props.onFinish?.(value)
        if (props.clearOnFinish) {
          setValue('')
        }
      }
    }

    useEffect(() => {
      if (props.value != value) setValue(props.value)
    }, [props.value])

    return (
      <textarea
        {...sanitizedProps}
        ref={ref}
        css={{...calculateInputCSS(props), ...sanitizedProps.style}}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    )
  },
)
