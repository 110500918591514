import React, {useState, useEffect, CSSProperties} from 'react'
import {RIF} from '../../lib'
import {selectTheme} from '../../store'

export enum QuestionnaireInputSize {
  extraSmall = '72px',
  small = '36.286rem',
  medium = '38.143rem',
  big = '53.143rem',
}
// export enum QuestionnaireInputSize {
//   small = '36.286rem',
//   medium = '38.143rem',
//   big = '53.143rem',
// }

export enum QuestionnaireInputHeight {
  medium = '40px',
  big = '48px',
}

export const QuestionnaireInput = (props: {
  defaultValue?: string
  initialValue: string
  onChangeAction: (arg: string) => void
  placeholder: string
  maxLength: number
  size?: QuestionnaireInputSize
  disabled?: boolean
  inputType?: 'number' | 'integer' | 'text'
  hideWordCount?: boolean
  questionnaireInputHeight?: QuestionnaireInputHeight
}) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {
    defaultValue,
    initialValue,
    placeholder,
    onChangeAction,
    maxLength,
    size,
    disabled,
    inputType,
    hideWordCount,
    questionnaireInputHeight,
  } = props
  const [focusing, setFocusing] = useState(false)
  const [inputValue, setInputValue] = useState(defaultValue || '')

  const fontSizeMap = {
    [QuestionnaireInputHeight.medium]: fontSize.h6,
    [QuestionnaireInputHeight.big]: fontSize.h3,
  }

  useEffect(() => {
    setInputValue(defaultValue || '')
  }, [defaultValue])

  const handleFocus = () => {
    setFocusing(true)
    if (inputValue === initialValue) {
      setInputValue('')
    }
  }

  const handleBlur = () => {
    setFocusing(false)
    if (!inputValue) {
      setInputValue(initialValue)
      if (onChangeAction) onChangeAction(initialValue)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || e.target.value === '-') {
      setInputValue(e.target.value)
    } else {
      if (inputType === 'number') {
        if (e.target.value === '') {
          setInputValue('0')
        } else if (!isNaN(parseFloat(e.target.value))) {
          setInputValue(e.target.value)
        }
      } else if (inputType === 'integer') {
        if (e.target.value === '') {
          setInputValue('0')
        } else {
          const number = parseInt(e.target.value)
          if (!isNaN(number) && number % 1 === 0) {
            setInputValue(e.target.value)
          }
        }
      } else {
        setInputValue(e.target.value)
      }
    }
    if (onChangeAction) onChangeAction(e.target.value)
  }

  return (
    <div
      // {...props}
      css={{
        width: size ?? '100%',
        height: questionnaireInputHeight ?? QuestionnaireInputHeight.medium,
        position: 'relative',
        borderRadius: '5px',
        overflow: 'hidden',
        border: `1px solid ${disabled ? color.border.default : focusing ? color.grey_400 : color.border.default}`,
        ':hover': {
          border: `1px solid ${disabled ? color.border.default : color.grey_400}`,
        },
      }}
    >
      <input
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={inputValue}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        css={{
          border: 'none',
          width: '100%',
          height: '100%',
          padding: hideWordCount ? '0 0 0 20px' : '0 100px 0 20px',
          backgroundColor: disabled ? color.surface.default : color.white,
          color: disabled ? color.state.disabled : color.textIcon.title,
          paddingLeft: '16px',
          fontSize: fontSizeMap[questionnaireInputHeight ?? QuestionnaireInputHeight.medium],
          // backgroundColor: color.white,
          ':focus': {
            outline: 'none',
          },
        }}
      />
      {RIF(
        focusing && !hideWordCount,
        <p
          css={{
            color: color.grey_400,
            fontWeight: fontWeight.medium,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            right: '20px',
            top: '0',
          }}
        >
          {inputValue.length} / {maxLength}
        </p>,
      )}
    </div>
  )
}
