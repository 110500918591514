import {useState, useRef, Dispatch, SetStateAction} from 'react'
import CronParser from 'cron-parser'
import {selectTheme, selectMethod, selectWorkspaces, createDispatchActions} from '../../store'
import {RIF, useClickOutside, _, t, useCurrentProjectState, useCurrentWorkspaceState} from '../../lib'
import {convertMovesenseDescription, convertGarminStreamDescription, DragHandle, TaskScheduleCard, ButtonMore} from '..'
import {
  TaskStateType,
  getTaskContentName,
  TempTask,
  TaskContentName,
  QuestionnaireTempSection,
  QuestionnaireTempInputConfigOptions,
} from '../../model'
import {differenceInMilliseconds} from 'date-fns'
import {
  ReminderItem,
  TaskType,
  IStopwatchMovesenseStream,
  IStopwatchGarminStream,
  IQuestionnaireSection,
  IQuestionnaireQuestion, SelectInputConfig, NumberInputConfig,
} from '../../shared/db'
import {Draggable} from 'react-beautiful-dnd'
import {
  BellIcon,
  DeviceIcon,
  MovesenseImg,
  RepeatIcon,
  SurveyBlackIcon,
  TimerBlackIcon,
  TodoBlackIcon,
} from '../../asset/image'

export interface TaskTagPropsT {
  task: TaskStateType | TempTask
  setRemovingTaskId?: Dispatch<SetStateAction<string>>
  setRemovingTaskName?: Dispatch<SetStateAction<string>>
  index: number
  onEditing?: () => void
  key?: string
}

function isSelectInputConfig(inputConfig: any) : inputConfig is SelectInputConfig {
  return !!inputConfig.options
}

function isNumberInputConfig(inputConfig: any) : inputConfig is NumberInputConfig {
  return !!inputConfig.integer || !!inputConfig.max || !!inputConfig.min
}

export const TaskTag = (props: TaskTagPropsT) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()

  const {task, setRemovingTaskId, setRemovingTaskName, index, onEditing} = props

  const taskContentName = getTaskContentName(task.type)

  const {doREQUEST_TASK_DUPLICATE, doTEMP_TASK_DATA_ADD_TASK, doQUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET}: any =
    createDispatchActions()
  const methodState = selectMethod()
  const methodId = methodState.id
  const {projectId, project} = useCurrentProjectState()
  const isEditingLiveProject = project?.status === 'live'

  const sortedScheduleList = [...(task.scheduleList ?? [])].sort((a, b) => {
    return differenceInMilliseconds(new Date(b.createdAt), new Date(a.createdAt))
  })

  const workspacesState = selectWorkspaces()
  const {workspaceId} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  const [hovered, setHovered] = useState(false)
  const [showingDropdown, setShowingDropdown] = useState(false)

  const brickRef = useRef<HTMLInputElement | null>(null)
  useClickOutside(brickRef, () => setShowingDropdown(false))

  const handleClickMoreBtn = () => {
    setShowingDropdown(true)
  }

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleClick = (e: any) => {
    if (e.target.tagName !== 'BUTTON') {
      onEditing?.()
    }
  }

  const handleDeleteBtnClick = () => {
    if (setRemovingTaskId) setRemovingTaskId(task.id)
    if (setRemovingTaskName) setRemovingTaskName(task[taskContentName]?.name ?? '')
  }

  const generateTaskTagContent = () => {
    let deviceTaskContent = ''
    if (task.type === TaskType.StopwatchMovesenseStream || task.type === TaskType.StopwatchMovesenseLogData) {
      deviceTaskContent = convertMovesenseDescription(task[taskContentName] as IStopwatchMovesenseStream)
    }
    if (task.type === TaskType.StopwatchGarminStream) {
      deviceTaskContent = convertGarminStreamDescription(task[taskContentName] as IStopwatchGarminStream)
    }

    return deviceTaskContent
  }

  const formatCronString = (cronParseObj: any) => {
    const {dayOfWeek, hour, minute} = cronParseObj.fields

    const timeString = `${hour[0].toString().padStart(2, '0')}:${minute[0].toString().padStart(2, '0')}`
    const AMPM = hour[0] < 12 ? 'AM' : 'PM'

    // (0 or 7 is Sun) from 'cron-parser'
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const formattedDaysOfWeek = dayOfWeek.map((index: number) => daysOfWeek[index])

    let formattedString = 'Repeats'

    if (dayOfWeek.length >= 7) {
      formattedString += ` daily at ${timeString} ${AMPM}`
    } else if (dayOfWeek.length === 1) {
      formattedString += ` every ${formattedDaysOfWeek[0]} at ${timeString} ${AMPM}`
    } else {
      const lastDayOfWeek = formattedDaysOfWeek.pop()
      formattedString += ` every ${formattedDaysOfWeek.join(', ')} and ${lastDayOfWeek} at ${timeString} ${AMPM}`
    }
    return formattedString
  }

  const formatISOString = (dateString: string) => {
    const utcDate = new Date(dateString)
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000
    return new Date(utcDate.getTime() + timezoneOffset).toLocaleString(undefined, options)
  }

  const generateReminderString = (reminder: ReminderItem) => {
    if (reminder.type === 'cron') {
      const cronInterval = CronParser.parseExpression(reminder.value)
      return formatCronString(cronInterval)
    } else if (reminder.type === 'date') {
      return formatISOString(reminder.value)
    }
  }

  const duplicateTask = () => {
    const originTask = _.cloneDeep(task)
    const originTaskContentName = getTaskContentName(originTask.type)
    if (!isEditingLiveProject) {
      const newTask = {
        taskId: originTask.id,
        methodId,
        name: `${originTask[originTaskContentName]?.name}_copy`,
      }
      doREQUEST_TASK_DUPLICATE({
        payload: newTask,
      })
    } else {
      const tempTaskId = t.uuid()
      const tempTask: TempTask = {
        id: tempTaskId,
        required: false,
        workspaceId: workspaceId ?? '',
        projectId: projectId ?? '',
        methodId: methodId ?? '',
        enabled: true,
        color: task.color,
        actionType: 'create',
        scheduleRepeat: originTask.scheduleRepeat,
        scheduleDescription: originTask.scheduleDescription,
        reminderList: [],
        type: originTask.type,
        [originTaskContentName]: {
          name: `${originTask[originTaskContentName]?.name}_copy`,
          description: originTask[originTaskContentName]?.description,
          projectId,
          taskId: tempTaskId,
        },
      }
      if (originTask.type === TaskType.Questionnaire) {
        const tempSectionList: QuestionnaireTempSection[] =
          originTask.questionnaire?.sectionList.map((s) => {
            const {questionList, description, legend, id} = s
            const tempQuestionList = questionList?.map((q) => {
              const {description, required, label, type, inputConfig, mediaUrl, id} = q
              if (isSelectInputConfig(inputConfig)) {
                const tempOptionList = inputConfig.options.map((o) => {
                  return {name: o.name}
                })
                return {
                  id,
                  description,
                  required,
                  label,
                  type,
                  mediaUrl,
                  inputConfig: {options: tempOptionList},
                }
              } else if (isNumberInputConfig(inputConfig)) {
                return {
                  id,
                  description,
                  required,
                  label,
                  type,
                  mediaUrl,
                  inputConfig: {
                    integer: inputConfig.integer,
                    max: inputConfig.max,
                    min: inputConfig.min
                  },
                }
              } else {
                return {
                  id,
                  description,
                  required,
                  label,
                  type,
                  inputConfig,
                  mediaUrl,
                }
              }
            })
            return {
              id,
              questionList: tempQuestionList,
              description,
              legend,
            }
          }) ?? []
        if (tempTask.questionnaire) {
          tempTask.questionnaire.sectionList = tempSectionList
        }
        // doQUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET({id: tempQuestionnaireId})
      }
      // if (
      //   originTask.type === TaskType.StopwatchMovesenseStream ||
      //   originTask.type === TaskType.StopwatchMovesenseLogData
      // ) {
      //   tempTask[originTaskContentName] = {
      //     ...originTask[originTaskContentName],
      //     name: `${originTask[originTaskContentName]?.name}_copy`,
      //   }
      // }
      // if (originTask.type === TaskType.StopwatchGarminStream) {
      //   tempTask[originTaskContentName] = {
      //     ...originTask[originTaskContentName],
      //     name: `${originTask[originTaskContentName]?.name}_copy`,
      //   }
      // }
      doTEMP_TASK_DATA_ADD_TASK(tempTask)
    }
    setShowingDropdown(false)
  }

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            css={{
              width: '100%',
              // height: addingToTaskSet? '3.5rem': '5rem',
              height: 'fit-content',
              position: 'relative',
              marginBottom: pad.mediumSmall,
              display: 'flex',
            }}
          >
            <DragHandle
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
              isShowing={role !== 'viewer' && (hovered || snapshot.isDragging)}
              css={{
                height: '100%',
                width: '24px',
                position: 'absolute',
                left: '-24px',
              }}
            />
            <div
              data-testid='task_tag'
              onClick={handleClick}
              css={{
                width: '100%',
                height: '100%',
                borderRadius: '5px',
                border: `1px solid ${hovered ? color.grey_400 : color.grey_160}`,
                borderLeft: `6px solid #${task?.color}`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: `${pad.medium} ${pad.mediumSmall}`,
                background: hovered ? color.grey_20 : color.white,
                cursor: 'pointer',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                {RIF(
                  task.type === TaskType.Todo,
                  <img src={TodoBlackIcon} width='24' height='24' css={{marginRight: pad.slightlyLarger}} />,
                )}
                {RIF(
                  task.type === TaskType.Timer,
                  <img src={TimerBlackIcon} width='24' height='24' css={{marginRight: pad.slightlyLarger}} />,
                )}
                {RIF(
                  task.type === TaskType.Questionnaire,
                  <img src={SurveyBlackIcon} width='24' height='24' css={{marginRight: pad.slightlyLarger}} />,
                )}
                {RIF(
                  task.type === TaskType.StopwatchMovesenseStream || task.type === TaskType.StopwatchMovesenseLogData,
                  <img
                    src={MovesenseImg}
                    width='24'
                    height='24'
                    css={{marginRight: pad.slightlyLarger, transform: 'scale(1.2)'}}
                  />,
                )}
                {RIF(
                  task.type === TaskType.StopwatchGarminStream,
                  <img
                    src={DeviceIcon}
                    width='22'
                    height='22'
                    css={{marginRight: pad.slightlyLarger, transform: 'scale(1.2)'}}
                  />,
                )}
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <p css={{fontWeight: fontWeight.thick, fontSize: fontSize.h6, marginBottom: '4px'}}>
                    {task[taskContentName]?.name}
                  </p>
                  <div css={{display: 'flex', alignItems: 'center'}}>
                    {RIF(
                      task.scheduleRepeat,
                      <img src={RepeatIcon} width='14' height='14' css={{marginRight: pad.xs}} />,
                    )}
                    <p css={{color: color.grey_400, fontSize: fontSize.h7}}>{task.scheduleDescription}</p>
                  </div>
                  {RIF(
                    !!generateTaskTagContent(),
                    <div
                      css={{
                        background: color.background,
                        borderRadius: '8px',
                        padding: '8px 12px',
                        marginTop: '8px',
                        color: color.grey_600,
                        fontSize: '12px',
                      }}
                    >
                      {generateTaskTagContent()}
                    </div>,
                  )}
                  {sortedScheduleList.length && (
                    <div css={{width: '100%', display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '8px'}}>
                      {sortedScheduleList.map((schedule) => (
                        <TaskScheduleCard
                          key={schedule.id}
                          schedule={schedule}
                          isViewOnly={true}
                          style={{
                            border: `1px solid ${color.border.default}`,
                            borderRadius: '5px',
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {task.reminderList?.map((reminder, index) => (
                    <div
                      key={index}
                      css={{
                        background: color.background,
                        borderRadius: '8px',
                        padding: '8px 12px',
                        marginTop: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                      }}
                    >
                      <img src={BellIcon} width='18' css={{marginRight: '5px'}} />
                      <p
                        css={{
                          color: color.grey_600,
                          fontSize: '12px',
                        }}
                      >
                        {generateReminderString(reminder)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div
                ref={brickRef}
                css={{
                  position: 'relative',
                  width: '1.71rem',
                  height: '1.71rem',
                }}
              >
                {RIF(role !== 'viewer', <ButtonMore data-testid='task_tag_more_btn' onClick={handleClickMoreBtn} />)}
                {RIF(
                  showingDropdown,
                  <div
                    css={{
                      width: '100px',
                      borderRadius: '5px',
                      boxShadow: '0px 4px 10px 1px rgba(104, 104, 104, 0.25)',
                      border: `1px solid ${color.disabled}`,
                      backgroundColor: color.white,
                      zIndex: '99',
                      position: 'absolute',
                      top: '1.68rem',
                      right: '1rem',
                      padding: '8px 0',
                    }}
                  >
                    <button
                      data-testid='task_tag_duplicate_btn'
                      onClick={duplicateTask}
                      css={{
                        width: '100%',
                        height: '28px',
                        padding: '5px 16px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: fontWeight.thick,
                        textAlign: 'start',
                        ':hover': {
                          backgroundColor: color.hover,
                        },
                        ':focus': {outline: 'none'},
                      }}
                    >
                      Duplicate
                    </button>
                    <button
                      data-testid='task_tag_remove_btn'
                      onClick={handleDeleteBtnClick}
                      css={{
                        width: '100%',
                        height: '28px',
                        padding: '5px 16px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: fontWeight.thick,
                        textAlign: 'start',
                        ':hover': {
                          backgroundColor: color.hover,
                        },
                        ':focus': {outline: 'none'},
                      }}
                    >
                      {isEditingLiveProject ? 'Hide' : 'Delete'}
                    </button>
                  </div>,
                )}
              </div>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}
